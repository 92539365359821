import { Flex, Tooltip, Text, Avatar } from "@chakra-ui/react";
import { useGlobal } from "src/contexts/global";

export const UserLogged = () => {
  const { session } = useGlobal();
  return (
    <Flex gap={2} alignItems={"center"}>
      <Tooltip label={session.role}>
        <Avatar name={session.role} size="sm" m={"auto"} />
      </Tooltip>
      <Text>{session.role}</Text>
    </Flex>
  );
};
