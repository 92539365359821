import { Button, Flex, Text } from "@chakra-ui/react";

import { IAplicativo } from "src/Interfaces/App";

interface ICertificadoProps {
  aplicativoId: number;
  nome: string;
  vencimento?: Date;
}

interface IAppProps {
  app: IAplicativo | ICertificadoProps;
  handleAppSelected?: () => void;
}

export default function LayoutBoxApp({ app, handleAppSelected }: IAppProps) {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      w={"285px"}
      h={"110px"}
      bg={"white"}
      border={"1px solid #f5f5f5"}
      borderRadius={"4px"}
      m={"0px 10px 10px 0px"}
    >
      <Flex
        h={"100px"}
        flexDir={"column"}
        py={3}
        justifyContent={app.vencimento ? "center" : "space-between"}
        alignItems={"center"}
      >
        <Text fontFamily={"sora"} fontSize={"14px"}>
          {app.nome}
        </Text>

        {handleAppSelected && (
          <Button
            w={"100px"}
            h={"25px"}
            color={"#1155BB"}
            border={"1px solid #1155BB"}
            colorScheme="whiteAlpha"
            onClick={handleAppSelected}
          >
            Ver detalhes
          </Button>
        )}

        {app.vencimento && (
          <Text
            fontFamily={"sora"}
            fontSize={"14px"}
            color={app?.vencimento < new Date() ? "red" : "green"}
            mt={3}
          >
            Vencimento: {app?.vencimento.toLocaleDateString()}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
