import { Box, Button, Flex, Text, Input } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "src/contexts/global";
import { ReactComponent as Logo } from "src/assets/svgs/logo-nucleo.svg";
import { Feedback } from "src/components/Feedback/feedback";
import { BsFillFileEarmarkArrowUpFill } from "react-icons/bs";
import { logout } from "src/utils/logout";
import { useEffect, useState } from "react";
import { apiInstance } from "src/services/api";
import { HeaderRegisterVersion } from "src/components/HeaderRegisterVersion";
import { TableVersionsGestor } from "./TableVersionsGestor";
import { TableVersionsUnique } from "./TableVersionsUnique";

export const RegisterVersion = () => {
  interface IdataVersionsComapnies {
    Id: number;
    dtcriacao: Date;
    dtliberacao: Date;
    link: string;
    sistema: string;
    status: number;
    versao: number;
  }

  type TSystem = "gestor" | "unique";

  const { session, details, handleSetDetails } = useGlobal();
  const navigate = useNavigate();
  const [registerVerion, setRegisterVersion] = useState("");
  const [registerLinkFTP, setRegisterLinkFTP] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [dateDefaultVersion, setDateDefaultVersion] = useState(new Date());
  const [infoVersionSystem, setInfoVersionSysytem] =
    useState<TSystem>("gestor");
  const [dataVersionsComapniesGestor, setDataVersionsComapniesGestor] =
    useState<IdataVersionsComapnies[]>([]);
  const [dataVersionsComapniesUnique, setDataVersionsComapniesUnique] =
    useState<IdataVersionsComapnies[]>([]);

  const getAllVersionsOfCompaniesGestor = async () => {
    try {
      const { data: getDataVersionsComapniesGestor } = await apiInstance(
        9008
      ).get(`/api/Versao/gestor`, {
        headers: {
          authorization: `Bearer ${session.token}`,
        },
      });
      setDataVersionsComapniesGestor(
        JSON.parse(getDataVersionsComapniesGestor.versao)
      );
    } catch (error) {
      console.log(error);
    }
  };
  const getAllVersionsOfCompaniesUnique = async () => {
    try {
      const { data: getDataVersionsComapniesUnique } = await apiInstance(
        9008
      ).get(`/api/Versao/unique`, {
        headers: {
          authorization: `Bearer ${session.token}`,
        },
      });
      setDataVersionsComapniesUnique(
        JSON.parse(getDataVersionsComapniesUnique.versao)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const findDefaultVersion = () => {
    const versionReleaseDates = dataVersionsComapniesGestor.map(
      (versionCompany) => {
        return versionCompany.dtliberacao;
      }
    );
    // console.log(
    //   dataVersionsComapnies.sort((a, b) => {
    //     if (new Date(a.dtcriacao) > new Date(b.dtcriacao)) return 1;
    //     if (new Date(b.dtcriacao) > new Date(a.dtcriacao)) return 2;
    //     return 0;
    //   })
    // );

    let maxDateVersionRelease = versionReleaseDates[0];

    for (let i = 1; i < versionReleaseDates.length; i++) {
      const currentDate = new Date(versionReleaseDates[i]);
      if (currentDate > maxDateVersionRelease) {
        maxDateVersionRelease = currentDate;
      }
    }

    setDateDefaultVersion(maxDateVersionRelease);
  };

  const createVersionCompanies = async () => {
    try {
      const payload = {
        versao: registerVerion,
        sistema: infoVersionSystem === "gestor" ? "Gestor" : "Unique",
        dtcriacao: new Date(),
        dtliberacao: releaseDate,
        status: 1,
        link: registerLinkFTP,
      };

      const { data } = await apiInstance(9008).post(
        `/api/Versao`,
        {
          ...payload,
        },
        {
          headers: {
            authorization: `Bearer ${session.token}`,
          },
        }
      );
      getAllVersionsOfCompaniesGestor();
      getAllVersionsOfCompaniesUnique();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVersionsOfCompaniesGestor();
    getAllVersionsOfCompaniesUnique();
  }, []);

  useEffect(() => {
    if (dataVersionsComapniesGestor.length > 0) findDefaultVersion();
  }, [dataVersionsComapniesGestor]);

  return (
    <>
      <Feedback />
      <HeaderRegisterVersion />

      <Flex w={"1280px"} flexDir="column" m={"auto"}>
        <Box w={"1030px"}>
          <Text fontWeight={"600"}>Qual aplicativo deseja cadastrar?</Text>
        </Box>
        <Flex w={"1030px"} gap={"20px"}>
          <Button
            colorScheme={"blue"}
            background={infoVersionSystem === "gestor" ? "#E2E8F0" : "white"}
            variant={"ghost"}
            border={"1px solid blue"}
            onClick={() => setInfoVersionSysytem("gestor")}
          >
            Gestor
          </Button>
          <Button
            colorScheme={"blue"}
            background={infoVersionSystem === "unique" ? "#E2E8F0" : "white"}
            variant={"ghost"}
            border={"1px solid blue"}
            onClick={() => setInfoVersionSysytem("unique")}
          >
            Unique
          </Button>
        </Flex>
        <Flex mt={"20px"} gap={"40px"} alignItems={"end"}>
          <Box>
            <Text fontWeight={"600"}>Versão</Text>
            <Input
              w={"280px"}
              h={"40px"}
              onChange={(ev) => setRegisterVersion(ev.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight={"600"}>Link FTP</Text>
            <Input
              w={"486px"}
              h={"40px"}
              onChange={(ev) => setRegisterLinkFTP(ev.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight={"600"}>Data de Liberação</Text>
            <Input
              type={"date"}
              w={"160px"}
              // h={"40px"}
              onChange={(ev) => setReleaseDate(ev.target.value)}
            />

            {/* <Button onClick={() => console.log(releaseDate)}>Show</Button> */}
          </Box>
          <Button
            w={"180px"}
            colorScheme={"blue"}
            leftIcon={<BsFillFileEarmarkArrowUpFill />}
            onClick={createVersionCompanies}
          >
            Inserir Versão
          </Button>
        </Flex>

        <Flex
          flexDir={"column"}
          justifyContent={"flex-start"}
          w={"1280px"}
          mt={"20px"}
        >
          <Text fontWeight={"600"}>Status dos aplicativos exibidos</Text>
          {infoVersionSystem === "gestor" ? (
            <TableVersionsGestor
              propVersionGestor={dataVersionsComapniesGestor}
              propGetVersionsGestor={getAllVersionsOfCompaniesGestor}
            />
          ) : (
            <TableVersionsUnique
              propVersionUnique={dataVersionsComapniesUnique}
              propGetVersionsUnique={getAllVersionsOfCompaniesUnique}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};
