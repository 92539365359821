import LayoutContent from "./content";
import LayoutLogo from "./logo";
import LayoutHeaderRoot from "./root";
import LayoutLogged from "./userLogged";

export const HeaderLayout = {
    Root: LayoutHeaderRoot,
    Content: LayoutContent,
    Logo: LayoutLogo,
    Logged: LayoutLogged
  };
  