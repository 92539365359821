import { Box, Text, BoxProps } from "@chakra-ui/react";

interface HeaderInfoProps extends BoxProps {
  title: string;
  subTitle?: string;
}

export const HeaderInfo = ({ title, subTitle, ...props }: HeaderInfoProps) => {
  return (
    <Box mb={2} {...props}>
      <Text fontSize={"sm"} color="primary.pure" fontWeight={"bold"}>
        {title}
      </Text>
      <Box h="px" bgColor={"primary.pure"} my={1} />

      {!!subTitle && (
        <Text fontSize={"11px"} color="#8f8f8f" textAlign={"right"}>
          {subTitle}
        </Text>
      )}
    </Box>
  );
};
