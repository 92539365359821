import axios, { AxiosInstance } from "axios";

import { logout } from "src/utils/logout";
import { IAplicativo } from "src/Interfaces/App";
import { IEconomyGroup } from "src/layouts/plansAndApps/economyGroup/interfaces";
import { logInterface } from "src/Interfaces/log";

const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "PRODUCTION"
    ? process.env.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL_ALFA;

export function apiInstance(port: number, url?: string): AxiosInstance {
  const baseURL = url ? `${url}` : `${BASE_URL}:${port}`;
  return axios.create({
    baseURL,
  });
}
apiInstance(9008).interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem("nl@session");
      localStorage.removeItem("nl@details");
      logout();
    }
  }
);

export const updateAplicativosEmpresa = async (
  companyCNPJ: string,
  body: IAplicativo[]
) => {
  await apiInstance(9000).post(
    `/workspace/Aplicativo/Empresa/${companyCNPJ}`,
    body,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
};

export const postLog = async (body: logInterface) => {
  await apiInstance(9000).post(`/logsApi/Logs/GravarLogs/`, body, {
    headers: {
      gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
    },
  });
};

export const getViaCep = async (cep: string) => {
  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  return data;
};

export const createCompany = async (body: any, token: any) => {
  await apiInstance(9008).post(
    "/api/Empresa",
    {
      ...body,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateCompany = async (body: any, token: any) => {
  const { data } = await apiInstance(9008).put(
    "/api/Empresa",
    {
      ...body,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const createUser = async (body: any, cnpjCpf: any) => {
  await apiInstance(8006).post(
    "/UserGestor",
    {
      ...body,
    },
    {
      headers: {
        authorization: `Bearer Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
        CnpjCpf: cnpjCpf,
      },
    }
  );
};

export const updateUser = async (body: any, token: any) => {
  await apiInstance(9008).put(
    "/api/User/Aplicativos",
    {
      ...body,
    },
    {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "Application/Json",
      },
    }
  );
};

export const getAllVersionsOfCompaniesUnique = async (token: any) => {
  const { data: getDataVersionsComapniesUnique } = await apiInstance(9008).get(
    `/api/Versao/unique`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return JSON.parse(getDataVersionsComapniesUnique.versao);
};

export const getAllVersionsOfCompaniesGestor = async (token: any) => {
  const { data: getDataVersionsComapniesGestor } = await apiInstance(9008).get(
    `/api/Versao/gestor`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return JSON.parse(getDataVersionsComapniesGestor.versao);
};

export const sendEmail = async (body: any, token: any) => {
  await apiInstance(9008).post("/api/User/NotifyUser", body, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const getSemaforo = async (params: string, token: string) => {
  const { data, status } = await apiInstance(9008).get(
    `/api/Semaforo/${params}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return { data, status };
};

export const getUsersByCompany = async (search: string, token: string) => {
  const { data } = await apiInstance(8006).get(
    `UserGestor/UsersByCompany/${search}`,
    {
      headers: {
        authorization: `Bearer Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
      },
    }
  );
  return data;
};

export const getValidateCertificate = async (cnpj: string) => {
  const { data } = await apiInstance(9000).get(
    `/workspace/Certificado/Vencimento/${cnpj}`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const getCompanyByID = async (companyID, token: any) => {
  const { data } = await apiInstance(9008).get(
    `/api/Empresa?empresaid=${companyID}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const getCompanyByCnpj = async (cnpj: string, token: any) => {
  const { data } = await apiInstance(9008).get(
    `/api/Empresa/nucleo?cnpj=${cnpj}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getUsersDetails = async (userId: string) => {
  const { data } = await apiInstance(8006).get(`/User/Email/${userId}`, {
    headers: {
      authorization: `Bearer Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
    },
  });
  return data;
};

export const getEconomyGroup = async (cnpj: string) => {
  const { data } = await apiInstance(9000).get(
    `/workspace/Empresa/GrupoEconomico/${cnpj}`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const getEconomyGroupParticipant = async (cnpj: string) => {
  const { data } = await apiInstance(9000).get(
    `/workspace/Participante/${cnpj}?page=0&limit=1000`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const postEconomyGroup = async (cnpj: string, body?: IEconomyGroup) => {
  const { data } = await apiInstance(9000).post(
    `/workspace/Participante/${cnpj}`,
    body,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};
export const deleteEconomyGroupParticipant = async (
  cnpj: string,
  body?: any
) => {
  const { data } = await apiInstance(9000).delete(
    `/workspace/Participante/${cnpj}`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const getUsersLog = async () => {
  const { data } = await apiInstance(9000).get(
    `/logsApi/Logs?aplicativo=nucleo`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const getUserLogByEmail = async (email: string) => {
  const { data } = await apiInstance(9000).get(
    `/logsApi/Logs/detail?email=${email}`,
    {
      headers: {
        gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
      },
    }
  );
  return data;
};

export const searchCompany = async (url: string, token: string) => {
  const { data } = await apiInstance(9008).get(`${url}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return data;
};

apiInstance(9000).interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem("nl@session");
      localStorage.removeItem("nl@details");
      logout();
    }
  }
);
