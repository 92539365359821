import { Button } from "@chakra-ui/react";
import { useGlobal } from "src/contexts/global";

import * as S from "./styles";

export interface IButtonSaveProps {
  isSubmitting: boolean;
  isDisabled: boolean;
}

export default function LayoutPlansAndAppsButtonSave({
  isSubmitting = false, isDisabled = true
}: IButtonSaveProps) {
  const { session } = useGlobal();

  return (
    <S.ButtonSave>
      <Button
        fontSize={"xs"}
        width={"160px"}
        colorScheme={"blue"}
        type="submit"
        isLoading={isSubmitting}
        isDisabled={isDisabled}
        loadingText="Salvando..."
        display={session.perfil === "4" ? "none" : "inline-flex"}
      >
        Salvar alterações
      </Button>
    </S.ButtonSave>
  );
}
