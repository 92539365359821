export const filters = {
  aplicativos: [
    { id: 2, name: "Gestor Nuvem", param: "nuvem" },
    { id: 1, name: "Gestor Local", param: "gestor" },
    { id: 3, name: "Unique", param: "unique" },
    // {id: 1, name: 'Simplaz'},
    // {id: 2, name: 'Mercado Livre'},
    // {id: 3, name: 'Analytics BI'},
    // {id: 4, name: 'Inteligência de Vendas'},
    // {id: 5, name: 'Inteligência Administrativa'},
    // {id: 1100, name: 'Gestor Local | Plano NF-e'},
    // {id: 1101, name: 'Gestor Local | Plano Básico'},
    // {id: 1102, name: 'Gestor Local | Plano Intermediário'},
    // {id: 1103, name: 'Gestor Local | Plano Avançado'},
    // {id: 1201, name: 'Gestor Nuvem | Plano NF-e'},
    // {id: 1202, name: 'Gestor Nuvem | Plano Básico'},
    // {id: 1203, name: 'Gestor Nuvem | Plano Intermediário'},
    // {id: 1204, name: 'Gestor Nuvem | Plano Avançado'},
    // {id: 2100, name: 'Unique | Plano Light'},
    // {id: 2101, name: 'Unique | Plano Plus'},
    // {id: 2102, name: 'Unique | Plano Premium'},
  ],
  status: [
    { id: 1, name: "Ativo" },
    { id: 2, name: "Inativo" },
  ],
  servidores: [
    // {id: -1, name: ''},
    { id: 0, name: "Principal" },
    { id: 1, name: "Secundario" },
    { id: 2, name: "Terciário" },
    { id: 9999, name: "Todos" },
  ],
  tipos: [
    { id: 0, name: "Empresa" },
    { id: 1, name: "Usuário" },
  ],
};
