import { ButtonAcessGerenciador } from "src/components/HeaderPages/ButtonAcessGerenciador";
import { useGlobal } from "src/contexts/global";
import { HeaderLayout } from "src/layouts/header";

export const Header = () => {
  const { session } = useGlobal();

  return (
    <HeaderLayout.Root>
      <HeaderLayout.Logo />
      <HeaderLayout.Content>
        {session.perfil === "5" && <ButtonAcessGerenciador />}
      </HeaderLayout.Content>
      <HeaderLayout.Logged />
    </HeaderLayout.Root>
  );
};
