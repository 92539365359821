import LayoutPlansAndAppsTopCenter from "./center";
import LayoutPlansAndAppsLeft from "./left";
import LayoutPlansAndAppsRight from "./right";
import LayoutPlansAndAppsTop from "./root";

export const PlansAndAppsTopLayout = {
  Root: LayoutPlansAndAppsTop,
  Center: LayoutPlansAndAppsTopCenter,
  Left: LayoutPlansAndAppsLeft,
  Right: LayoutPlansAndAppsRight
};
