import { forwardRef } from "react";
import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from "@chakra-ui/react";

interface SelectProps extends ChakraSelectProps {
  label: string;
  children: React.ReactNode;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, label, name, ...props }, ref) => {
    return (
      <FormControl id={name} w={props.w}>
        <FormLabel htmlFor={name} fontSize={"xs"} mb={"2px"} color={"gray.300"}>
          {label}
        </FormLabel>
        <ChakraSelect
          name={name}
          id={name}
          ref={ref}
          backgroundColor={"gray.200"}
          borderRadius={"4px"}
          fontSize={"sm"}
          _hover={{ borderColor: "inherit" }}
          {...props}
        >
          {children}
        </ChakraSelect>
      </FormControl>
    );
  }
);
export const SelectAble = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, label, name, ...props }, ref) => {
    return (
      <FormControl id={name} w={props.w}>
        <FormLabel htmlFor={name} fontSize={"xs"} mb={"2px"} color={"#2D3748"}>
          {label}
        </FormLabel>
        <ChakraSelect
          name={name}
          id={name}
          ref={ref}
          backgroundColor={"white"}
          borderColor={"#8F8F8F"}
          borderRadius={"4px"}
          fontSize={"sm"}
          _hover={{ borderColor: "inherit" }}
          {...props}
        >
          {children}
        </ChakraSelect>
      </FormControl>
    );
  }
);
