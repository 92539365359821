import { useGlobal } from "src/contexts/global";
import { TableUsers } from "./TableUsers";
import { TableCompanies } from "./TableCompanies";
import { PropsTable } from "../interfaces";

export const VersionManagerTable = ({
  tipo,
  propCompanies,
  propSetCompanies,
  propUsers,
  propSetUsers,
  propHasFiltered,
  propHandleAplicativo,
  propSearchValue,
  propSetSearchValue,
  dataVersionsComapnies
}: PropsTable) => {
  const { session } = useGlobal();

  return tipo === "usuario" || session.perfil === "3" ? (
    <TableUsers
      propUsers={propUsers ? propUsers : []}
      propSearchValue={propSearchValue}
      propSetSearchValue={propSetSearchValue}
      propSetUsers={propSetUsers}
    />
  ) : (
    <TableCompanies
      propCompanies={propCompanies}
      propSetCompanies={propSetCompanies}
      propHasFiltered={propHasFiltered}
      propHandleAplicativo={propHandleAplicativo}
      propTipo={tipo}
      dataVersionsComapnies={dataVersionsComapnies}
    />
  );
};
