import { Box, Flex, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { ReactComponent as EmptySearch } from "src/assets/svgs/emptySearch.svg";

export default function LayoutVersionManagerEmptySearch() {
  return (
    <Tbody>
      <Tr>
        <Td style={{ background: "#fff", border: 0 }} align="center">
          <Flex
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            p={0}
            flexWrap={"wrap"}
          >
            <Box
              w={"350px"}
              h={"350px"}
              sx={{
                padding: "0 10px",
                "@media (max-height: 768px)": {
                  width: "200px",
                  height: "200px",
                },
              }}
            >
              <EmptySearch width={"100%"} height={"100%"} />
            </Box>
            <Box padding={"10px"}>
              <Text
                fontSize={"32px"}
                fontWeight={"700"}
                color={"#1155BB"}
                ml={"20px"}
                mb={6}
                sx={{
                  "@media (max-height: 768px)": {
                    fontSize: "24px",
                    marginBottom: "10px",
                  },
                }}
              >
                Faça uma busca para realizar uma ação
              </Text>
              <Text
                fontSize={"20px"}
                fontFamily={"inter"}
                color={"#303030"}
                ml={"20px"}
                mb={3}
                sx={{
                  "@media (max-height: 768px)": {
                    fontSize: "14px",
                    marginBottom: "5px",
                  },
                }}
              >
                Use os filtros acima para buscar a empresa/usuário.
              </Text>
              <Text
                fontSize={"20px"}
                fontFamily={"inter"}
                color={"#303030"}
                ml={"20px"}
                mb={3}
                sx={{
                  "@media (max-height: 768px)": {
                    fontSize: "14px",
                    marginBottom: "5px",
                  },
                }}
              >
                Para realizar as ações, use os campos abaixo.
              </Text>
            </Box>
          </Flex>
        </Td>
      </Tr>
    </Tbody>
  );
}
