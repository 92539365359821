import { Flex, Grid } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerFilters({ children }: IRootProps) {
  return (
    <Flex id="filters" display={"flex"} flexDirection={"column"}>
      <Flex
        alignItems="end"
        sx={{
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          sx={{
            "@media (max-width: 800px)": {
              gridTemplateColumns: "repeat(1, minmax(150px, 1fr))"
            },
          }}
          gap={3}
          width={"full"}
          alignItems={"end"}
        >
          {children}
        </Grid>
      </Flex>
    </Flex>
  );
}
