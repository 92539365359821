import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../../interfaces";

export default function LayoutPlansAndAppsLeft({ children }: IRootProps) {
  return (
    <Flex alignSelf={"left"} gap={"1.5rem"} minWidth={'25%'}>
      {children}
    </Flex>
  );
}
