import {
  Flex,
  Input,
  useToast,
  Text,
  Button,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "src/contexts/global";
import { apiInstance, searchCompany } from "src/services/api";
import { logout } from "src/utils/logout";

interface AllCompaniesAndUsers {
  company: {
    descricao: string;
    empresaId: number;
    cnpjCpf: string;
    email: string;
    razao: string;
    dtcriacao: Date;
    dtAtualiza: Date;
    status: string;
    versao: string;
    servidor: number;
    dedicado: boolean;
  };
  user: string;
}

interface CompanyVersionManager {
  descricao: string;
  empresaId: number;
  cnpjCpf: string;
  email: string;
  razao: string;
  dtcriacao: Date;
  dtAtualiza: Date;
  status: string;
  versao: string;
  servidor: number;
  dedicado: boolean;
}

interface ErrorResponse {
  status: number;
  message: string;
  company: string;
}

export const SearchCompany = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { session, handleSetDetails } = useGlobal();
  const [searchValue, setSearchValue] = useState("");
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filtredCompanies, setFiltredCompanies] = useState<
    AllCompaniesAndUsers[]
  >([]);
  const [desiredCompanySelection, setDesiredCompanySelection] = useState<
    CompanyVersionManager[]
  >([]);

  const [companySelected, setCompanySelected] = useState(0);

  const getWorkspace = async (cnpjCpf: any) => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/Empresa/${cnpjCpf}`,
        {
          headers: {
            gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
          },
        }
      );
    } catch (error) {
      toast({
        title: "Falha ao buscar dados",
        description: "Verifique os filtros e os valores informados!",
        position: "top-right",
        status: "error",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
    }
  };

  const notFound = () => {
    toast({
      title: "Nenhum resultado encontrado",
      description: "Verifique o campo e tente novamente",
      position: "top-right",
      status: "info",
      isClosable: true,
      containerStyle: {
        fontSize: "14px",
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = async (e: FormEvent) => {
    // e.preventDefault();
    // console.log(e);
    if (
      sessionStorage.getItem("haveChanges") &&
      sessionStorage.getItem("haveChanges") === "true"
    ) {
      toast({
        title: "Você realizou alterações e não salvou.",
        description: " Clique no botão salvar para efetivar as alterações.",
        position: "top-right",
        status: "warning",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
      sessionStorage.setItem("haveChanges", "false");
    } else {
      setCompanySelected(0);
      document.getElementById("tableSearch")?.focus();
      setIsLoading(true);
      let url = "/api/Empresa/nucleo?";

      if (
        (!isNaN(Number(searchValue)) && searchValue.length >= 10) ||
        (searchValue.includes(".") && searchValue.includes("-") && !searchValue.includes("@") )
      ) {
        const resultCNPJ = searchValue
          .replaceAll(".", "")
          .replace("/", "")
          .replace("-", "");
        url = url.concat(`&cnpj=${resultCNPJ}`);
      } else if (isNaN(Number(searchValue)) && !searchValue.includes("@")) {
        url = url.concat(`razao=${searchValue}`);
      } else if (searchValue.includes("@")) {
        url = url.concat(`email=${searchValue}`);
      } else if (searchValue !== "") {
        url = url = `/api/Empresa?empresaid=${searchValue}`;
      }
      try {
        e.preventDefault();

        const data = await searchCompany(url, session.token);

        if (data?.dados?.length === 0 && !data?.company) {
          notFound();
        }

        const cnpjCpfSelected = data?.dados
          ? data.dados[0].company.cnpjCpf
          : data.company.cnpjCpf;

        getWorkspace(cnpjCpfSelected);

        let newFiltredCompanies = data?.dados ? data.dados : [data];
        setFiltredCompanies(newFiltredCompanies);

        if (newFiltredCompanies.length > 1) {
          showList();
        } else {
          handleSetDetails(newFiltredCompanies[0]);
          navigate("/details");
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<ErrorResponse>;

          if (axiosError.response) {
            if (axiosError.response.status === 404) {
              notFound();
            }

            if (axiosError.response.status === 401) {
              toast({
                title: "Sessão Expirada",
                description: "Você será redirecionado(a) para tela de login",
                position: "top-right",
                status: "error",
                isClosable: true,
                containerStyle: {
                  fontSize: "14px",
                },
              });
              // alert("sessão expirada");
              setTimeout(() => {
                logout();
              }, 3000);
            }
          } else {
            toast({
              title: "Falha ao salvar",
              description: "Erro desconhecido",
              position: "top-right",
              status: "error",
              isClosable: true,
              containerStyle: {
                fontSize: "14px",
              },
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Mostra lista com empresas filtradas pela razão social
  const showList = () => {
    setIsSelected(true);
  };
  // Confirma empresa a ser apresentada na tabela principal e fecha a tabela auxiliar de busca
  const SelectedItem = (item: CompanyVersionManager) => {
    const newResult: any = filtredCompanies.filter((res) => {
      return res.company.empresaId === item.empresaId;
    });

    handleSetDetails(newResult[0]);
    setDesiredCompanySelection(newResult);
    setIsSelected(false);
    navigate("/details");
  };

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      const list: NodeListOf<Element> =
        document.querySelectorAll("#tableSearch li");

      if (keyCode === 27) {
        setIsSelected(false);
      }

      // enter
      if (keyCode === 13 && searchValue && !isSelected) {
        setCompanySelected(0);
        handleSearch(event);
        document.getElementById("tableSearch")?.focus();
        // enter com empresa selecionada
      } else if (keyCode === 13 && searchValue && isSelected) {
        const empresa = filtredCompanies.filter((item, index) =>
          index === companySelected ? item.company : null
        );

        if (empresa && empresa[0]) {
          SelectedItem(empresa[0].company);
        }
      } else if (keyCode === 38 || keyCode === 104) {
        //up
        companySelected > 0
          ? setCompanySelected(companySelected - 1)
          : setCompanySelected(0);
      } else if (keyCode === 40 || keyCode === 98) {
        // down
        companySelected < list.length - 1
          ? setCompanySelected(companySelected + 1)
          : setCompanySelected(list.length - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSearch, searchValue]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handleClickOutOfList = useCallback((event) => {
    if (!event.target.matches("#tableSearch")) {
      //setSearchValue("");
      setIsSelected(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleClickOutOfList);
    return () => {
      window.removeEventListener("click", handleClickOutOfList);
    };
  }, [handleClickOutOfList]);

  return (
    <Flex mt={"5px"} maxWidth={"100%"}>
      {/* <Flex onSubmit={handleSearch}> */}
      <Flex maxWidth={"100%"} border={"1px solid #D8D8D8"}>
        <Flex>
          <InputGroup>
            <Input
              w="495px"
              maxWidth={"100%"}
              ml={"5px"}
              size={"sm"}
              placeholder="CPF / CNPJ, Nome / Razão Social / E-mail / Id Empresa"
              borderColor="white"
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
              variant="unstyled"
              autoFocus
              sx={{
                "@media (max-width: 600px)": {
                  width: "100%",
                },
              }}
            />
            <InputRightElement cursor={"pointer"} mt={-1} mr={1}>
              {searchValue.length > 0 && (
                <ImCancelCircle
                  size={"20px"}
                  color={"gray"}
                  onClick={() => {
                    setIsSelected(false);
                    setSearchValue("");
                  }}
                />
              )}
            </InputRightElement>
          </InputGroup>
          <Flex
            // position={"absolute"}
            top={"0px"}
            right={"0px"}
            // border={"none"}
            outline={"none"}
          ></Flex>
        </Flex>
        <Flex
          style={{
            position: "fixed",
            zIndex: "10",
            maxHeight: "60vh",
            overflow: "auto",
            boxShadow: isSelected ? "2px 2px 5px 2px #cdcdcd" : "none",
          }}
        >
          <List
            w={"590px"}
            maxWidth={"full"}
            maxHeight={"350px"}
            id="tableSearch"
            zIndex={2}
            tabIndex={0}
            _focusVisible={{ outline: "none" }}
          >
            {isSelected === true &&
              filtredCompanies.map((item, index) => (
                <ListItem
                  background={companySelected === index ? "#efefef" : "#fff"}
                  color={companySelected === index ? "#1155BB" : "#222"}
                  fontWeight={companySelected === index ? "bold" : "300"}
                  key={item.company.empresaId}
                  tabIndex={index + 1}
                  _hover={{ color: "#1155BB", background: "#efefef" }}
                  _active={{ color: "#1155BB", background: "#efefef" }}
                  textAlign={"left"}
                  cursor={"pointer"}
                  onClick={() => SelectedItem(item.company)}
                  p={3}
                  value={item.company.empresaId}
                >
                  <Text text-align={"left"}>{item.company.razao}</Text>
                  <Text text-align={"right"}>{item.company.cnpjCpf}</Text>
                </ListItem>
              ))}
          </List>
        </Flex>
      </Flex>

      <Flex>
        <Button
          size={"sm"}
          borderLeftRadius={0}
          colorScheme={"blue"}
          // type="submit"
          onClick={handleSearch}
          isDisabled={!searchValue.length}
          isLoading={isLoading}
        >
          Pesquisar
        </Button>
      </Flex>
    </Flex>
  );
};
