import { Navigate, Outlet } from "react-router-dom";
import { useGlobal } from "src/contexts/global";
import { logout } from "src/utils/logout";
import jwt_decode from "jwt-decode";
import { useToast } from "@chakra-ui/react";

export const ProtectedRoutes = () => {
  const { session } = useGlobal();
  const toast = useToast();

  if (session.token) {
    const { exp } = jwt_decode(session.token) as {
      exp: number;
    };
    const expirationDate = new Date(exp * 1000); // Convert expiration timestamp to milliseconds

    if (expirationDate < new Date()) {
      toast({
        title: "Sessão Expirada",
        description: "Você será redirecionado(a) para tela de login",
        position: "top-right",
        status: "error",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
      // Token has expired, redirect to login
      logout();
    }
  } else {
    logout();
  }

  const isAuth = !!session.perfil;

  if (isAuth) {
    setTimeout(() => {
      logout();
    }, 43200000);
  }

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export const HasAuth = () => {
  const { session } = useGlobal();

  const isAuth = !session.perfil;
  // console.log(`HasAuth - ${isAuth}`);
  return isAuth ? <Outlet /> : <Navigate to="/geral" />;
};
