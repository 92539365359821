import {
  Badge,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { filters } from "src/constants/filters";
import { useGlobal } from "src/contexts/global";
import { apiInstance } from "src/services/api";
import { AiOutlineCheck } from "react-icons/ai";
import { VersionManagerActionsLayout } from "src/layouts/gerenciador/versionManagerActions";
import { FaRubleSign } from "react-icons/fa";

export const VersionManagerActions = ({
  selectedData,
  propSearchValue,
  propSetUsers,
  propCompanies,
  propSetCompanies,
  companiesSelectedToUpdate,
  propHandleAplicativo,
  propTipo,
  propSumSelected,
  propSetSelected,
  dataVersionsComapnies,
}) => {
  const { session } = useGlobal();
  const toast = useToast();

  const [isStatusUser, setIsStatusUser] = useState("");
  const [isOpenModalReviewUpdateCompany, setIsOpenModalReviewUpdateCompany] =
    useState(false);
  const [isOpenModalReviewUpdateUser, setIsOpenModalReviewUpdateUser] =
    useState(false);
  const [fieldStatusToUpdate, setFieldStatusToUpdate] = useState("");
  const [fieldServerToUpdate, setFieldServerToUpdate] = useState({
    id: "-1",
    name: "Não Altera",
  });
  const [fieldVersionToUpdate, setFieldVersionToUpdate] = useState("0");
  const [fieldForceUpdate, setFieldForceUpdate] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const getUsers = async () => {
    const { data } = await apiInstance(8006).get(
      `UserGestor/UsersByCompany/${propSearchValue}`,
      {
        headers: {
          authorization: `Bearer Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
        },
      }
    );

    propSetUsers(data);
    setIsOpenModalReviewUpdateUser(false);
  };

  const updateStatusUser = async () => {
    setIsLoading(true);
    const user = isStatusUser === "Ativo" ? true : false;
    try {
      const payload = {
        userId: selectedData.userId,
        name: selectedData.name,
        cpf: selectedData.cpf,
        emailValid: selectedData.emailValid,
        perfilId: selectedData.perfilId,
        aplicativos: selectedData.aplicativos,
        phone: selectedData.phone,
        email: selectedData.email,
        status: user,
        Password:
          selectedData.Password === undefined ? "" : selectedData.Password,
      };

      const { data } = await apiInstance(8006).put(
        "/User",
        {
          ...payload,
        },
        {
          headers: {
            authorization: `Bearer Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb`,
          },
        }
      );

      msgSucesso();
    } catch (error) {
      console.log(error);
      msgErro();
    } finally {
      setIsLoading(false);
    }

    getUsers();
  };

  const msgErro = (message?: string) => {
    return toast({
      title: "Erro ao atualizar",
      description: message ? message : "",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const msgSucesso = (message?: string) => {
    return toast({
      title: "Atualização  feita com sucesso!",
      description: message ? message : "",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  //Função resoponsavel por fazer update da empresa
  const updateDataCompanies = async () => {
    setIsLoading(true);
    //companiesSelectedToUpdate ---- Armazena o código da empresa selecionada
    //propCompanies ---------------- Armazena todas empresas após clicar no botão pesquisar do componente VMFilter
    //filtered --------------------- Armazena todas empesas selecionadas (todas que tiverem id dentro de companiesSelectedToUpdate), cada empresa é armazenada em um array diferente
    //filteredCompanies ------------ Armazena vários arrays em um objeto
    //filteredArray ---------------- Armazena a transformação de objeto de arrays em um array de objetos

    const dataVersionSelected = dataVersionsComapnies?.filter(
      (version) => version.versao.toString() === fieldVersionToUpdate
    );
    const linkVersionSelected = dataVersionSelected[0]?.link;

    let filteredCompanies = {};

    //Gera um array para cada empresa selecionada e joga na variavel filtered
    for (var item in companiesSelectedToUpdate) {
      const filtered = propCompanies.filter(
        (company) => company.empresaid === companiesSelectedToUpdate[item]
      );

      // Gera uma objeto envolvendo os arrays do filtred
      filteredCompanies = {
        ...filteredCompanies,
        [companiesSelectedToUpdate[item]]: filtered,
      };
    }

    //Transforma o objeto de arrays em um array de objetos e armazena o resultado em filteredArray
    const filteredArray = ([] as Array<any>).concat(
      ...Object.values(filteredCompanies)
    );

    let system = propHandleAplicativo.param.includes("unique")
      ? "UNIQUE"
      : "GESTOR";

    //Variavel responsavel por montar um objeto somente com as informações que forem preenchidas para enviar o put
    let initialPayload: any = {};

    if (fieldStatusToUpdate !== "" && fieldStatusToUpdate !== "0") {
      initialPayload = {
        ...initialPayload,
        indStatus: fieldStatusToUpdate === "Ativo" ? "1" : "2",
      };
    }
    if (
      fieldServerToUpdate.id !== "-1" &&
      fieldServerToUpdate.id !== undefined
    ) {
      initialPayload = { ...initialPayload, servidor: fieldServerToUpdate.id };
    }
    if (fieldVersionToUpdate !== "0" && fieldVersionToUpdate !== "") {
      if (fieldForceUpdate !== "" && propHandleAplicativo.param !== "nuvem") {
        const date = fieldForceUpdate.split("-");
        const formatDate = `${date[0]}-${date[1]}-${date[2]}`;
        initialPayload = {
          ...initialPayload,
          versao: fieldVersionToUpdate,
          link: `<${system}>${linkVersionSelected}<${system}>`,
          dtAtualiza: formatDate,
        };
      } else {
        initialPayload = {
          ...initialPayload,
          versao: fieldVersionToUpdate,
          dtAtualiza: "0001-01-01",
        };
      }
    }

    //Monta a requisição de put com o id da empresa, cnpjCpf e dados setados
    //É gerado um objeto para cada empresa selecionada
    filteredArray.map(async (item) => {
      const companie = {
        empresaId: item.empresaid,
        cnpjCpf: item.cnpj,
        aplicativos: `|${system}|`,
        ...initialPayload,
      };

      try {
        const { data } = await apiInstance(9008).put(
          "api/Semaforo",
          {
            ...companie,
          },
          {
            headers: {
              authorization: `Bearer ${session.token}`,
            },
          }
        );
        msgSucesso();
      } catch (error) {
        console.log(error);
        msgErro();
      } finally {
        setIsLoading(false);
      }
    });

    propSetSelected([]); //Retira todas seleções
    propSetCompanies([]); //Zera o objto de empresas filtradas para que que apresente a tela inicial de busca
    setFieldStatusToUpdate("");
    setFieldServerToUpdate({
      id: "-1",
      name: "Não Altera",
    });
    setFieldVersionToUpdate("0");
    setFieldForceUpdate("");
    setIsOpenModalReviewUpdateCompany(false);
  };

  useEffect(() => {
    setFieldStatusToUpdate("0");
    setFieldServerToUpdate({
      id: "-1",
      name: "Não Altera",
    });
    setFieldVersionToUpdate("");
    setFieldForceUpdate("");
  }, [propHandleAplicativo]);

  return (
    <VersionManagerActionsLayout.Root>
      <VersionManagerActionsLayout.Filters>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          <Badge
            border={1}
            borderColor={"#333333"}
            borderStyle={"solid"}
            borderRadius={"0.375rem"}
            px={5}
            py={"2.5"}
            whiteSpace={'pre-wrap'}
          >
            Total Selecionado: {propSumSelected}
          </Badge>
        </VersionManagerActionsLayout.FiltersItem>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          <Text color={"#2D3748"}>Status</Text>
          <Select
            background={"white"}
            borderColor={"#8F8F8F"}
            value={fieldStatusToUpdate}
            onChange={(ev) => {
              setFieldStatusToUpdate(ev.target.value);
              setIsStatusUser(ev.target.value);
            }}
          >
            <option value={""}>Não altera</option>
            {filters.status.map((statusSemaforo) => (
              <option key={statusSemaforo.id} value={statusSemaforo.name}>
                {statusSemaforo.name}
              </option>
            ))}
          </Select>
        </VersionManagerActionsLayout.FiltersItem>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          {propTipo !== "empresa" ? (
            <Text color={"#BABABA"}>Servidor</Text>
          ) : (
            <Text color={"#2D3748"}>Servidor</Text>
          )}
          <Select
            isDisabled={propTipo !== "empresa"}
            background={"white"}
            borderColor={"#8F8F8F"}
            value={JSON.stringify(fieldServerToUpdate)}
            onChange={(ev) =>
              setFieldServerToUpdate(JSON.parse(ev.target.value))
            }
          >
            <option value={"-1"}>Não Altera</option>
            {filters.servidores.map(
              (serverSemaforo) =>
                serverSemaforo.id !== 9999 && (
                  <option
                    key={serverSemaforo.id}
                    value={JSON.stringify(serverSemaforo)}
                  >
                    {serverSemaforo.name}
                  </option>
                )
            )}
          </Select>
        </VersionManagerActionsLayout.FiltersItem>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          {propTipo !== "empresa" ? (
            <Text color={"#BABABA"}>Versão</Text>
          ) : (
            <Text color={"#2D3748"}>Versão</Text>
          )}
          <Select
            isDisabled={propTipo !== "empresa"}
            background={"white"}
            borderColor={"#8F8F8F"}
            value={fieldVersionToUpdate}
            onChange={(ev) => setFieldVersionToUpdate(ev.target.value)}
          >
            <option value={"0"}>Não altera</option>
            {dataVersionsComapnies?.map(
              (version) =>
                version.status === 1 && (
                  <option key={version.versao} value={version.versao}>
                    {version.versao}
                  </option>
                )
            )}
          </Select>
        </VersionManagerActionsLayout.FiltersItem>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          {propTipo !== "empresa" ? (
            <Text color={"#BABABA"}>Forçar Atualização</Text>
          ) : (
            <Text>Forçar Atualização</Text>
          )}
          <Input
            isDisabled={
              propTipo !== "empresa" || propHandleAplicativo.param === "nuvem"
            }
            type={"date"}
            border={"1px solid #8F8F8F"}
            background={"white"}
            value={fieldForceUpdate}
            onChange={(ev) => {
              setFieldForceUpdate(ev.target.value);
            }}
          />
        </VersionManagerActionsLayout.FiltersItem>
        <VersionManagerActionsLayout.FiltersItem colSpan={2} rowSpan={1}>
          <Button
            fontSize={"xs"}
            width={"160px"}
            colorScheme={"blue"}
            onClick={
              propTipo === "empresa"
                ? () => setIsOpenModalReviewUpdateCompany(true)
                : () => setIsOpenModalReviewUpdateUser(true)
            }
            isDisabled={propSumSelected === 0}
            isLoading={isLoading}
            loadingText="Atualizando..."
          >
            Atualizar
          </Button>
        </VersionManagerActionsLayout.FiltersItem>
      </VersionManagerActionsLayout.Filters>
      {/* Modal revisão atualizar dados empresa */}
      <Modal
        isOpen={isOpenModalReviewUpdateCompany}
        onClose={() => setIsOpenModalReviewUpdateCompany(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"#1155BB"}>Confirmar Alterações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>
              <strong>{`${propSumSelected} selecionado(s)`}</strong>
            </p>
            <br />
            <p>
              {`Status: `}
              <strong>
                {fieldStatusToUpdate !== "0" && fieldStatusToUpdate !== ""
                  ? fieldStatusToUpdate
                  : "Não Altera"}
              </strong>
            </p>
            <p>
              {`Servidor: `}
              <strong>
                {fieldServerToUpdate.id !== "-1" &&
                fieldServerToUpdate.id !== undefined
                  ? fieldServerToUpdate.name
                  : "Não Altera"}
              </strong>
            </p>
            <p>
              {`Versão: `}
              <strong>
                {fieldVersionToUpdate !== "" && fieldVersionToUpdate !== "0"
                  ? fieldVersionToUpdate
                  : "Não Altera"}
              </strong>
            </p>
            <p>
              {`Forçar Atualização em: `}
              <strong>
                {fieldForceUpdate !== "" &&
                propHandleAplicativo.param !== "nuvem"
                  ? new Date(fieldForceUpdate).toLocaleDateString("pt-br", {
                      timeZone: "UTC",
                    })
                  : "Não Altera"}
              </strong>
            </p>
          </ModalBody>

          <ModalFooter>
            <Button
              w={"140px"}
              variant="ghost"
              border={"1px solid gray"}
              mr={"100px"}
              onClick={() => setIsOpenModalReviewUpdateCompany(false)}
            >
              X Cancelar
            </Button>
            <Button
              w={"140px"}
              colorScheme="green"
              mr={3}
              leftIcon={<AiOutlineCheck />}
              onClick={updateDataCompanies}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal revisão atualizar dados usuario */}
      <Modal
        isOpen={isOpenModalReviewUpdateUser}
        onClose={() => setIsOpenModalReviewUpdateUser(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmar Alterações</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Tem certeza que deseja alterar o status do usuario para{" "}
            <b>{fieldStatusToUpdate}</b>?
          </ModalBody>

          <ModalFooter>
            <Button
              w={"140px"}
              variant="ghost"
              border={"1px solid gray"}
              mr={"100px"}
              // leftIcon={<AiOutlineCheck />}
              onClick={() => setIsOpenModalReviewUpdateUser(false)}
            >
              X Cancelar
            </Button>
            <Button
              w={"140px"}
              colorScheme="green"
              mr={3}
              leftIcon={<AiOutlineCheck />}
              onClick={updateStatusUser}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VersionManagerActionsLayout.Root>
  );
};
