import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../interfaces";

export default function LayoutHeaderRoot({ children }: IRootProps) {
  return (
    <Flex
      w="100%"
      alignItems={"center"}
      justifyContent={"space-between"}
      flexWrap={"wrap"}
      boxShadow={"md"}
      p={3}
      mb={5}
      gap={3}
    >
      {children}
    </Flex>
  );
}
