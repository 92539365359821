import { Flex } from "@chakra-ui/react";
import { useGlobal } from "src/contexts/global";

import { IRootProps } from "../../interfaces";

export default function LayoutPlansAndAppsRight({ children }: IRootProps) {
  const { session } = useGlobal();
  return (
    <Flex
      alignSelf={"right"}
      gap={"1.5rem"}
      justifyContent={"end"}
      alignItems={"center"}
      minWidth={"25%"}
    >
      {session.perfil === "5" && <div> {children}</div>}
    </Flex>
  );
}
