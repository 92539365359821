export const logout = () => {
  localStorage.removeItem("nl@session");
  localStorage.removeItem("nl@details");

  window.location.replace(
    process.env.REACT_APP_ENVIRONMENT === "ALFA"
      ? window.location.href.includes("localhost")
        ? "http://localhost:3498/"
        : "http://alpha-nucleo.londrisoftservices.com.br"
      : process.env.REACT_APP_ENVIRONMENT === "LOCAL"
      ? "http://localhost:3498/"
      : "http://192.168.0.224"
  );
};
