import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";

 interface IRootProps {
  children: ReactNode;
  onSubmit: () => void
}


export default function LayoutPlansAndAppsRoot({ children, onSubmit }: IRootProps) {
  return (
    <Flex
      flexDir={"column"}
      w={"full"}
      maxWidth={"100%"}
      minHeight={"100vh"}
      p={"0 30px 80px 30px"}
      as="form" 
      onSubmit={onSubmit}
      marginBottom={"200px"}
    >
      {children}
    </Flex>
  );
}
