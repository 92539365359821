import { Flex } from "@chakra-ui/react";
import { AiOutlineLogout } from "react-icons/ai";
import { logout } from "src/utils/logout";

export const ButtonLogout = () => {
  return (
    <Flex
      w={"full"}
      color={"black"}
      onClick={() => logout()}
      gap={3}
      textAlign={"left"}
      alignItems={"center"}
    >
      <AiOutlineLogout color="red" /> Sair
    </Flex>
  );
};
