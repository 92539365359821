import { Skeleton, Stack, Td, Tr } from "@chakra-ui/react";

export default function LayoutVersionManagerLoadingSearch() {
  return (
    <Tr>
      <Td style={{ background: "#fff", border: 0 }}>
        <Stack mb={100}>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      </Td>
    </Tr>
  );
}
