import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Switch as ChackraSwitch,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import moment from "moment";

import { getUserLogByEmail, getUsersDetails } from "src/services/api";

import { GestorProfiles, UniqueProfiles, WorkspaceProfiles } from "./perfis";
import {
  detailsUserInterface,
  detailsUserDetailsInterface,
  userLogInterface,
  PerfilAPP,
} from "./interfaces";

import * as S from "./styles";
import { IAplicativo } from "src/Interfaces/App";

export default function LayoutPlansAndAppsDetailsUser({
  open,
  onClose,
  user,
  apps,
}: detailsUserInterface) {
  const [userDetails, setUserDetails] = useState<detailsUserDetailsInterface>();
  const [userLogs, setUserLogs] = useState<userLogInterface[]>([]);

  const [isLoading, setIsloading] = useState(false);
  const [perfisApps, setPerfisApps] = useState<IAplicativo[]>([]);

  useEffect(() => {
    if (open) {
      setIsloading(true);
      //  setUserDetails(null);
      if (user?.email) {
        getUserLogByEmail(user.email).then((response) => {
          setUserLogs(response.logs);
        });
      }
      getUser();
    }
  }, [open, user]);

  const getUser = async () => {
    if (user?.userid) {
      getUsersDetails(user.email).then((response) => {
        if (response) {
          response.perfilAplicativos = response.perfilAplicativos
            ? JSON.parse(response.perfilAplicativos)
            : [];
          setUserDetails(response);
          perfilUserList(response);
        }
      });
    }
    setIsloading(false);
  };

  const getLastAcessByApp = (appName: string): string => {
    const last = userLogs.find((u) =>
      appName.toUpperCase().includes(u.aplicativo.toUpperCase())
    )?.dataHora;
    return last ? moment(last).format("DD/MM/YYYY [às] HH:mm:ss") : "";
  };

  const perfilUser = (aplicativo: string, perfil: string): string => {
    if (aplicativo === "UNIQUE") {
      const perfilApp = UniqueProfiles.find((p) => p.id === perfil)?.name;
      return perfilApp ? perfilApp : "";
    } else if (aplicativo === "SIMPLAZ") {
      const perfilApp = WorkspaceProfiles.find((p) => p.id === perfil)?.name;
      return perfilApp ? perfilApp : "";
    } else {
      const perfilApp = GestorProfiles.find((p) => p.id === perfil)?.name;
      return perfilApp ? perfilApp : "";
    }
  };

  const setPerfilAplicativo = (
    appName: string,
    perfilAplicativos: PerfilAPP[]
  ): string => {
    const perfil =
      perfilAplicativos.length > 0
        ? perfilAplicativos?.find((p) =>
            appName.toUpperCase().includes(p.Aplicativo.toUpperCase())
          )
        : null;
    return perfilUser(
      perfil?.Aplicativo ? perfil?.Aplicativo : "",
      perfil?.Perfil ? perfil?.Perfil : ""
    );
  };

  const perfilUserList = (user: detailsUserDetailsInterface) => {
    setPerfisApps([]);
    const APP = apps
      ?.filter(
        (app) =>
          app.hasShow === true &&
          user.aplicativos.toUpperCase().includes(app.tag.toUpperCase())
      )
      .map((app) => {
        app.perfil = setPerfilAplicativo(
          app.nome,
          user.perfilAplicativos ? user.perfilAplicativos : []
        );
        return app;
      });

    setPerfisApps(APP && APP?.length > 0 ? APP : []);
  };

  return (
    <>
      <Modal isOpen={open} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent w={"full"}>
          <ModalHeader>
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              flexWrap={"wrap"}
            >
              <Box>
                <Box fontSize={"20px"} fontWeight={"bold"} color={"#1155BB"}>
                  Detalhes do usuário
                </Box>
                <Box fontSize={"16px"} fontWeight={"bold"}>
                  {" "}
                  {user?.name}{" "}
                </Box>
                <Box fontSize={"14px"}> Email: {user?.email} </Box>
                <Box fontSize={"14px"}> Fone: {userDetails?.phone} </Box>
              </Box>
              <Box>
                <S.Switch>
                  <ChackraSwitch
                    size={"lg"}
                    bgSize={"cover"}
                    colorScheme={"green"}
                    isChecked={user?.status ? true : false}
                    p={1}
                  >
                    <div className="textSwitch">
                      {user?.status ? "ATIVO" : "INATIVO"}
                    </div>
                  </ChackraSwitch>
                </S.Switch>
              </Box>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody w={"full"}>
            {!isLoading ? (
              perfisApps && perfisApps?.length > 0 ? (
                <S.Table>
                  <Table w={"full"} variant={"striped"}>
                    <Thead>
                      <Tr>
                        <Th>Aplicativos</Th>
                        <Th>Perfil de acesso</Th>
                        <Th>Último acesso</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {perfisApps?.map((app, index) => {
                        return (
                          <Tr key={`log-user- ${index}`}>
                            <Td>{app.nome}</Td>
                            <Td>{app.perfil ? app.perfil : "-"}</Td>
                            <Td>{getLastAcessByApp(app.nome)}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </S.Table>
              ) : (
                <Box mt={5}>Nenhum perfil para aplicativo encontrado</Box>
              )
            ) : (
              <Stack mb={100}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            {/*<Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>*/}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
