import styled from "@emotion/styled";

export const Table = styled.div`
  width: 100%;

  th,
  td {
    border: 1px solid #f5f5f5;
    padding: 5px 10px;
    font-size: 12.5px;
  }

  tr {
    color: #1155bb;
  }

  td {
    color: #666666;
  }
`;
