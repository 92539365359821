import { getAllVersionsOfCompaniesGestor } from "src/services/api";
import { IdataVersionComapnie } from "../Interfaces/dataVersionCompanie";

//2.01.009 -> Consome api de versões filtrando versões do gestor e salva todos os dados em um array
export const handleDataDefaultVersionGestor = async (token: any) => {
  try {
    const versionsGestor: IdataVersionComapnie[] =
      await getAllVersionsOfCompaniesGestor(token);

    const versionValide = versionsGestor.filter((versionValide) => {
      return (
        !versionValide.versao.toUpperCase().includes("BETHA") &&
        !versionValide.versao.toUpperCase().includes("ALPHA") &&
        versionValide.status === 1
      );
    });

    let currentVersion = versionValide[0].dtliberacao; // Assume o primeiro objeto como tendo a data mais recente
    let responseCurrentVersion = versionValide[0]; // Armazena o objeto com a data mais recente
    for (let i = 1; i < versionValide.length; i++) {
      const verionVerify = versionValide[i];
      if (verionVerify.dtliberacao > currentVersion) {
        currentVersion = verionVerify.dtliberacao;
        responseCurrentVersion = verionVerify;
      }
    }
    const defaultVersion = versionValide.filter(
      (item) => item.dtliberacao === responseCurrentVersion.dtliberacao
    );

    return defaultVersion[0]

  } catch (error) {
    console.log(error);
  }
};