import { Button } from "@chakra-ui/react";
import { BsFillFileEarmarkArrowUpFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const ButtonAcessGerenciador = () => {
  const navigate = useNavigate();
  return (
    <Button
      fontSize={"sm"}
      w={"160px"}
      colorScheme={"green"}
      onClick={() => navigate("/registerVersion")}
      leftIcon={<BsFillFileEarmarkArrowUpFill />}
    >
      Inserir Versão
    </Button>
  );
};
