import styled from "@emotion/styled";

export const ButtonSave = styled.div`
  width: 100%;
  position: fixed;
  display: flex;

  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;

  background-color: rgb(224 224 224 / 0.5);

  z-index: 99;

  button {
    margin: 0 auto;
    opacity: 1;
  }
`;
