import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../interfaces";

export default function LayoutGerenciadorHeader({ children }: IRootProps) {
  return (
    <Flex id="header-ger" display={"flex"} flexDirection={"column"}>
      {children}
    </Flex>
  );
}
