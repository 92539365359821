import { Table, TableContainer, Box, Flex } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";
import * as S from "./styles";

export default function LayoutVersionManagerTableRoot({
  children,
}: IRootProps) {
  return (
    <TableContainer
    h={"220px"}
    minHeight={"60vh"}
   
    sx={{
      "@media (max-height: 768px)": {
        minHeight: "50vh",
      },
      "@media (max-height: 700px)": {
        minHeight: "42vh",
      },
    }}

    overflowX={"auto"}
    overflowY={"auto"}
    css={{
      "&::-webkit-scrollbar": {
        background: "#ddd",
        width: "8px",
        height: "8px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#A1A1AA",
        borderRadius: "24px",
      },
    }}
    >
      <Flex
        w={"full"}
        align={"center"}
      >
        <S.Table>
          <Table
            size={"sm"}
            w={"full"}
          >
            {children}
          </Table>
        </S.Table>
      </Flex>
    </TableContainer>
  );
}
