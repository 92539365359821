import LayoutVersionManagerTableContent from "./content";
import LayoutVersionManagerTableTd from "./contentTd";
import LayoutVersionManagerTableTh from "./contentTh";
import LayoutVersionManagerEmptySearch from "./emptySearch";
import LayoutVersionManagerTableHeader from "./header";
import LayoutVersionManagerLoadingSearch from "./loading";
import LayoutVersionManagerTableRoot from "./root";


export const VersionManagerTableLayout = {
  Root: LayoutVersionManagerTableRoot,
  Header: LayoutVersionManagerTableHeader,
  Empty: LayoutVersionManagerEmptySearch,
  Loading: LayoutVersionManagerLoadingSearch,
  Content: LayoutVersionManagerTableContent,
  TableHead: LayoutVersionManagerTableTh,
  TableBody: LayoutVersionManagerTableTd,
};
