import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IAplicativo, TAppsEcosystem } from "src/Interfaces/App";
import { apiInstance } from "src/services/api";


export const AppsEcosystem = ({
  propIsOpenModalNewApp,
  propSetIsOpenModalNewApp,
  propSetTags,
  propSetApps,
  propApps,
}: TAppsEcosystem) => {
  const toast = useToast();
  const [selectedAppsGestor, setSelectedAppsGestor] = useState("0");
  const [appsUnique, setAppsUnique] = useState("0");
  const [appsSimplaz, setAppsSimplaz] = useState("0");

  const getApps = async () => {
    try {
      apiInstance(9000)
        .get(`/workspace/Aplicativo/`, {
          headers: {
            gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
          },
        })
        .then((res) => {
          const aplicativos = res.data.data as IAplicativo[];
          propSetApps(aplicativos);
        });
    } catch (error) {
      console.log("Erro ao consultar aplicativos", error);
    }
  };

  const handleCheckedApp = (app: IAplicativo, checked: boolean) => {
    const newApps: IAplicativo[] = propApps.map((item) => {
      if (item.aplicativoId === app.aplicativoId) {
        return {
          ...item,
          indAtivar: checked ? "S" : "N" ? "N" : "S",
        };
      } else {
        return item;
      }
    });
    // console.log(newApps);

    propSetApps(newApps);
  };

  const handleSetarAplicativo = async () => {
    try {
      propSetApps([]);
      propSetTags([]);
      const newApps: IAplicativo[] = propApps.map((item) => {
        if (
          item.aplicativoId === Number(selectedAppsGestor) ||
          item.aplicativoId === Number(appsUnique) ||
          item.aplicativoId === Number(appsSimplaz)
        ) {
          return {
            ...item,
            indAtivar: "S",
          };
        } else {
          return item;
        }
      });
      newApps.filter((app) => {
        if (app.indAtivar === "S") {
          propSetTags((prevArray) => {
            return [...prevArray, `${app.tag}`];
          });
          // propSetTags(() => [`${app.tag}`]);
        }
      });
      propSetApps(newApps);
      propSetIsOpenModalNewApp();

      toast({
        title: "Serviço adicionado com Sucesso",
        position: "top",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      propSetIsOpenModalNewApp();
      toast({
        title: "Falha na gravação do banco de dados",
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };

  const cancelSelection = () => {
    propSetApps([]);
    propSetIsOpenModalNewApp();
  };

  useEffect(() => {
    getApps();
  }, []);

  //2.01.009 -> alterado layout - criado grupos para gestor, unique e simplaz
  return (
    <Flex translate="no">
      <Modal
        closeOnOverlayClick={false}
        isOpen={propIsOpenModalNewApp}
        onClose={propSetIsOpenModalNewApp}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Selecione os serviços para adicionar ao perfil
          </ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <Flex>
              {/* Aplicativos Gestor */}
              <RadioGroup
                onChange={setSelectedAppsGestor}
                value={selectedAppsGestor}
              >
                <Flex direction="column" h={"full"}>
                  <Text as={"b"}>Gestor</Text>
                  {propApps.map(
                    (app) =>
                      app.descricao.toUpperCase().includes("GESTOR") && (
                        <Radio
                          key={app.aplicativoId}
                          value={app.aplicativoId.toString()}
                          size={"sm"}
                          borderColor={"#666"}
                        >
                          {app.descricao}
                        </Radio>
                      )
                  )}
                </Flex>
              </RadioGroup>
              {/* Aplicativos Unique */}
              <RadioGroup onChange={setAppsUnique} value={appsUnique}>
                <Flex
                  direction="column"
                  h={"full"}
                  borderLeft={"1px solid #f5f5f5"}
                  ml={"20px"}
                  pl={"20px"}
                >
                  <Text as={"b"} translate="no">
                    Unique
                  </Text>
                  {propApps.map(
                    (app) =>
                      app.descricao.toUpperCase().includes("UNIQUE") && (
                        <Radio
                          key={app.aplicativoId}
                          value={app.aplicativoId.toString()}
                          size={"sm"}
                          borderColor={"#666"}
                          translate="no"
                        >
                          {app.descricao}
                        </Radio>
                      )
                  )}
                </Flex>
              </RadioGroup>
              {/* Aplicativos Simplaz */}
              <RadioGroup onChange={setAppsSimplaz} value={appsSimplaz}>
                <Flex
                  direction="column"
                  h={"full"}
                  borderLeft={"1px solid #E0E0E0"}
                  ml={"20px"}
                  pl={"20px"}
                >
                  <Text as={"b"}>Simplaz</Text>
                  {propApps.map(
                    (app) =>
                      app.descricao.toUpperCase().includes("SIMPLAZ") && (
                        <Radio
                          key={app.aplicativoId}
                          value={app.aplicativoId.toString()}
                          size={"sm"}
                          borderColor={"#666"}
                        >
                          {app.descricao}
                        </Radio>
                      )
                  )}
                </Flex>
              </RadioGroup>
              {/* Outros aplicativos */}
              <Flex
                direction="column"
                borderLeft={"1px solid #f5f5f5"}
                ml={"20px"}
                pl={"20px"}
              >
                <Text as={"b"}>Outros</Text>
                {propApps.map((app) =>
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("GESTOR") &&
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("UNIQUE") &&
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("SIMPLAZ") &&
                  !app.descricao.toUpperCase().includes("CERTIFICADO") ? (
                    <Checkbox
                      key={app.aplicativoId}
                      value={app.aplicativoId.toString()}
                      size={"sm"}
                      borderColor={"#666"}
                      onChange={(ev) =>
                        handleCheckedApp(app, ev.target.checked)
                      }

                      // onChange={() => handleOtherSelectApps(app.aplicativoId)}
                    >
                      {app.descricao}
                    </Checkbox>
                  ) : (
                    ""
                  )
                )}
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={cancelSelection}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSetarAplicativo}
              // onClick={showApps}
            >
              Adicionar Serviços
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
