import { Box, Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "src/assets/svgs/logo-nucleo.svg";

export const LogoNucleo = () => {
  const navigate = useNavigate();
  const toast = useToast();


  const navigateUrl = () => {
    if (
      (!sessionStorage.getItem("haveChanges") ||
      sessionStorage.getItem("haveChanges") === "false") 
    ) {
      navigate("/geral");
    } else {
      toast({
        title: "Você realizou alterações e não salvou.",
        description: " Clique no botão salvar para efetivar as alterações.",
        position: "top-right",
        status: "warning",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
      sessionStorage.setItem("haveChanges", "false");
    }
  };

  return (
    <Box>
      <Button variant={"link"} onClick={() => navigateUrl()}>
        <Logo />
      </Button>
    </Box>
  );
};
