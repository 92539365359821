import { Button, Flex, Text } from "@chakra-ui/react";
import "./index.css";

export const Feedback = () => {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Inter"
        rel="stylesheet"
      ></link>
      <Flex direction={"row-reverse"}>
        <a
          href="https://feedback.userreport.com/7275e80b-0459-4d2a-94d1-05c79f73478a/#ideas/popular"
          target={"_blank"} rel="noreferrer"
        >
          <Button
            style={{ position: "fixed", zIndex: "10" }}
            top="40%"
            h="149px"
            w="45px"
            right={"0"}
            background={"#43B5EF"}
            borderRadius={"4px 0px 0px 4px"}
            overflow="hidden"
            _hover={{ background: "#1155BB", color: "#FFFFFF" }}
          >
            <Text className="textFeedback">Deixe sua sugestão</Text>
          </Button>
        </a>
      </Flex>
    </>
  );
};
