import { Th, Thead, Tr } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerTableTh({ children }: IRootProps) {
  return (
    <Thead
      css={{
        position: "sticky",
        top: "-1px",
        zIndex: 2,
        backgroundColor: "#f2f2f2",
      }}
    >
      <Tr visibility={"visible"}>
        <Th fontSize={"14px"} color={"#1155BB"}>
          {children}
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"} p={"0"}>
          Tipo
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          ID
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          CPF/CNPJ
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          E-mail
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Nome
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Status
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Servidor
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Versão
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Especific.
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Forçar Att.
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Data Alt.
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Data Exclusão
        </Th>
        <Th fontSize={"14px"} color={"#1155BB"}>
          Criado em
        </Th>
      </Tr>
    </Thead>
  );
}
