import { GridItem } from "@chakra-ui/react";
import { ReactNode } from "react";

type ItemsFiltersType = {
  children: ReactNode;
  colSpan: number;
  rowSpan: number;
};

export default function LayoutVersionManagerActionsItem({
  children,
  colSpan,
  rowSpan,
}: ItemsFiltersType) {
  return (
    <GridItem w="100%" colSpan={colSpan} rowSpan={rowSpan}>
      {children}
    </GridItem>
  );
}
