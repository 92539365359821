import { Flex } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerActionsRoot({
  children,
}: IRootProps) {
  return (
    <Flex flexDir={"column"} alignItems={"center"} >
      <Flex
        w={"100vw"}
        maxWidth={"100%"}
        background={"#fff"}
        alignItems={"center"}
      >
        <Flex
          w={"100%"}
          gap={"40px"}
          mt="20px"
          mb="20px"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}
