import { Feedback } from "src/components/Feedback/feedback";
import { VersionManagerFilters } from "./VersionManagerFilters";
import { Header } from "./VersionManagerHeader";
import { GerenciadorLayout } from "src/layouts/gerenciador";

export const GerenciadorDeVersao = () => {
  return (
    <GerenciadorLayout.Root>
      <GerenciadorLayout.Header>
        <Feedback />
        <Header />
      </GerenciadorLayout.Header>
      <GerenciadorLayout.Content>
        <VersionManagerFilters />
      </GerenciadorLayout.Content>
    </GerenciadorLayout.Root>
  );
};
