import { Box, Button, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { HeaderInfo } from "src/components/HeaderInfo";
import { useGlobal } from "src/contexts/global";
import { PlansAndAppsLayout } from "src/layouts/plansAndApps";
import { detailsUsersDataInterface } from "src/layouts/plansAndApps/detailsUser/interfaces";

import { IAplicativo } from "src/Interfaces/App";

import * as S from "./styles";

interface userInterface {
  apps?: IAplicativo[];
}

export const Users = ({ apps }: userInterface) => {
  const { details } = useGlobal();

  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [userSelected, setUserSelected] = useState<detailsUsersDataInterface>();

  useEffect(() => {
    setUserSelected(details?.users);
  }, [details]);

  return (
    <>
      <HeaderInfo title="Usuários" mt={"30px"} mb={"30px"} />
      <Box>
        <S.Table>
          <Table size={"sm"} variant="striped">
            <Thead>
              <Tr>
                <Th
                  fontWeight={"400"}
                  textTransform={"capitalize"}
                  color={"gray.300"}
                  fontSize={"11px"}
                >
                  Nome
                </Th>
                <Th
                  fontWeight={"400"}
                  textTransform={"capitalize"}
                  color={"gray.300"}
                  fontSize={"11px"}
                >
                  E-mail
                </Th>
                <Th
                  fontWeight={"400"}
                  textTransform={"capitalize"}
                  color={"gray.300"}
                  fontSize={"11px"}
                >
                  Status
                </Th>
                <Th
                  fontWeight={"400"}
                  textTransform={"capitalize"}
                  color={"gray.300"}
                  fontSize={"11px"}
                >
                  Telefone
                </Th>
                <Th
                  fontWeight={"400"}
                  textTransform={"capitalize"}
                  color={"gray.300"}
                  fontSize={"11px"}
                >
                  Ação
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {details?.users?.map((user, index) => (
                <Tr key={index}>
                  <Td color={"#000"} fontWeight={"bold"} fontSize={"xs"}>
                    {user.name}
                  </Td>
                  <Td color={"#000"} fontWeight={"bold"} fontSize={"xs"}>
                    {user.email}
                  </Td>
                  <Td color={"#000"} fontWeight={"bold"} fontSize={"xs"}>
                    {user.status ? "Ativo" : "Inativo"}
                  </Td>
                  <Td color={"#000"} fontWeight={"bold"} fontSize={"xs"}>
                    {user?.phone}
                  </Td>
                  <Td color="#000" fontSize="xs">
                    <Button
                      variant={"link"}
                      colorScheme="blue"
                      fontWeight="semibold"
                      onClick={() => {
                        setUserSelected(user);
                        setOpenUserDetails(!openUserDetails);
                      }}
                    >
                      Detalhes
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </S.Table>
      </Box>
      <PlansAndAppsLayout.UserDetailsModal
        user={userSelected}
        apps={apps}
        open={openUserDetails}
        onClose={() => setOpenUserDetails(false)}
      />
    </>
  );
};
//#region Perfil Gestor
const perfilGestor = [
  {
    type: 1,
    perfil: "Administrador",
  },
  {
    type: 2,
    perfil: "Cadastro",
  },
  {
    type: 3,
    perfil: "Faturamento",
  },
  {
    type: 4,
    perfil: "Pdv",
  },
  {
    type: 5,
    perfil: "NF-e",
  },
  {
    type: 6,
    perfil: "Financeiro",
  },
  {
    type: 7,
    perfil: "Industria",
  },
];
//#endregion Perfil Gestor

//#region Perfil Unique

const perfilUnique = [
  {
    type: 1,
    perfil: "Administrador",
  },
  {
    type: 2,
    perfil: "Cadastro",
  },
  {
    type: 3,
    perfil: "Folha",
  },
  {
    type: 4,
    perfil: "Fiscal",
  },
  {
    type: 5,
    perfil: "Contabil",
  },
  {
    type: 6,
    perfil: "Contabil fiscal",
  },
];
//#endregion Perfil Unique
