import { extendTheme, ComponentStyleConfig } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      "body, #root": {
        height: "100vh",
      },
      "* ": {
        scrollbarWidth: "thin",
      },
      "* ::-webkit-scrollbar": {
        scrollbarWidth: "thin",
        width: "6px",
        height: "6px",
      },
      "* ::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        background: "#aaa",
      },
      "* ::-webkit-scrollbar-track": {
        width: "1px",
        background: "#ededed",
      },
      "::placeholder": {
        fontSize: "12px",
      },
    },
  },
  fonts: {
    body: "Sora, sans-serif",
    heading: "Sora, sans-serif",
  },
  colors: {
    primary: {
      pure: "#1155BB",
      light: "#3BB2EE",
      medium: "#166DD7",
      dark: "#113377",
    },
    highlight: {
      pure: "#8855EE",
      light: "#BB88FF",
      medium: "#9055FF",
      dark: "#7044BB",
    },
    low: {
      pure: "#000000",
      light: "#BABABA",
      medium: "#606060",
      dark: "#303030",
    },
    high: {
      pure: "#FFFFFF",
      light: "#FBFBFB",
      medium: "#F0F0F0",
      dark: "#E5E5E5",
    },
    error: {
      pure: "#D13F3F",
      light: "#E5ACAC",
      medium: "#D16161",
      dark: "#B22424",
    },
    alert: {
      pure: "#F48619",
      light: "#FFB266",
      medium: "#F29130",
      dark: "#B25900",
    },
    success: {
      pure: "#008844",
      light: "#6CD9A0",
      medium: "#22BB6B",
      dark: "#138047 ",
    },
    gray: {
      100: "#F5F5F5",
      200: "#F0F0F0",
      300: "#8F8F8F",
      400: "#D8D8D8",
    },
  },
  components: {
    Table: {
      Td: {
      baseStyle: {
        borderColor: '#f5f5f5'
      }
    }
    },
    Button: {
      baseStyle: {
        fontWeight: "400",
        borderRadius: "4px",
      },
      sizes: {
        md: {
          fontSize: "sm",
        },
      },
      variants: {
        solid: ({ colorScheme }) => {
          if (colorScheme === "blue") {
            return {
              bg: "primary.pure",
              _hover: {
                bg: "primary.medium",
              },
            };
          }
        },
      },
    } as ComponentStyleConfig,
  },
});
