import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../../interfaces";

export default function LayoutPlansAndAppsTopCenter({ children }: IRootProps) {
  return (
    <Flex
     justifyContent={"center"}
     gap={"1.5rem"}
     alignItems={"center"}
     minWidth={'50%'}
     sx={{
      "@media (max-width: 1024px)": {
        minWidth: '100%',
      },
      "@media (max-width: 650px)": {
        flexWrap: 'wrap',
      },
    }}
    >
      {children}
    </Flex>
  );
}
