import styled from "@emotion/styled";

export const Switch = styled.div`
  width: 100%;

  .chakra-switch {
    width: 200px;

    .chakra-switch__track {
      width: 180px;
      border-radius: 4px;
      padding: 7px;
      background: #f93d3d;

      .chakra-switch__thumb {
        border-radius: 0%;
        background: transparent;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABNUlEQVR4Ae2V4XGDMAyFRRYoIzBCRmAENggblA1wJigbtKOwAd2AbEA7gSpdTPtOMU1iuPzI+bvT+WzZTwJZQJRIJBIBmDkXa8UGsYnXoRpvYsWtwV83CBpCNRsbLzPBWxkcLH2J9X6MoRDbi+Ww5rIsO17slOC1ydiJ5bQBXgspQ5tG2KDJzPdgLofWsqZIzAOO1lmB892vDRymoUjk7EfwLcikA8c+UA5kii2NBgWdTtd23vcrKBfkU4byH538in8R0e5h+oIJ3EtsV1yws4K+Nv2VcyeKQMsL0290lHdcQkeRqDboVNY5gvPA5zbsYH1tGx5AfwxtqMyTtrQB/Pc9QerZbz/FTgYMrHdDu+JEccwdg217lG5wiyckiYYf+DNaSqLgc/0HXsfkNTb7ryQSiefjBxoS7Yi3PCiYAAAAAElFTkSuQmCC");
        background-repeat: no-repeat;

        margin-right: 10px;

        width: 32px;
        height: 32px;
        transform: translate(0, -4px);
      }
    }

    .chakra-switch__track[data-checked] {
      background: #38a169;

      .chakra-switch__thumb {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABOUlEQVR4Ae2Vi43CMAxA3VvgOkJHYIPrCN3g2ODYoGGCYwMYpUxQNigbABMYG1wwJhQUIpBQnmRF+dhO608AEolEwgMi5iQ1SUuywedgG/8kxaPO/yI49cE2J9ZfZpzXNDi1tCVpZAyhIBmR5GrNZVk2vTpJzsfmxo4khwiILU3pO9SpA2MIRELYih22yfHPzQd2VqlSm3MIBI+J66OV/YX3L9BkpjZGEADpFTgM/4FSzWes9yX6p1hTgqwgjHsX/yHbjZp/6wu8jf4CpzJDX4Y+RgPD5bo04d2BdhopCSc4nIRztVZZ5U5t/kIgkmx9GXL3O/QTtqnsdz7Fyty6hgjg+V25qIh+37ZiR4N2zDHlqlhDGFxdJVy24ilVg7upIXF82WN06xLcVLg5tfgcG7ER7V1JJBKfxx56Zu12CaDbAQAAAABJRU5ErkJggg==");
        transform: translate(0, -4px);
      }
    }

    .chakra-switch__label {
      display: flex;
      justify-content: center;
      div.textSwitch {
        color: #fff;
        position: absolute;
        top: 23px;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .chakra-switch__label[data-checked] {
      div.textSwitch {
      }
    }
  }
`;
