import { createContext, useContext, useState } from "react";
import jwt_decode from "jwt-decode";
import { logout } from "src/utils/logout";

interface GlobalProps {
  handleSetLogin: (token: string) => void;
  handleSetDetails: (data: Details) => void;
  session: Session;
  details: Details;
}

type Permission = {
  register: boolean;
  edit: {
    status: boolean;
    financial: boolean;
    plan: boolean;
    environment: boolean;
    partner: boolean;
    application: boolean;
  };
  admin: boolean;
};

type Session = {
  token: string;
  perfil: string;
  role: string;
  permissions: Permission;
};

type Details = {
  company: any;
  users?: any[] | any;
};

const roles = {
  1: "Financeiro",
  2: "Comercial",
  3: "Cs",
  4: "Geral",
  5: "Administrador",
};

const CtxGlobal = createContext({} as GlobalProps);

const getPayloadUser = (token: string) => {
  const { nameid } = jwt_decode(token) as {
    nameid: string;
    exp: number;
  };
  
  return {
    token,
    perfil: nameid,
    role: roles[Number(nameid)],
    permissions: {
      register: nameid === "2" || nameid === "5" || nameid === "3" ,
      edit: {
        status: nameid === "1" || nameid === "3" || nameid === "5",
        financial: nameid === "1" || nameid === "5",
        partner: nameid === "2" || nameid === "5",
        plan: nameid === "2" || nameid === "3" || nameid === "5",
        environment: nameid === "3" || nameid === "5",
        application: nameid === "5",
      },
      admin: nameid === "5",
    } as Permission,
  };
};

export const GlobalProvider = ({ children }: { children: JSX.Element }) => {
  const [session, setSession] = useState<Session>(() => {
    const token = localStorage.getItem("nl@session")
      ? String(
          localStorage
            .getItem("nl@session")
            ?.split(":")[1]
            .split(",")[0]
            .replaceAll(/["]/g, "")
        )
      : null;

    const session = token ? getPayloadUser(token) : null; // localStorage.getItem("nl@session");
    if (session) {
      return JSON.parse(JSON.stringify(session));
    }
    return {} as Session;
  });

  const [details, setDetails] = useState<Details>(() => {
    const details = localStorage.getItem("nl@details");
    if (details) {
      return JSON.parse(details);
    }

    return {} as Details;
  });

  const handleSetLogin = (token: string) => {
    const { exp } = jwt_decode(token) as {
      nameid: string;
      exp: number;
    };
    const expirationDate = new Date(exp * 1000); // Convert expiration timestamp to milliseconds

    if (expirationDate < new Date()) {
      // Token has expired, redirect to login
      setSession({} as Session);
      setDetails({} as Details);
      logout();
      return;
    }

    const payload = getPayloadUser(token);

    localStorage.setItem("nl@session", JSON.stringify(payload));
    setSession(payload);
  };

  const handleSetDetails = (data: any) => {
    const payload = {
      company: data.company ? data.company : data.dados[0]?.company,
      users: data.users ? JSON.parse(data.users) : [],
    };

    localStorage.setItem("nl@details", JSON.stringify(payload));
    setDetails(payload);
  };

  return (
    <CtxGlobal.Provider
      value={{ session, handleSetLogin, handleSetDetails, details }}
    >
      {children}
    </CtxGlobal.Provider>
  );
};

export const useGlobal = () => useContext(CtxGlobal);
