import LayoutPlansAndAppsRoot from "./root";
import LayoutPlansAndAppsUsers from "./users";
import { PlansAndAppsTopLayout } from "./top";
import LayoutPlansAndAppsInput from "./input/root";
import LayoutPlansAndAppsEcosystem from "./ecosystem";
import LayoutPlansAndAppsButtonSave from "./buttonSave";
import LayoutPlansAndAppsCompanyForm from "./companyForm/index";
import LayoutPlansAndAppsLogUser from "./log";
import LayoutPlansAndAppsDetailsUser from "./detailsUser";
import LayoutPlansAndAppsEconomyGroup from "./economyGroup";

export const PlansAndAppsLayout = {
    Root: LayoutPlansAndAppsRoot,
    Top: PlansAndAppsTopLayout,
    CompanyForm: LayoutPlansAndAppsCompanyForm,
    Ecosystem: LayoutPlansAndAppsEcosystem,
    Users: LayoutPlansAndAppsUsers,
    Input: LayoutPlansAndAppsInput,
    ButtonSave: LayoutPlansAndAppsButtonSave,
    UserLog: LayoutPlansAndAppsLogUser,
    UserDetailsModal: LayoutPlansAndAppsDetailsUser,
    EconomyGroup: LayoutPlansAndAppsEconomyGroup
  };
  