import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../interfaces";

export default function LayoutGerenciadorRoot({ children }: IRootProps) {
  return (
    <Flex flexDir={"column"} w={"full"} minHeight={"100vh"}>
      {children}
    </Flex>
  );
}
