import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

interface TextInputProps extends InputProps {
  label: string;
  error?: FieldError;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, name, error, isRequired, ...props }, ref) => {
    return (
      <FormControl isInvalid={!!error} id={name} isRequired={isRequired}>
        <FormLabel htmlFor={name} fontSize={"xs"} mb={"2px"} color={"#8F8F8F"}>
          {label}
        </FormLabel>
        <Input
          name={name}
          id={name}
          ref={ref}
          isRequired={isRequired}
          backgroundColor={"#f5f5f5"}
          borderRadius={"4px"}
          fontSize={"sm"}
          _hover={{ borderColor: "inherit" }}
          {...props}
        />

        <FormErrorMessage fontSize={"11px"} mt="2px">
          {!!error && error.message}
        </FormErrorMessage>
      </FormControl>
    );
  }
);
export const TextInputAble = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, name, error, isRequired, ...props }, ref) => {
    return (
      <FormControl isInvalid={!!error} id={name} isRequired={isRequired}>
        <FormLabel htmlFor={name} fontSize={"xs"} mb={"2px"} color={"#8F8F8F"}>
          {label}
        </FormLabel>
        <Input
          name={name}
          id={name}
          ref={ref}
          isRequired={isRequired}
          background={"white"}
          borderColor={"#8F8F8F"}
          borderRadius={"4px"}
          fontSize={"sm"}
          _hover={{ borderColor: "inherit" }}
          {...props}
        />

        <FormErrorMessage fontSize={"11px"} mt="2px">
          {!!error && error.message}
        </FormErrorMessage>
      </FormControl>
    );
  }
);
