import { toPattern } from "vanilla-masker";

type Patterns = "phone" | "cep" | "document";

const patterns = {
  phone: ["(99) 9999-9999", "(99) 9 9999-9999"],
  document: ["999.999.999-99", "99.999.999/9999-99"],
  cep: "99999-999",
};

export const masked = (value = "", pattern: Patterns) => {
  let maskedValue = "";

  if (pattern === "phone") {
    const size = value.replace(/\D/g, "").length > 10 ? 1 : 0;
    maskedValue = toPattern(value, patterns[pattern][size]);
  }

  if (pattern === "document") {
    const size = value.replace(/\D/g, "").length > 11 ? 1 : 0;
    maskedValue = toPattern(value, patterns[pattern][size]);
  }

  if (pattern === "cep") return toPattern(value, patterns.cep);

  return maskedValue;
};

export const unMasked = (value: string) => value.replace(/[^a-zA-Z0-9]/g, "");
