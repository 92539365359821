import {
  Box,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";

interface IInputProps {
  text?: string;
  value?: string | number;
  readonly?: boolean;
  onChange?: (event) => any;
  handleClick?: (event) => any;
  isChecked?: boolean;
  register?: any;
  size?: string;
  textAlign?: "center" | "left";
  search?: boolean;
  placeholder?: string;
  type?: "number" | "text";
}

export default function LayoutPlansAndAppsInput({
  text,
  value,
  readonly = true,
  isChecked,
  onChange,
  register,
  size,
  textAlign,
  search,
  handleClick,
  placeholder,
  type,
  ...props
}: IInputProps) {
  return (
    <Box>
      {text && (
        <Text
          fontSize={size ? size : "md"}
          textAlign={textAlign ? textAlign : "center"}
          color={"#303030"}
        >
          {text}
        </Text>
      )}
      <InputGroup>
        {search && (
          <InputLeftElement pointerEvents="none">
            <BsSearch color="gray.300" />
          </InputLeftElement>
        )}
        <Input
          w={"full"}
          size="md"
          p={1}
          type={type === "number" ? "number" : "text"}
          value={value}
          color={"gray"}
          backgroundColor={readonly ? "#F0F0F0" : "#fff"}
          borderRadius={"4px"}
          _hover={{ borderColor: "inherit" }}
          textAlign={"center"}
          readOnly={readonly}
          onChange={onChange}
          placeholder={placeholder}
          minWidth={"max-content"}
          {...props}
        />
        
        {handleClick && !search &&  (
        
            <Button
              ml={2}
              size="md"
              onClick={handleClick}
              colorScheme="blue"
              isDisabled={!value}
              variant={"outline"}
            >
              Adicionar
            </Button>
        
        )}

        {search && value && (
          <InputRightElement cursor={"pointer"}>
            {value?.toString().length > 0 && (
              <ImCancelCircle
                size={"20px"}
                color={"gray"}
                onClick={handleClick}
              />
            )}
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  );
}
