import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { UserLogged } from "src/components/HeaderPages/UserLogged";
import { ButtonLogout } from "src/components/HeaderPages/ButtonLogout";
import { FiChevronDown } from "react-icons/fi";

export default function LayoutLogged() {
  return (
    <Menu >
      <MenuButton as={Button} variant={"ghost"} rightIcon={<FiChevronDown />}>
        <UserLogged />
      </MenuButton>
      <MenuList minWidth={"150px"} zIndex={3} right={0} >
        <MenuGroup title="">
          <MenuItem left={0}>
            <ButtonLogout />
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}
