import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";

import { unMasked } from "src/utils/masked";
import { useGlobal } from "src/contexts/global";
import { IAplicativo } from "src/Interfaces/App";
import {
  apiInstance,
  postLog,
  updateAplicativosEmpresa,
  updateUser,
} from "src/services/api";
import { useState } from "react";
import { getAppsActives } from "src/utils/apps";

type TConfirmUpdate = {
  propIsOpenModalConfirmAltPlan: boolean;
  propSetIsOpenModalConfirmAltPlan: () => void;
  propAltApp: string;
  propApps: IAplicativo[];
  propSetApps: React.Dispatch<React.SetStateAction<IAplicativo[]>>;
  propAppSelected: any;
  propSetIsOpenModalUpdate: () => void;
  propSetIsOpenModalDetailApp: () => void;
  altAppDescription: string | undefined;
};

export const ConfirmUpdateApp = ({
  propIsOpenModalConfirmAltPlan,
  propSetIsOpenModalConfirmAltPlan,
  propAltApp,
  propApps,
  propSetApps,
  propAppSelected,
  propSetIsOpenModalUpdate,
  propSetIsOpenModalDetailApp,
  altAppDescription,
}: TConfirmUpdate) => {
  const toast = useToast();
  const { session, details, handleSetDetails } = useGlobal();
  // const [apps, setApps] = useState<IAplicativo[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const typePlan = (plano: string) => {
    return plano ? (plano.includes("GE_L") ? 0 : 1) : 0;
  };

  async function updateUsers(tagOldPlan: string, tagNewPlan: string) {
    // altera o plano nos usuários
    const usersUp = await details?.users.map((user) => {
      const appsReplace = user.aplicativos // elimina duplicação
        .replaceAll("||", "|")
        .split("|")
        .filter((e, i, f) => f.indexOf(e) === i)
        .sort()
        .join("|");

      let oldApp = "";

      appsReplace.split("|").map((app) => {
        if (app.split("_")[0] === tagNewPlan.split("_")[0]) {
          return (oldApp = app);
        }
        return "";
      });

      oldApp = oldApp !== "" ? oldApp : tagOldPlan;

      user.aplicativos = tagNewPlan
        ? appsReplace.replace(oldApp, tagNewPlan)
        : user.aplicativos; //getAppsActives(newapps)
      user.type = tagNewPlan ? typePlan(tagNewPlan) : user.type;
      user.indhospedagem = tagNewPlan
        ? typePlan(tagNewPlan).toString()
        : String(user.type);
      updateUser(user, session.token);
      return user;
    });
    // atualiza o plano dos usuários no local storage
    await handleSetDetails({
      company: details.company,
      users: JSON.stringify(usersUp),
    });
  }

  const changeApp = async () => {
    setIsLoading(true);
    try {
      const idAppChange: any = propApps.filter((app) => {
        return app.aplicativoId === parseInt(propAltApp);
      });

      let tagOldPlan = "";
      let tagNewPlan = "";
      let gestorId = 0;

      const newapps: IAplicativo[] = await propApps.map((app) => {
        if (app.aplicativoId === propAppSelected.aplicativoId) {
          tagOldPlan = app.tag;
          return {
            ...app,
            indAtivar: "N",
          };
        }

        if (app.aplicativoId === parseInt(propAltApp)) {
          tagNewPlan = app.tag;
          gestorId = app.valor;
          return {
            ...app,
            indAtivar: "S",
          };
        } else {
          return app;
        }
      });

      if (!tagNewPlan) {
        propSetIsOpenModalConfirmAltPlan();
        toast({
          title: "O plano selecionado é o mesmo que o atual",
          description: "Selecione outro plano para realizar a alteração",
          position: "top",
          status: "warning",
          isClosable: true,
        });
        return;
      }

      // details.company.aplicativos.replace(tagOldPlan, tagNewPlan);

      await propSetApps(newapps);

      if (idAppChange[0].codigo >= 1100 && idAppChange[0].codigo <= 1200) {
        details.company.indHospedagem = 0;
      } else if (
        idAppChange[0].codigo > 1200 &&
        idAppChange[0].codigo <= 3000
      ) {
        details.company.indHospedagem = 1;
      }

      const dataCompanySlected = details.company;

      if (tagNewPlan) {
        dataCompanySlected.planoGestorId = gestorId;
        dataCompanySlected.indHospedagem = typePlan(tagNewPlan);
      } //Number(propAltApp);
      await sendindHospedagem(dataCompanySlected, newapps);

      propSetIsOpenModalConfirmAltPlan();
      propSetIsOpenModalUpdate();
      propSetIsOpenModalDetailApp();

      await updateUsers(tagOldPlan, tagNewPlan); // importante chamar antes de updateAplicativosEmpresa para gravar o indhospedagem

      await updateAplicativosEmpresa(details.company.cnpjCpf, newapps);

      await postLog({
        acao: getAppsActives(newapps),
        idUsuario: 0,
        nomeUsuario: session.role,
        Anterior: getAppsActives(newapps).replace(tagNewPlan, tagOldPlan),
        tipoAcao: "Put",
        endPoint: "/workspace/Aplicativo/Empresa",
        aplicativo: "workspace",
        empresaCnpj: details.company.cnpjCpf,
      });

      toast({
        title: "Aplicativo alterado com Sucesso",
        position: "top",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Falha na gravação do banco de dados",
        position: "top",
        status: "error",
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Envio key indHospedagem para criação do AD
  const sendindHospedagem = async (data: any, newapps: IAplicativo[]) => {
    try {
      const payload = {
        aplicativos: getAppsActives(newapps),
        empresaId: data.empresaId,
        cnpjCpf: unMasked(data.cnpjCpf),
        indhospedagem: data.indHospedagem.toString(),
        planoGestorId: data.planoGestorId,
        indPlano: data.indPlano,
        type: data.indHospedagem,
      };
      await apiInstance(9008)
        .put(
          "/api/Empresa",
          {
            ...payload,
          },
          {
            headers: {
              authorization: `Bearer ${session.token}`,
            },
          }
        )
        .then((result) => {
          handleSetDetails({
            company: result.data?.company,
          });
          getDetailsCompanyByCnpj(result.data?.company?.cnpjCpf, session.token);
        });
    } catch (error) {
      toast({
        title: "Falha ao salvar",
        description: "Não foi possivel alterar o ambiente da empresa",
        position: "top-right",
        status: "error",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
    }
  };

  const getDetailsCompanyByCnpj = async (cnpj: string, token: any) => {
    return apiInstance(9008)
      .get(`/api/Empresa/nucleo?cnpj=${cnpj}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then((result) =>
        result?.data?.dados[0] ? handleSetDetails(result?.data?.dados[0]) : null
      );
  };

  const cancelSelection = () => {
    propSetIsOpenModalConfirmAltPlan();
  };

  return (
    <Modal
      isOpen={propIsOpenModalConfirmAltPlan}
      onClose={() => propSetIsOpenModalConfirmAltPlan}
      closeOnOverlayClick={false}
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar plano</ModalHeader>
        <Divider w={"400px"} borderColor="#D3D3D3" mx={"20px"} />
        <ModalBody>
          <Text>
            Você tem certeza que deseja alterar o plano para
            <Text as={"b"}> {altAppDescription} ?</Text>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button w="180px" mr={3} onClick={cancelSelection}>
            Cancelar
          </Button>
          <Button
            w="180px"
            colorScheme="blue"
            mr={3}
            onClick={changeApp}
            isLoading={isLoading}
            loadingText="Salvando..."
          >
            Alterar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
