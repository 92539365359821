import { Box, Flex, Radio, RadioGroup, Text } from "@chakra-ui/react";

import { ReactComponent as Rocket } from "src/assets/svgs/rocket.svg";
import { ReactComponent as Canceled } from "src/assets/svgs/canceled.svg";
import { ReactComponent as Boat } from "src/assets/svgs/boat.svg";
import { ReactComponent as Pending } from "src/assets/svgs/pending.svg";
import { ReactComponent as PendingWK } from "src/assets/svgs/pending-wk.svg";
import { useEffect, useState } from "react";
import { HeaderInfo } from "../HeaderInfo";
import { useGlobal } from "src/contexts/global";
import React from "react";

const statusType = [
  {
    ind: "1",
    title: "Ativo",
    icon: <Rocket />,
    color: "#1155BB",
  },
  {
    ind: "2",
    title: "Cancelado",
    icon: <Canceled />,
    color: "#F93D3D",
  },
  {
    ind: "3",
    title: " Onboarding",
    icon: <Boat />,
    color: "#F9CC3D",
  },
  {
    ind: "4",
    title: "Adequação Pendente",
    icon: <Pending />,
    color: "#BABABA",
  },
  {
    ind: "5",
    title: "Workspace Pendente",
    icon: <PendingWK />,
    color: "#BABABA",
  },
];

interface IStatusProps {
  denied?: boolean;
  register: any;
}

export const Status = React.forwardRef<HTMLInputElement, IStatusProps>(
  ({ denied = true, register }: IStatusProps, ref) => {
    const { session, details } = useGlobal();
    const [statusValue, setStatusValue] = useState(details.company.indStatus);

    useEffect(() => {
      setStatusValue(details?.company?.indStatus);
    }, [details.company?.cnpjCpf]);

    return (
      <>
        <Flex
          minHeight={"20px"}
          alignItems="center"
          position={"relative"}
          zIndex={2}
          // mt={"40px"}
          mt={"20px"}
          mb={"40px"}
        >
          <Box
            w={"full"}
            height={"24px"}
            bgColor={statusType.find((s) => s.ind === statusValue)?.color}
          />
          <Flex
            position={"absolute"}
            alignItems="center"
            left={"50%"}
            transform={"auto"}
            translateX={"-50%"}
          >
            {statusType.find((s) => s.ind === statusValue)?.icon}

            <Text
              textTransform={"uppercase"}
              ml={2}
              color={"high.pure"}
              fontSize={"sm"}
              fontWeight={"bold"}
            >
              {statusType.find((s) => s.ind === statusValue)?.title}
            </Text>
          </Flex>
        </Flex>
        {(session.perfil === "1" ||
          session.perfil === "3" ||
          session.perfil === "5") && (
          <Flex
            mb={"20px"}
            flexDir={"column"}
            alignItems={"center"}
            overflow={"auto"}
          >
            <Flex w={"full"} flexDir={"column"}>
              <HeaderInfo title="Status do Cadastro" />
              <RadioGroup
                onChange={setStatusValue}
                value={statusValue}
                ref={ref}
              >
                <Flex
                  gap={"20px"}
                  justifyContent={"center"}
                  minWidth={"fit-content"}
                >
                  {statusType.map((item, index) => (
                    <Radio
                      borderColor={"1px solid #1155BB"}
                      color={"#606060"}
                      value={item.ind}
                      {...register("indStatus")}
                      key={"statusRadio-" + index}
                    >
                      {item.title}
                    </Radio>
                  ))}
                </Flex>
              </RadioGroup>
            </Flex>
          </Flex>
        )}
      </>
    );
  }
);

Status.displayName = "Status";
