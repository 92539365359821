import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import jwt_decode from "jwt-decode";

import { useGlobal } from "src/contexts/global";
import { postLog, updateAplicativosEmpresa } from "src/services/api";
import { getAppsActives } from "src/utils/apps";

interface IAplicativo {
  aplicativoId: number;
  codigo: number;
  nome: string;
  descricao: string;
  indStatus: number;
  valor: number;
  tag: string;
  indAtivar: "S" | "N";
  indLista: number;
  hasShow: boolean;
}

type TAppsEcosystem = {
  propIsOpenModalNewApp: boolean;
  propSetIsOpenModalNewApp: () => void;
  propSetApps: React.Dispatch<React.SetStateAction<IAplicativo[]>>;
  propApps: IAplicativo[];
  propGetApps: () => void;
};

export const InsertNewApp = ({
  propIsOpenModalNewApp,
  propSetIsOpenModalNewApp,
  propSetApps,
  propApps,
  propGetApps,
}: TAppsEcosystem) => {
  const toast = useToast();
  const [selectedAppsGestor, setSelectedAppsGestor] = useState("0");
  const [appsUnique, setAppsUnique] = useState("0");
  const [appsSimplaz, setAppsSimplaz] = useState("0");
  const { details, session } = useGlobal();

  const onlyAppsGestor = propApps.filter((app) =>
    app.descricao.toUpperCase().includes("GESTOR")
  );
  const hasGestor = onlyAppsGestor.find(
    (appGestor) => appGestor.indAtivar === "S"
  );

  const onlyAppsUnique = propApps.filter((app) =>
    app.descricao.toUpperCase().includes("UNIQUE")
  );
  const hasUnique = onlyAppsUnique.find(
    (appGestor) => appGestor.indAtivar === "S"
  );

  const onlyAppsSimplaz = propApps.filter((app) =>
    app.descricao.toUpperCase().includes("SIMPLAZ")
  );
  const hasSimplaz = onlyAppsSimplaz.find(
    (appGestor) => appGestor.indAtivar === "S"
  );

  const handleCheckedApp = (app: IAplicativo, checked: boolean) => {
    const newApps: IAplicativo[] = propApps.map((item) => {
      if (item.aplicativoId === app.aplicativoId) {
        return {
          ...item,
          indAtivar: checked ? "S" : "N" ? "N" : "S",
        };
      } else {
        return item;
      }
    });
    propSetApps(newApps);
  };

  const gravarLog = (newApps, oldApps) => {
    const { unique_name } = jwt_decode(session.token) as {
      unique_name: string;
    };

    postLog({
      acao: getAppsActives(newApps),
      idUsuario: 0,
      nomeUsuario: unique_name[0],
      Anterior: getAppsActives(oldApps.filter(app => app.hasShow === true && app.indAtivar === 'S')),
      tipoAcao: "Post",
      endPoint: "/workspace/Aplicativo/Empresa",
      aplicativo: "nucleo",
      empresaCnpj: details.company.cnpjCpf,
    });
  };

  const handleSetarAplicativo = async () => {
    try {
      const newApps: IAplicativo[] = await propApps.map((item) => {
        if (
          item.aplicativoId === Number(selectedAppsGestor) ||
          item.aplicativoId === Number(appsUnique) ||
          item.aplicativoId === Number(appsSimplaz)
        ) {
       
          return {
            ...item,
            indAtivar: "S",
          };
        } else {
          return item;
        }
      });

      await propSetApps(newApps);
      await updateAplicativosEmpresa(details.company.cnpjCpf, newApps);
      await gravarLog(newApps, propApps);

      setSelectedAppsGestor("0");
      setAppsUnique("0");
      setAppsSimplaz("0");

      propSetIsOpenModalNewApp();

      toast({
        title: "Aplicativo adicionado com Sucesso",
        position: "top",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      propSetIsOpenModalNewApp();
      toast({
        title: "Falha na gravação do banco de dados",
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };

  const cancelSelection = () => {
    propGetApps();
    propSetIsOpenModalNewApp();
  };

  return (
    <Flex>
      <Modal
        isOpen={propIsOpenModalNewApp}
        onClose={propSetIsOpenModalNewApp}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Selecione os serviços para adicionar ao perfil
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              {/* Aplicativos Gestor */}
              {!hasGestor && (
                <RadioGroup
                  onChange={setSelectedAppsGestor}
                  value={selectedAppsGestor}
                >
                  <Flex direction="column" h={"full"}>
                    <Text as={"b"}>Gestor</Text>
                    {propApps.map(
                      (app) =>
                        app.descricao.toUpperCase().includes("GESTOR") && (
                          <Radio
                            key={app.aplicativoId}
                            value={app.aplicativoId.toString()}
                            size={"sm"}
                            borderColor={"#666"}
                          >
                            {app.descricao}
                          </Radio>
                        )
                    )}
                  </Flex>
                </RadioGroup>
              )}
              {/* Aplicativos Unique */}
              {!hasUnique && (
                <RadioGroup onChange={setAppsUnique} value={appsUnique}>
                  <Flex
                    direction="column"
                    h={"full"}
                    borderLeft={"1px solid #f5f5f5"}
                    ml={"20px"}
                    pl={"20px"}
                  >
                    <Text as={"b"}>Unique</Text>
                    {propApps.map(
                      (app) =>
                        app.descricao.toUpperCase().includes("UNIQUE") && (
                          <Radio
                            key={app.aplicativoId}
                            value={app.aplicativoId.toString()}
                            size={"sm"}
                            borderColor={"#666"}
                          >
                            {app.descricao}
                          </Radio>
                        )
                    )}
                  </Flex>
                </RadioGroup>
              )}
              {/* Aplicativos Simplaz */}
              {!hasSimplaz && (
                <RadioGroup onChange={setAppsSimplaz} value={appsSimplaz}>
                  <Flex
                    direction="column"
                    h={"full"}
                    borderLeft={"1px solid #E0E0E0"}
                    ml={"20px"}
                    pl={"20px"}
                  >
                    <Text as={"b"}>Simplaz</Text>
                    {propApps.map(
                      (app) =>
                        app.descricao.toUpperCase().includes("SIMPLAZ") && (
                          <Radio
                            key={app.aplicativoId}
                            value={app.aplicativoId.toString()}
                            size={"sm"}
                            borderColor={"#666"}
                          >
                            {app.descricao}
                          </Radio>
                        )
                    )}
                  </Flex>
                </RadioGroup>
              )}
              {/* Outros aplicativos */}
              <Flex
                direction="column"
                borderLeft={"1px solid #f5f5f5"}
                ml={"20px"}
                pl={"20px"}
              >
                <Text as={"b"}>Outros</Text>
                {propApps.map((app) =>
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("GESTOR") &&
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("UNIQUE") &&
                  !app.descricao ===
                    app.descricao.toUpperCase().includes("SIMPLAZ") &&
                  !app.descricao.toUpperCase().includes("CERTIFICADO") ? (
                    <Checkbox
                      key={app.aplicativoId}
                      value={app.aplicativoId.toString()}
                      size={"sm"}
                      borderColor={"#666"}
                      onChange={(ev) =>
                        handleCheckedApp(app, ev.target.checked)
                      }

                      // onChange={() => handleOtherSelectApps(app.aplicativoId)}
                    >
                      {app.descricao}
                    </Checkbox>
                  ) : (
                    ""
                  )
                )}
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={cancelSelection}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSetarAplicativo}
              // onClick={showApps}
            >
              Adicionar Serviços
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

// import {
//   Button,
//   Checkbox,
//   Flex,
//   Modal,
//   ModalBody,
//   ModalContent,
//   ModalFooter,
//   ModalHeader,
//   ModalOverlay,
//   useToast,
// } from "@chakra-ui/react";
// import { useEffect, useState } from "react";
// import { useGlobal } from "src/contexts/global";
// import { apiInstance, updateAplicativosEmpresa } from "src/services/api";

// interface IAplicativo {
//   aplicativoId: number;
//   codigo: number;
//   nome: string;
//   descricao: string;
//   indStatus: number;
//   valor: number;
//   tag: string;
//   indAtivar: "S" | "N";
//   indLista: number;
//   hasShow: boolean;
// }

// type TInsertNewApp = {
//   propIsOpenModalAdd: boolean;
//   propSetIsOpenModalAdd: () => void;
// };

// export const InsertNewApp = ({
//   propIsOpenModalAdd,
//   propSetIsOpenModalAdd,
// }: TInsertNewApp) => {
//   const toast = useToast();
//   const { session, details } = useGlobal();
//   const [apps, setApps] = useState<IAplicativo[]>([]);

//   const getApps = async () => {
//     try {
//       apiInstance(9000)
//         .get(`/workspace/Aplicativo/Empresa/${details.company?.cnpjCpf}`, {
//           headers: {
//             gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
//           },
//         })
//         .then((res) => {
//           const aplicativos = res.data.data as IAplicativo[];
//           aplicativos.forEach((app) => {
//             app.hasShow = app.indAtivar === "S" ? true : false;
//           });

//           setApps(aplicativos);
//         });
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const handleSetarAplicativo = async () => {
//     try {
//       // await updateAplicativosEmpresa(details.company.cnpjCpf, apps);
//       await getApps();
//       propSetIsOpenModalAdd();

//       session.perfil === "5"
//         ? toast({
//             title: "Aplicativo adicionado com Sucesso",
//             position: "top",
//             status: "success",
//             isClosable: true,
//           })
//         : toast({
//             title: "Aplicativo removido com Sucesso",
//             position: "top",
//             status: "success",
//             isClosable: true,
//           });
//     } catch (error) {
//       propSetIsOpenModalAdd();
//       toast({
//         title: "Falha na gravação do banco de dados",
//         position: "top",
//         status: "error",
//         isClosable: true,
//       });
//     }
//   };

//   function cancelSelection() {
//     getApps();
//     propSetIsOpenModalAdd();
//     // setIsOpenModalConfirmAltPlan(false);
//   }

//   useEffect(() => {
//     getApps();
//   }, []);

//   return (
//     <Modal
//       size="xl"
//       isOpen={propIsOpenModalAdd}
//       onClose={propSetIsOpenModalAdd}
//       preserveScrollBarGap
//       closeOnOverlayClick={false}
//     >
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader>Adicionar Aplicativos</ModalHeader>
//         <ModalBody>
//           <Flex flexDir="column" gap="16px">
//             {apps?.map((app) => {
//               if (!app.hasShow) {
//                 return (
//                   <Checkbox
//                     key={app.aplicativoId}
//                     borderColor={"#606060"}
//                     colorScheme="blue"

//                     // onChange={(ev) => handleCheckedApp(app, ev.target.checked)}
//                   >
//                     {app.nome}
//                   </Checkbox>
//                 );
//               }
//             })}
//           </Flex>
//         </ModalBody>

//         <ModalFooter mt="10px" alignSelf="center">
//           <Button w="180px" mr={3} onClick={cancelSelection}>
//             Cancelar
//           </Button>
//           <Button w="228px" colorScheme="blue" onClick={handleSetarAplicativo}>
//             Salvar
//           </Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// };
