import {
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useGlobal } from "src/contexts/global";
import { apiInstance } from "src/services/api";
import ClipboardJS from "clipboard";

export const TableVersionsGestor = ({
  propVersionGestor,
  propGetVersionsGestor,
}) => {
  interface IdataVersionsComapnies {
    Id: number;
    dtcriacao: Date;
    dtliberacao: Date;
    link: string;
    sistema: string;
    status: number;
    versao: number;
  }

  const toast = useToast();
  const { session } = useGlobal();
  const [releaseDate, setReleaseDate] = useState("");
  const [dateDefaultVersion, setDateDefaultVersion] = useState(new Date());
  const [
    isOpenModalConfirmUpdateDateVersion,
    setIsOpenModalConfirmUpdateDateVersion,
  ] = useState(false);
  const [versionToUpdate, setVersionToUpdate] =
    useState<IdataVersionsComapnies>();

  const updateStatusVersion = async (currentStatus: IdataVersionsComapnies) => {
    try {
      if (currentStatus.status === 1) {
        currentStatus.status = 2;
      } else {
        currentStatus.status = 1;
      }

      const payload = {
        versao: currentStatus.versao,
        sistema: currentStatus.sistema,
        dtliberacao: currentStatus.dtliberacao,
        status: currentStatus.status,
        link: currentStatus.link,
      };

      const { data } = await apiInstance(9008).put(
        "/api/Versao",
        {
          ...payload,
        },
        {
          headers: {
            authorization: `Bearer ${session.token}`,
          },
        }
      );
      propGetVersionsGestor();
    } catch (error) {
      console.log(error);
    }
  };

  const changeDate = (
    newDateReleaseVersion,
    currentDateReleaseVersion,
    dataVersionSelected
  ) => {
    if (currentDateReleaseVersion !== newDateReleaseVersion) {
      setReleaseDate(newDateReleaseVersion);
      setVersionToUpdate(dataVersionSelected);
      setIsOpenModalConfirmUpdateDateVersion(true);
    }
  };

  const updateDateReleaseversion = async () => {
    try {
      const payload = {
        versao: versionToUpdate?.versao,
        sistema: versionToUpdate?.sistema,
        dtliberacao: releaseDate,
        status: versionToUpdate?.status,
        link: versionToUpdate?.link,
      };

      console.log(payload);

      const { data } = await apiInstance(9008).put(
        "/api/Versao",
        {
          ...payload,
        },
        {
          headers: {
            authorization: `Bearer ${session.token}`,
          },
        }
      );
      propGetVersionsGestor();
    } catch (error) {
      console.log(error);
    }
    setIsOpenModalConfirmUpdateDateVersion(false);
  };

  const findDefaultVersion = () => {
    const versionReleaseDates = propVersionGestor.map((versionCompany) => {
      return versionCompany.dtliberacao;
    });
    // console.log(
    //   dataVersionsComapnies.sort((a, b) => {
    //     if (new Date(a.dtcriacao) > new Date(b.dtcriacao)) return 1;
    //     if (new Date(b.dtcriacao) > new Date(a.dtcriacao)) return 2;
    //     return 0;
    //   })
    // );

    let maxDateVersionRelease = versionReleaseDates[0];

    for (let i = 1; i < versionReleaseDates.length; i++) {
      const currentDate = new Date(versionReleaseDates[i]);
      if (currentDate > maxDateVersionRelease) {
        maxDateVersionRelease = currentDate;
      }
    }

    setDateDefaultVersion(maxDateVersionRelease);
  };

  const copy = () => {
    const clipboard = new ClipboardJS(".fullName");
    clipboard.on("success", (e) => {
      toast({
        id: Math.random(),
        title: "Copiado com sucesso!",
        status: "success",
        position: "top",
        duration: 2000,
      });
      e.clearSelection();
    });
    setTimeout(() => {
      clipboard.destroy();
    }, 100);
  };

  useEffect(() => {
    if (propVersionGestor.length > 0) findDefaultVersion();
  }, [propVersionGestor]);

  return (
    <Tabs>
      <TabList>
        <Tab>Ativos</Tab>
        <Tab>Inativos</Tab>
        <Tab>Todos</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p={"0px"}>
          <Table mt={"10px"} >
            <Thead>
              <Tr>
                <Th
                  p={"15px"}
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Default
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Versão
                </Th>
                <Th
                  width={"200px !important"}
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  FTP
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                  whiteSpace={"nowrap"}
                >
                  Data de criação
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Status
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                  whiteSpace={"nowrap"}
                >
                  Data de liberação
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {propVersionGestor.map((dataVersionCompany) => (
                <Tr key={dataVersionCompany.versao}>
                  {dataVersionCompany.status === 1 && (
                    <>
                      <Td p={"15px"}>
                        <Box ml={"20px"} w={"22px"}>
                          {dateDefaultVersion ===
                          dataVersionCompany.dtliberacao ? (
                            <AiFillStar color={"#F9CC3D"} size={"20px"} />
                          ) : (
                            <AiOutlineStar color={"#303030"} size={"20px"} />
                          )}
                        </Box>
                      </Td>

                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {dataVersionCompany.versao}
                      </Td>
                      <Td
                        maxWidth={"50ch"}
                        textOverflow={"ellipsis"}
                        overflow="hidden"
                        whiteSpace={"nowrap"}
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        <Tooltip label={dataVersionCompany.link}>
                          <Text
                            _hover={{ color: "blue" }}
                            cursor="pointer"
                            className="fullName"
                            onClick={copy}
                            data-clipboard-text={dataVersionCompany.link}
                          >
                            {dataVersionCompany.link}
                          </Text>
                        </Tooltip>
                        {/* {dataVersionCompany.link} */}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {new Date(
                          dataVersionCompany.dtcriacao
                        ).toLocaleDateString()}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {dataVersionCompany.status === 1 ? "Ativo" : "Inativo"}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        <Input
                          type={"date"}
                          w={"160px"}
                          value={String(dataVersionCompany.dtliberacao).slice(
                            0,
                            10
                          )}
                          onChange={(ev) =>
                            changeDate(
                              ev.target.value,
                              String(dataVersionCompany.dtliberacao),
                              dataVersionCompany
                            )
                          }
                          // onClick={() => console.log(releaseDate)}
                        />
                      </Td>
                      <Td>
                        {dataVersionCompany.status === 1 ? (
                          <Button
                            w={"143px"}
                            h={"40px"}
                            fontFamily={"inter, sans-serif"}
                            fontWeight={"600"}
                            fontSize={"16px"}
                            leftIcon={<IoMdRemoveCircleOutline size={"20px"} />}
                            colorScheme={"gray"}
                            onClick={() => {
                              updateStatusVersion(dataVersionCompany);
                            }}
                          >
                            Desativar
                          </Button>
                        ) : (
                          <Button
                            w={"143px"}
                            fontFamily={"inter, sans-serif"}
                            fontWeight={"600"}
                            fontSize={"16px"}
                            leftIcon={<IoMdAddCircleOutline size={"20px"} />}
                            colorScheme={"blue"}
                            onClick={() => {
                              updateStatusVersion(dataVersionCompany);
                            }}
                          >
                            Ativar
                          </Button>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TabPanel>
        <TabPanel>
          <Table borderColor={"#f5f5f5"} colorScheme={"#f5f5f5"}>
            <Thead>
              <Tr>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Default
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Versão
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  FTP
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Data de criação
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Status
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Data de liberação
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {propVersionGestor.map((dataVersionCompany) => (
                <Tr key={dataVersionCompany.versao}>
                  {dataVersionCompany.status === 2 && (
                    <>
                      <Td>
                        <Box ml={"20px"} w={"22px"}>
                          {dateDefaultVersion ===
                          dataVersionCompany.dtliberacao ? (
                            <AiFillStar color={"#F9CC3D"} size={"20px"} />
                          ) : (
                            <AiOutlineStar color={"#303030"} size={"20px"} />
                          )}
                        </Box>
                      </Td>

                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {dataVersionCompany.versao}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {dataVersionCompany.link}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {new Date(
                          dataVersionCompany.dtcriacao
                        ).toLocaleDateString()}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {dataVersionCompany.status === 2 ? "Inativo" : "Ativo"}
                      </Td>
                      <Td
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"400"}
                        fontSize={"12px"}
                      >
                        {new Date(
                          dataVersionCompany.dtliberacao
                        ).toLocaleDateString()}
                      </Td>
                      <Td>
                        {dataVersionCompany.status === 2 ? (
                          <Button
                            w={"143px"}
                            h={"40px"}
                            fontFamily={"inter, sans-serif"}
                            fontWeight={"600"}
                            fontSize={"16px"}
                            leftIcon={<IoMdAddCircleOutline size={"20px"} />}
                            colorScheme={"blue"}
                            onClick={() => {
                              updateStatusVersion(dataVersionCompany);
                            }}
                          >
                            Ativar
                          </Button>
                        ) : (
                          <Button
                            w={"143px"}
                            h={"40px"}
                            fontFamily={"inter, sans-serif"}
                            fontWeight={"600"}
                            fontSize={"16px"}
                            leftIcon={<IoMdRemoveCircleOutline size={"20px"} />}
                            colorScheme={"gray"}
                            onClick={() => {
                              updateStatusVersion(dataVersionCompany);
                            }}
                          >
                            Desativar
                          </Button>
                        )}
                      </Td>
                    </>
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TabPanel>
        <TabPanel>
          <Table>
            <Thead>
              <Tr textTransform={"lowercase"}>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Default
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Versão
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  FTP
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Data de criação
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Status
                </Th>
                <Th
                  fontWeight={"400"}
                  fontSize={"16px"}
                  color={"#1155BB"}
                  textTransform={"capitalize"}
                >
                  Data de liberação
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {propVersionGestor.map((dataVersionCompany) => (
                <Tr key={dataVersionCompany.versao}>
                  <Td>
                    <Box ml={"20px"} w={"22px"}>
                      {dateDefaultVersion === dataVersionCompany.dtliberacao ? (
                        <AiFillStar color={"#F9CC3D"} size={"20px"} />
                      ) : (
                        <AiOutlineStar color={"#303030"} size={"20px"} />
                      )}
                    </Box>
                  </Td>
                  <Td
                    fontFamily={"inter, sans-serif"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {dataVersionCompany.versao}
                  </Td>
                  <Td
                    fontFamily={"inter, sans-serif"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {dataVersionCompany.link}
                  </Td>
                  <Td
                    fontFamily={"inter, sans-serif"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {new Date(
                      dataVersionCompany.dtcriacao
                    ).toLocaleDateString()}
                  </Td>
                  <Td
                    fontFamily={"inter, sans-serif"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {dataVersionCompany.status === 2 ? "Inativo" : "Ativo"}
                  </Td>
                  <Td
                    fontFamily={"inter, sans-serif"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    {new Date(
                      dataVersionCompany.dtliberacao
                    ).toLocaleDateString()}
                  </Td>
                  <Td>
                    {dataVersionCompany.status === 1 ? (
                      <Button
                        w={"143px"}
                        h={"40px"}
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"600"}
                        fontSize={"16px"}
                        leftIcon={<IoMdRemoveCircleOutline size={"20px"} />}
                        colorScheme={"gray"}
                        // onClick={() => {
                        //   console.log(dataVersionCompany.status);
                        // }}
                        onClick={() => {
                          updateStatusVersion(dataVersionCompany);
                        }}
                      >
                        Desativar
                      </Button>
                    ) : (
                      <Button
                        w={"143px"}
                        h={"40px"}
                        fontFamily={"inter, sans-serif"}
                        fontWeight={"600"}
                        fontSize={"16px"}
                        leftIcon={<IoMdAddCircleOutline size={"20px"} />}
                        colorScheme={"blue"}
                        // onClick={() => {
                        //   console.log(dataVersionCompany.status);
                        // }}
                        onClick={() => {
                          updateStatusVersion(dataVersionCompany);
                        }}
                      >
                        Ativar
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TabPanel>
      </TabPanels>
      {/* Alterar aplicativos */}
      <Modal
        isOpen={isOpenModalConfirmUpdateDateVersion}
        onClose={() => setIsOpenModalConfirmUpdateDateVersion(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              {`Tem que certeza que deseja alterar a data de liberação de ${"XXX"} para ${releaseDate}`}
            </Text>
            <Flex gap={"20px"} justifyContent={"right"} mt={"20px"}>
              <Button
                onClick={() => setIsOpenModalConfirmUpdateDateVersion(false)}
              >
                Cancelar
              </Button>
              <Button colorScheme={"green"} onClick={updateDateReleaseversion}>
                Confirmar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Tabs>
  );
};
