import {
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import { Select } from "src/components/Forms/Select";
import { TextInput } from "src/components/Forms/TextInput";
import { HeaderInfo } from "src/components/HeaderInfo";
import { useGlobal } from "src/contexts/global";
import { masked } from "src/utils/masked";
import "./css/companyData.css";
import estadosBr from "src/constants/estados";
import { Istate } from "src/constants/estados";
import { useEffect, useState } from "react";
import { getViaCep } from "src/services/api";

interface IPropIndPlano {
  register: any;
  errors: { [key: string]: any };
  setValue: any;
  setError: any;
  clearErrors: any;
}

interface IUsersEmail {
  name: string;
  email: string;
  status: boolean;
  perfil: number;
  phone: string;
}

export const CompanyData = ({
  register,
  errors,
  setValue,
  setError,
  clearErrors,
}: IPropIndPlano) => {
  const [cepAtual, setCepAtual] = useState<string | null>(null);
  const { session, details } = useGlobal();
  const [campoEditavel, setCampoEditavel] = useState<boolean>(false);
  const [emailDesatualizado, setEmailDesatualizado] = useState<boolean>(false);
  const toast = useToast();

  const defaultMsg = {
    required: "Campo obrigatório",
    format: "Campo deve ser númerico",
  };

  const handleGetCep = async (cep: string) => {
    try {
      if (cep !== cepAtual && cep.length === 9) {
        setCepAtual(cep.length === 9 ? cep : null);
        const verifyCep = cep;
        const responseViaCep = await getViaCep(verifyCep);

        if (responseViaCep.erro) {
          toast({
            title: "CEP Inválido",
            description: "Falha ao buscar o cep, verifique e tente novamente",
            position: "top-right",
            status: "warning",
            isClosable: true,
            containerStyle: {
              fontSize: "14px",
            },
          });
        }
        setValue("estado", responseViaCep.uf);
        setValue("endereco", responseViaCep.logradouro);
        setValue("cidade", responseViaCep.localidade);
        setValue("bairro", responseViaCep.bairro);
        setValue("numero", "");
      }
    } catch (error: any) {
      toast({
        title: "CEP Inválido",
        description: "Falha ao buscar o cep, verifique e tente novamente",
        position: "top-right",
        status: "warning",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
    }
  };

  const changeEmail = (event: any) => {
    const usuarioEncontrado = details.users.find(
      (user: { email: string }) => user.email === event.target.value
    );
    setValue("responsavel", usuarioEncontrado?.name, { shouldDirty: true });
    clearErrors("email");
  };

  useEffect(() => {
    setCampoEditavel(
      !!(
        session.perfil === "5" ||
        session.perfil === "1" ||
        session.perfil === "3"
      )
    );
  }, []);

  useEffect(() => {
    const checkEmailCompany = () => {
      const emailEncontrado = !!details?.users.find(
        (user: any) => user.email === details?.company?.email
      );
      setEmailDesatualizado(!emailEncontrado);
      setTimeout(() => {
        if (!emailEncontrado) {
          setError(
            "email",
            { message: "Email desatualizado" },
            { shouldDirty: false }
          );
        }
      }, 1000);
    };
    checkEmailCompany();
  }, [details?.users]);

  return (
    <>
      <HeaderInfo
        title="Dados da Empresa"
        subTitle={`Data de Cadastro: ${new Date(
          details.company?.dtCriacao
        ).toLocaleDateString()} | Data de Alteração: ${new Date(
          details.company?.dtAtualiza
        ).toLocaleDateString()}`}
      />
      <Grid
        templateColumns="repeat(12, 1fr)"
        sx={{
          "@media (max-width: 800px)": {
            gridTemplateColumns: "repeat(1, minmax(150px, 1fr))",
          },
        }}
        gap={2}
        width={"full"}
        alignItems={"end"}
      >
        <GridItem colSpan={6} className="inputChackra">
          <FormControl isInvalid={errors?.razao}>
            <TextInput
              {...register("razao", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Nome / Razão Social *"
              isReadOnly={session?.perfil !== "5"}
              isInvalid={errors?.razao}
            />
            {errors?.razao && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.razao?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={6} className="inputChackra">
          <FormControl isInvalid={errors?.email}>
            <Select
              {...register("email")}
              label="E-mail *"
              onChange={changeEmail}
              isDisabled={!campoEditavel}
            >
              {emailDesatualizado && (
                <option value={details?.company.email}>
                  {details?.company.email}
                </option>
              )}
              {details &&
                details?.users
                  ?.filter((user: IUsersEmail) => user.status !== false)
                  .map((option: IUsersEmail) => (
                    <option key={option.email} value={option.email}>
                      {option.email}
                    </option>
                  ))}
            </Select>
            {errors?.email && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.email?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={6} className="inputChackra">
          <FormControl isInvalid={errors?.responsavel}>
            <TextInput
              {...register("responsavel", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Nome do Responsável (Contrato) *"
              isReadOnly={true}
              isInvalid={errors?.responsavel}
            />
            {errors?.responsavel && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.responsavel?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <FormControl isInvalid={errors?.fone}>
            <TextInput
              {...register("fone", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Telefone *"
              placeholder="(XX) X XXXX-XXXX"
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                (e.target.value = masked(e.target.value, "phone"))
              }
              isReadOnly={!campoEditavel}
              isInvalid={errors?.fone}
            />
            {errors?.fone && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.fone?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <Select
            {...register("indPlano")}
            label="Adequação de Plano *"
            isDisabled={session.perfil !== "3" && session.perfil !== "5"}
          >
            <option value="0">Liberado para acesso</option>
            <option value="1">Adequação necessária</option>
            <option value="2">Plano contratado</option>
          </Select>
        </GridItem>
        <GridItem colSpan={6} className="inputChackra">
          <FormControl isInvalid={errors?.endereco}>
            <TextInput
              {...register("endereco", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Endereço *"
              isReadOnly={!campoEditavel}
              isInvalid={errors?.endereco}
            />
            {errors?.endereco && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.endereco?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <FormControl isInvalid={errors?.numero}>
            <TextInput
              {...register("numero", {
                required: { value: true, message: defaultMsg?.required },
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Somente números são permitidos.",
                },
              })}
              label="Número *"
              isReadOnly={!campoEditavel}
              isInvalid={errors?.numero}
            />
            {errors?.numero && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.numero?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <FormControl isInvalid={errors?.cep}>
            <TextInput
              {...register("cep", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="CEP *"
              placeholder="XXXXX-XXX"
              onChange={(e) => handleGetCep(e.target.value)}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                (e.target.value = masked(e.target.value, "cep"))
              }
              isReadOnly={!campoEditavel}
              isInvalid={errors?.cep}
            />
            {errors?.cep && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.cep?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={6} className="inputChackra">
          <FormControl isInvalid={errors?.bairro}>
            <TextInput
              {...register("bairro", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Bairro *"
              isReadOnly={!campoEditavel}
              isInvalid={errors?.bairro}
            />
            {errors?.bairro && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.bairro?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <FormControl isInvalid={errors?.cidade}>
            <TextInput
              {...register("cidade", {
                required: { value: true, message: defaultMsg?.required },
              })}
              label="Cidade / Município *"
              isReadOnly={!campoEditavel}
              isInvalid={errors?.cidade}
            />
            {errors?.cidade && (
              <FormErrorMessage sx={{ fontSize: "9px" }}>
                {errors?.cidade?.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={3} className="inputChackra">
          <Select
            {...register("estado")}
            label="Estado *"
            isDisabled={!campoEditavel}
          >
            <option value={details?.company?.estado}>
              {details.company?.estado}
            </option>
            {estadosBr &&
              estadosBr?.map((option: Istate) => (
                <option key={option.sigla} value={option.sigla}>
                  {option.sigla}
                </option>
              ))}
          </Select>
        </GridItem>
      </Grid>
      <p style={{ fontSize: "10px", paddingTop: "10px" }}>
        Campos obrigatórios(*)
      </p>
    </>
  );
};

