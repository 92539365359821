export interface perfilInterface {
    id: string,
    name: string
}
export const GestorProfiles: perfilInterface[] = [
  {
    id: "1",
    name: "Administrador",
  },
  {
    id: "2",
    name: "Cadastro",
  },
  {
    id: "3",
    name: "Faturamento",
  },
  {
    name: "PDV",
    id: "4",
  },
  {
    name: "NF-e",
    id: "5",
  },
  {
    id: "6",
    name: "Financeiro",
  },
  {
    id: "7",
    name: "Indústria",
  },
];

export const UniqueProfiles: perfilInterface[] = [
  {
    id: "1",
    name: "Administrador",
  },
  {
    id: "2",
    name: "Cadastro",
  },
  {
    id: "3",
    name: "Folha",
  },
  {
    id: "4",
    name: "Fiscal",
  },
  {
    id: "5",
    name: "Contábil",
  },
  {
    id: "6",
    name: "Contábil Físcal",
  },
];

export const WorkspaceProfiles: perfilInterface[] = [
  {
    id: "0",
    name: "Administrador",
  },
  {
    id: "1",
    name: "Usuário",
  },
];
