import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  Button,
  Switch as ChackraSwitch,
  useToast,
  Text,
  Box,
} from "@chakra-ui/react";

import { Status } from "src/components/Status";
import { apiInstance, updateCompany } from "src/services/api";
import { useGlobal } from "src/contexts/global";
import { IAplicativo } from "src/Interfaces/App";
import { IRegister } from "src/Interfaces/Register";
import { Feedback } from "src/components/Feedback/feedback";
import { SearchCompany } from "src/components/HeaderPages/SearchCompany";
import { unMasked } from "src/utils/masked";
import { Users } from "./Users";
import { CompanyData } from "./CompanyData";
import { PlansAndApps } from "./PlansAndApps";
import { HeaderLayout } from "src/layouts/header";
import { PlansAndAppsLayout } from "src/layouts/plansAndApps";
import LayoutPlansAndAppsInput from "src/layouts/plansAndApps/input/root";

import * as S from "./styles";

export const Details = () => {
  const { session, details, handleSetDetails } = useGlobal();
  const toast = useToast();
  const navigate = useNavigate();
  const [apps, setApps] = useState<IAplicativo[]>([]);
  const [openUserLog, setOpenUserLog] = useState(false);
  const [openEconomyGroup, setEconomyGroup] = useState(false);
  const [financial, setFinancial] = useState(details?.company?.indfinanceiro);

  const {
    handleSubmit,
    setValue,
    register,
    setError,
    reset,
    clearErrors,
    formState: { isSubmitting, isDirty, errors },
  } = useForm<IRegister>({
    shouldFocusError: false,
    defaultValues: details.company,
    mode: "onChange",
  });

  const handleSaveEdit: SubmitHandler<IRegister> = async (dataNucleo) => {
    const emailEncontrado = !!details?.users.find(
      (user: any) => user.email === dataNucleo.email
    );
    if (!emailEncontrado) {
      setError("email", { message: "Email desatualizado" });
      toast({
        title: "Email desatualizado",
        description: "Atualize o email para salvar o formulário",
        position: "top-right",
        status: "warning",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
      return false;
    }
    try {
      const payload = {
        empresaId: details.company.empresaId,
        cnpjCpf: details.company.cnpjCpf,
        aplicativos: details.company?.aplicativos,

        //valores alteraveis
        indStatus: dataNucleo.indStatus,
        indfinanceiro: Boolean(dataNucleo.indfinanceiro) === true ? "0" : "1",
        indParceiro: dataNucleo.indparceiro,
        planoGestorId: Number(
          dataNucleo.planoGestorId || details.company.planoGestorId
        ),
        planoUniqueId: Number(
          dataNucleo.planoUniqueId || details.company.planoUniqueId
        ),
        modeloNf: details.company.modeloNf,
        // campos form
        razao: dataNucleo.razao,
        email: dataNucleo.email,
        responsavel: dataNucleo.responsavel,
        fone: unMasked(dataNucleo.fone),
        indPlano: dataNucleo.indPlano,
        endereco: dataNucleo.endereco,
        numero: dataNucleo.numero,
        cep: dataNucleo.cep,
        bairro: dataNucleo.bairro,
        cidade: dataNucleo.cidade,
        estado: dataNucleo.estado,
      };
      const { company } = await updateCompany(payload, session.token);
      handleSetDetails({
        company: company,
        users: JSON.stringify(details.users),
      }); // atualiza os dados na session
      toast({
        title: "Dados alterados com sucesso!",
        description: "O Cadastro da empresa foi atualizado!",
        position: "top-right",
        status: "success",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
    } catch (error) {
      toast({
        title: "Falha ao salvar",
        description: "Verifique os valores alterados e tente novamente",
        position: "top-right",
        status: "error",
        isClosable: true,
        containerStyle: {
          fontSize: "14px",
        },
      });
    } finally {
      sessionStorage.setItem("haveChanges", "false");
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty && session.perfil !== "4") {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    const popState = () => {
      if (isDirty) {
        sessionStorage.setItem("haveChanges", "false");
      }
    };
    window.addEventListener("popstate", popState);
    return () => {
      sessionStorage.setItem("haveChanges", "false");
      window.removeEventListener("popstate", popState);
    };
  }, [isDirty]);

  useEffect(() => {
    if (isDirty && session.perfil !== "4") {
      sessionStorage.setItem("haveChanges", "true");
    } else {
      sessionStorage.setItem("haveChanges", "false");
    }
  }, [isDirty, register]);

  const getApps = async () => {
    try {
      apiInstance(9000)
        .get(`/workspace/Aplicativo/Empresa/${details.company?.cnpjCpf}`, {
          headers: {
            gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
          },
        })
        .then((res) => {
          const aplicativos = res.data.data as IAplicativo[];
          aplicativos.forEach((app) => {
            app.hasShow = app.indAtivar === "S" ? true : false;
          });
          setApps(aplicativos);
        });
    } catch (error) {
      console.log("Erro ao consultar aplicativos", error);
    }
  };

  const resetFormOnChangeCompany = () => {
    reset(details.company);
    setFinancial(details?.company?.indfinanceiro);
  };

  useEffect(() => {
    resetFormOnChangeCompany();
  }, [details.company?.cnpjCpf]);

  useEffect(() => {
    getApps();
  }, [details.company?.cnpjCpf]);

  useEffect(() => {
    if (Object.keys(details).length === 0) {
      navigate("/geral");
    }
  }, [details, setValue, navigate]);

  if (Object.keys(details).length === 0) {
    return <div />;
  }

  const handleSetFinancial = () => {
    setFinancial(financial === "0" ? "1" : "0");
  };

  return (
    <>
      <Feedback />
      <HeaderLayout.Root>
        <HeaderLayout.Logo />
        <HeaderLayout.Content>
          <SearchCompany />
        </HeaderLayout.Content>
        <HeaderLayout.Logged />
      </HeaderLayout.Root>

      <PlansAndAppsLayout.Root onSubmit={handleSubmit(handleSaveEdit)}>
        <Status denied={!session.permissions.edit.status} register={register} />
        <PlansAndAppsLayout.Top.Root>
          <PlansAndAppsLayout.Top.Left>
            <Button
              colorScheme="blue"
              variant={"outline"}
              onClick={() => setEconomyGroup(!openEconomyGroup)}
            >
              Grupos econômicos
            </Button>
            <PlansAndAppsLayout.EconomyGroup
              open={openEconomyGroup}
              onClose={() => setEconomyGroup(!openEconomyGroup)}
            />
          </PlansAndAppsLayout.Top.Left>
          <PlansAndAppsLayout.Top.Center>
            <LayoutPlansAndAppsInput
              text="ID Empresa"
              value={details.company?.empresaId}
            />
            <LayoutPlansAndAppsInput
              text="CPF/CNPJ"
              value={details.company?.cnpjCpf}
            />

            <Box>
              <Text size={"16px"} textAlign={"center"} color={"#303030"}>
                Status Financeiro
              </Text>

              <S.Switch>
                <ChackraSwitch
                  size={"lg"}
                  bgSize={"cover"}
                  colorScheme={"green"}
                  isChecked={financial === "0" ? true : false}
                  {...register("indfinanceiro", {
                    onChange: handleSetFinancial,
                  })}
                  p={1}
                  isDisabled={session.perfil !== "5" && session.perfil !== "1"}
                >
                  <div className="textSwitch">
                    {financial === "0" ? "Normal" : "Pendente"}
                  </div>
                </ChackraSwitch>
              </S.Switch>
            </Box>
          </PlansAndAppsLayout.Top.Center>
          <PlansAndAppsLayout.Top.Right>
            <Button
              colorScheme="blue"
              variant={"outline"}
              onClick={() => setOpenUserLog(!openUserLog)}
            >
              Visualizar log de ações
            </Button>
          </PlansAndAppsLayout.Top.Right>
        </PlansAndAppsLayout.Top.Root>
        <PlansAndAppsLayout.CompanyForm>
          <CompanyData
            register={register}
            errors={errors}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
          />
        </PlansAndAppsLayout.CompanyForm>

        <PlansAndAppsLayout.Ecosystem>
          <PlansAndApps />
        </PlansAndAppsLayout.Ecosystem>

        <PlansAndAppsLayout.Users>
          <Users apps={apps} />
          <PlansAndAppsLayout.UserLog
            open={openUserLog}
            onClose={() => setOpenUserLog(false)}
          />
        </PlansAndAppsLayout.Users>

        <PlansAndAppsLayout.ButtonSave
          isSubmitting={isSubmitting}
          isDisabled={!isDirty}
        />
      </PlansAndAppsLayout.Root>
    </>
  );
};
