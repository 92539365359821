import { Avatar, Button, Flex, Tooltip, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "src/contexts/global";
import { logout } from "src/utils/logout";
import { ReactComponent as Logo } from "src/assets/svgs/logo-nucleo.svg";

export const HeaderRegisterVersion = () => {
  const { session } = useGlobal();
  const navigate = useNavigate();

  return (
    <Flex
      minH={"80px"}
      w="full"
      bgColor={"high.pure"}
      boxShadow="sm"
      alignItems={"center"}
      px={"20px"}
      mb={"20px"}
    >
      <Button variant={"link"} onClick={() => navigate("/GerenciadorDeVersao")}>
        <Logo />
      </Button>
      <Flex w={"100%"} flexDir={"row"} justifyContent={"end"}>
        <Flex flexDir={"column"} alignItems={"center"}>
          <Tooltip label={session.role}>
            <Avatar name={session.role} size="sm" m={"auto"} />
          </Tooltip>
          <Text>{session.role}</Text>
        </Flex>
        <Flex px={"20px"} textColor={"#1155BB"}>
          <Button color={"blue"} onClick={() => logout()} variant="link">
            Sair
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
