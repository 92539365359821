import { Flex } from "@chakra-ui/react";
import { IRootProps } from "../../interfaces";

export default function LayoutPlansAndAppsTop({ children }: IRootProps) {
  return (
    <Flex
      flexDir={"row"}
      justifyContent={"space-evenly"}
      w={"full"}
      alignItems={"flex-end"}
      marginBottom={6}
      sx={{
        "@media (max-width: 1024px)": {
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "10px",
        },
      }}
    >
      {children}
    </Flex>
  );
}
