import { IAplicativo } from "src/Interfaces/App";

export const getAppsActives = (apps: IAplicativo[]) => {
    return (
      "|" +
      apps
        .map((app) => {
          let tag = "";
          if ((app.hasShow || app.indAtivar === "S") && app.indAtivar !== "N") {
            tag = app.tag;
          }
          return tag.trim();
        })
        .filter((tag) => tag !== "")
        .toString()
        .replaceAll(",", "|") +
      "|"
    );
  };

