import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useState } from "react";

import { Select } from "src/components/Forms/Select";

import { ConfirmUpdateApp } from "./confirmUpdate";
import { TUpdateApp } from "src/Interfaces/App";

export const UpdateApp = ({
  propIsOpenModalUpdate,
  propSetIsOpenModalUpdate,
  propAppSelected,
  propAppChosen,
  propApps,
  propSetApps,
  propSetIsOpenModalDetailApp,
}: TUpdateApp) => {
  const [isOpenModalConfirmAltPlan, setIsOpenModalConfirmAltPlan] =
    useState(false);
  const [altApp, setAltApp] = useState("");
  const [altAppDescription, setAltAppDescription] = useState<string | undefined>("");

  return (
    <>
      <Modal
        isOpen={propIsOpenModalUpdate}
        onClose={propSetIsOpenModalUpdate}
        size={"md"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              label="Alterar plano"
              onChange={(ev) => {
                setAltApp(ev.target.value);
                setAltAppDescription(
                  propAppChosen.find(
                    (app) =>
                      String(app.aplicativoId) === String(ev.target.value)
                  )?.descricao
                );
              }}
            >
              <option>Selecione o aplicativo</option>
              {propAppChosen.map((app) => (
                <option key={app.aplicativoId} value={app.aplicativoId}>
                  {app.descricao}
                </option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter justifyContent={"center"}>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setIsOpenModalConfirmAltPlan(true)}
              disabled={altApp === "" ? true : false}
            >
              Alterar plano
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmUpdateApp
        propIsOpenModalConfirmAltPlan={isOpenModalConfirmAltPlan}
        propSetIsOpenModalConfirmAltPlan={() =>
          setIsOpenModalConfirmAltPlan(false)
        }
        propAltApp={altApp}
        altAppDescription={altAppDescription}
        propApps={propApps}
        propSetApps={propSetApps}
        propAppSelected={propAppSelected}
        propSetIsOpenModalUpdate={propSetIsOpenModalUpdate}
        propSetIsOpenModalDetailApp={propSetIsOpenModalDetailApp}
      />
    </>
  );
};
