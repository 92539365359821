import { Button, Flex, VStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";


import bglogin from "src/assets/bg_login.png";
import { ReactComponent as Illustration } from "src/assets/svgs/illustration.svg";
import { ReactComponent as LogoNucleo } from "src/assets/svgs/logo-nucleo.svg";
import { Feedback } from "src/components/Feedback/feedback";
import { TextInput } from "src/components/Forms/TextInput";
import { useGlobal } from "src/contexts/global";
import { apiInstance } from "src/services/api";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido")
    .required("E-mail obrigatório")
    .trim(),
  password: yup.string().required("Senha obrigatória").trim(),
});

interface Credentials {
  email: string;
  password: string;
}

export const Login = () => {
  const { handleSetLogin } = useGlobal();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Credentials>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const handleLogin: SubmitHandler<Credentials> = async ({
    email,
    password,
  }) => {
    try {
      const { data } = await apiInstance(9008).post("/api/login", {
        login: email.trim(),
        password: password.trim(),
      });
      handleSetLogin(data.accessToken);
    } catch (error) {
      console.log(error);
      // sprint de qualidade
      // alert("e-mail ou senha incorreto, favor verificar");
      toast({
        title: 'E-mail e/ou senha incorreto(s).',
        description: "Favor conferir o que foi digitado, e tente novamente.",
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top-right'
      })
    }
  };

  return (
    <>
      <Feedback />
      <Flex h="full">
        <Flex
          display={{ base: "none", lg: "flex" }}
          position={"relative"}
          w={"400px"}
          h="full"
          backgroundImage={`url(${bglogin})`}
          backgroundPosition="center"
          backgroundSize="cover"
        >
          <Illustration
            style={{
              position: "absolute",
              top: "20%",
              left: "54px",
              height: "65%",
            }}
          />
        </Flex>

        <Flex flex={1} justifyContent="center" alignItems={"center"} flexDirection={"column"}>
          <VStack
            spacing={8}
            w={"360px"}
            as="form"
            onSubmit={handleSubmit(handleLogin)}
          >
            <LogoNucleo />
            <TextInput
              {...register("email")}
              error={errors.email}
              label="E-mail"
              placeholder="email@provider.com"
              // defaultValue="frontteste@londrisoft.com.br"
            />
            <TextInput
              {...register("password")}
              error={errors.password}
              label="Senha"
              type={"password"}
              // defaultValue="Front#2202%"
              placeholder="********"
            />

            <Button
              w="150px"
              colorScheme={"blue"}
              type="submit"
              isLoading={isSubmitting}
            >
              Acessar
            </Button>
          </VStack>
        </Flex>
      </Flex>
    </>
  );
};
