import { useEffect, useState } from "react";

import { IoAddCircleOutline } from "react-icons/io5";
import { Button, Text, Flex } from "@chakra-ui/react";

import { apiInstance, getValidateCertificate } from "src/services/api";
import { useGlobal } from "src/contexts/global";
import { IAplicativo } from "src/Interfaces/App";
import { HeaderInfo } from "src/components/HeaderInfo";

import { DetailsApp } from "./Modals/DetailsApp";
import { InsertNewApp } from "./Modals/InsertNewApp";
import LayoutBoxApp from "src/layouts/plansAndApps/app";

export const PlansAndApps = () => {
  const emptyDate = new Date(1900, 0, 1);
  const { session, details } = useGlobal();
  const [apps, setApps] = useState<IAplicativo[]>([]);
  const [validateCertificado, setValidateCertificado] = useState(emptyDate);
  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);
  const [isOpenModalDetailApp, setIsOpenModalDetailApp] = useState(false);
  const [appSelected, setAppSelected] = useState({} as IAplicativo);
  const [amountApp, setAmountApp] = useState(0);

  const getApps = async () => {
    try {
      apiInstance(9000)
        .get(`/workspace/Aplicativo/Empresa/${details.company?.cnpjCpf}`, {
          headers: {
            gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
          },
        })
        .then((res) => {
          const aplicativos = res.data.data as IAplicativo[];
          aplicativos.forEach((app) => {
            app.hasShow = app.indAtivar === "S" ? true : false;
          });

          setApps(aplicativos);
        });
    } catch (error) {
      console.log("Erro ao consultar aplicativos", error);
    }
  };

  const getVencCerticate = async () => {
    getValidateCertificate(details.company?.cnpjCpf)
      .then((response) =>
        response?.dados
          ? setValidateCertificado(new Date(response?.dados?.vencimento))
          : setValidateCertificado(new Date(0, 0, 0))
      )
      .catch(() => setValidateCertificado(new Date(1900, 0, 1)));
  };

  const handleAppSelected = (app: IAplicativo) => {
    calculateAmountApp(app.tag);
    setAppSelected(app);
    setIsOpenModalDetailApp(true);
  };

  const calculateAmountApp = (tag: string) => {
    let Amount = 0;

    if (
      !tag.toUpperCase().includes("GE_") &&
      !tag.toUpperCase().includes("UN_")
    ) {
      details.users?.forEach((user) => {
        const aplicativos = user.aplicativos;
        if (user.status === true && aplicativos !== null) {
          const aplicativosArray = aplicativos.split("|");
          if (aplicativosArray.includes(tag)) {
            Amount++;
          }
        }
      });

      setAmountApp(Amount);
    } else {
      details.users?.filter((userActive) => {
        userActive.status === true ? Amount++ : (Amount = Amount + 0);
      });

      setAmountApp(Amount);
    }
  };

  useEffect(() => {
    getApps();
    getVencCerticate();
  }, [details.company?.cnpjCpf]);

  return (
    <>
      <HeaderInfo title="Ecossistema Londrisoft" mt={"30px"} mb={"30px"} />
      <Flex w={"full"} wrap={"wrap"} maxWidth={"100%"}>
        {apps?.map((app, index) => {
          if (
            app.indAtivar === "S"
          ) {
            return (
              app.tag !== "CERTD" && (
                <LayoutBoxApp
                  key={`box-app-${index}`}
                  app={app}
                  handleAppSelected={() => handleAppSelected(app)}
                />
              )
            );
          }
          return null;
        })}

        {validateCertificado.toLocaleDateString() !== "01/01/1900" && (
          <LayoutBoxApp
            key={"box-app-999"}
            app={{
              aplicativoId: 999,
              nome: "Certificado Digital",
              vencimento: validateCertificado,
            }}
          />
        )}

        {session.perfil === "2" ||
        session.perfil === "3" ||
        session.perfil === "5" ? (
          <Button
            justifyContent={"center"}
            alignItems={"center"}
            w={"285px"}
            h={"110px"}
            bg={"#166DD7"}
            colorScheme="linkedin"
            border={"1px solid #E0E0E0"}
            borderRadius={"4px"}
            m={"0px 15px 15px 0px"}
            onClick={() => setIsOpenModalAdd(true)}
          >
            <Flex
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"white"}
            >
              <IoAddCircleOutline size={32} />
              <Text mt={"5px"}>Adicionar serviço</Text>
            </Flex>
          </Button>
        ) : (
          ""
        )}
      </Flex>

      <DetailsApp
        propIsOpenModalDetailApp={isOpenModalDetailApp}
        propSetIsOpenModalDetailApp={() => setIsOpenModalDetailApp(false)}
        propAppSelected={appSelected}
        propApps={apps}
        propSetApps={setApps}
        propValidateCertificado={validateCertificado}
        propAmountApp={amountApp}
      />
      <InsertNewApp
        propIsOpenModalNewApp={isOpenModalAdd}
        propSetIsOpenModalNewApp={() => setIsOpenModalAdd(false)}
        propSetApps={setApps}
        propApps={apps}
        propGetApps={getApps}
      />
    </>
  );
};
