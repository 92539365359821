import styled from "@emotion/styled";

export const Table = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 80vh;

  th,
  td {
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    min-width: 150px;
    background-color: #fff;
  }

  th {
    color: #1155bb;
  }

  tr {
    color: #1155bb;
  }

  td {
    color: #666666;
    overflow: auto;
  }
`;

export const Switch = styled.div`
  width: 100%;
  font-size: 14px;

  .chakra-switch {
    width: 130px;

    .chakra-switch__track {
      width: 120px;
      border-radius: 4px;
      padding: 4px;
      background: #f93d3d;

      .chakra-switch__thumb {
        border-radius: 50%;
        transform: translateX(95px);
        width: 1.2rem;
        height: 1.2rem;
        margin-top: 2px;
      }
    }

    .chakra-switch__track[data-checked] {
      background: #38a169;

      .chakra-switch__thumb {
        transform: translateX(5px);
      }
    }

    .chakra-switch__label {
      display: flex;
      justify-content: center;
      div.textSwitch {
        color: #fff;
        position: absolute;
        top: 20px;
        margin-left: -10px;
      }
    }

    .chakra-switch__label[data-checked] {
      div.textSwitch {
        margin-left: 10px;
      }
    }
  }
`;
