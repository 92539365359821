import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
import { getAppsActives } from "src/utils/apps";

import { useGlobal } from "src/contexts/global";
import { postLog, updateAplicativosEmpresa } from "src/services/api";

interface IAplicativo {
  aplicativoId: number;
  codigo: number;
  nome: string;
  descricao: string;
  indStatus: number;
  valor: number;
  tag: string;
  indAtivar: "S" | "N";
  indLista: number;
  hasShow: boolean;
}

type TDeleteApp = {
  propIsOpenModalDelete: boolean;
  propSetIsOpenModalDelete: () => void;
  propAppSelected: IAplicativo;
  propApps: IAplicativo[];
  propSetApps: React.Dispatch<React.SetStateAction<IAplicativo[]>>;
  propSetIsOpenModalDetailApp: () => void;
};

export const DeleteApp = ({
  propIsOpenModalDelete,
  propSetIsOpenModalDelete,
  propAppSelected,
  propApps,
  propSetApps,
  propSetIsOpenModalDetailApp,
}: TDeleteApp) => {
  const toast = useToast();
  const { details, session } = useGlobal();

  const gravarLog = (newApps, oldApps) => {
    const { unique_name } = jwt_decode(session.token) as {
      unique_name: string;
    };

    postLog({
      acao: getAppsActives(newApps),
      idUsuario: 0,
      nomeUsuario: unique_name[0],
      Anterior: getAppsActives(oldApps.filter(app => app.hasShow === false || app.indAtivar === 'S')),
      tipoAcao: "Delete",
      endPoint: "/workspace/Aplicativo/Empresa",
      aplicativo: "nucleo",
      empresaCnpj: details.company.cnpjCpf,
    });
  };

  const handleDeleteApp = async () => {
    try {
      const newapps: IAplicativo[] = propApps.map((item) => {
        if (item.aplicativoId === propAppSelected.aplicativoId) {
          return {
            ...item,
            indAtivar: "N",
          };
        }
        return item;
      });


      await propSetApps(newapps);
      await updateAplicativosEmpresa(details.company.cnpjCpf, newapps);
      await gravarLog(newapps, propApps);

      toast({
        title: "Aplicativo excluido com Sucesso",
        position: "top",
        status: "success",
        isClosable: true,
      });

      propSetIsOpenModalDelete();
      propSetIsOpenModalDetailApp();
    } catch (error) {
      console.log(error);
      toast({
        title: "Falha na gravação do banco de dados",
        position: "top",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={propIsOpenModalDelete}
      onClose={propSetIsOpenModalDelete}
      size={"sm"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Excluir aplicativo</ModalHeader>
        <Divider w={"400px"} borderColor="#D3D3D3" mx={"20px"} />
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Você tem certeza que deseja excluir
            <Text as={"b"}>{` ${propAppSelected.nome}`}</Text>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button w="180px" mr={3} onClick={propSetIsOpenModalDelete}>
            Cancelar
          </Button>
          <Button w="180px" colorScheme="red" mr={3} onClick={handleDeleteApp}>
            excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
