import {
  Button,
  Flex,
  useToast,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { SelectAble } from "src/components/Forms/Select";
import { TextInputAble } from "src/components/Forms/TextInput";
import { filters } from "src/constants/filters";
import { useGlobal } from "src/contexts/global";
import {
  getAllVersionsOfCompaniesGestor,
  getAllVersionsOfCompaniesUnique,
  getCompanyByID,
  getSemaforo,
  getUsersByCompany,
} from "src/services/api";
import { VersionManagerTable } from "../VersionManagerTable";
import { ImCancelCircle } from "react-icons/im";
import {
  CompanyVersionManager,
  FilterAplicativo,
  FilterServer,
  IUserCompany,
  IdataVersionsComapnies,
  TypeTable,
} from "../interfaces";
import { FaSearch } from "react-icons/fa";
import { VersionManagerTableLayout } from "src/layouts/gerenciador/versionManagerTable";
import { VersionManagerFiltersLayout } from "src/layouts/gerenciador/versionManagerFilters";
import { logout } from "src/utils/logout";

export const VersionManagerFilters = () => {
  const toast = useToast();
  const { session } = useGlobal();
  const [dataVersionsComapnies, setDataVersionsComapnies] = useState<
    IdataVersionsComapnies[]
  >([]);
  const [typeTable, setTypeTable] = useState<TypeTable>("empresa");
  const [usersCompany, setUsersCompany] = useState<IUserCompany[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [hasFilter, setHasFilter] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [fieldStatus, setFieldStatus] = useState({
    id: 1,
    name: "Ativo",
  });
  const [fieldServer, setFieldServer] = useState<FilterServer>({
    id: 9999,
    name: "todos",
  });
  const [fieldVersion, setFieldVersion] = useState("");
  const [handleAplicativo, handleSetAplicativo] = useState<FilterAplicativo>({
    id: 2,
    name: "nuvem",
    param: "nuvem",
  });

  const [desiredCompanySelection, setDesiredCompanySelection] = useState<
    CompanyVersionManager[]
  >([]);

  //Função responsavel por setar o tipo da tabela entre empresa e usuario de acordo com a seleção do campo
  const isTableCompany = (isCompany) => {
    if (isCompany.id === 0) {
      setTypeTable("empresa");
    } else {
      setTypeTable("usuario");
    }
  };

  const messageNotFound = () => {
    toast({
      title: "Nenhum resultado encontrado",
      description: "Verifique se digitou corretamente e tente novamente",
      position: "top-right",
      status: "info",
      isClosable: true,
      containerStyle: {
        fontSize: "14px",
      },
    });
  };

  //Função responsavel por realizar a pesquisa segundo os filtros informados
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const SearchFilterGerenciador = async () => {
    let typedFilter = "";
    setIsLoading(true);
    setUsersCompany([]);
    setDesiredCompanySelection([]);

    if (typeTable === "usuario") {
      await getUsersByCompany(`${searchValue}`, session.token)
        .then((data) => {
          setUsersCompany(data);
          if (data?.length === 0) {
            messageNotFound();
          }
        })
        .catch(() => {
          messageNotFound();
        })
        .finally(() => setIsLoading(false));
    } else {
      if (
        // Busca por CNPJ/CPF
        (typeTable === "empresa" &&
          !isNaN(Number(searchValue)) &&
          searchValue.length >= 10) ||
        (searchValue.includes(".") && searchValue.includes("-"))
      ) {
        const resultCNPJ = searchValue
          .replaceAll(".", "")
          .replace("/", "")
          .replace("-", "");
        typedFilter = typedFilter.concat(`&cnpj=${resultCNPJ}`);
      }
      // Busca por Nome/Razão Social
      else if (isNaN(Number(searchValue)) && !searchValue.includes("@")) {
        typedFilter = typedFilter.concat(`&razao=${searchValue}`);
      }
      // Busca por E-mail
      else if (searchValue.includes("@")) {
        typedFilter = typedFilter.concat(`&email=${searchValue}`);
      } else {
        typedFilter = typedFilter.concat(`&id=${searchValue}`);
      }

      const servidor = fieldServer.id === 9999 ? -1 : fieldServer.id;
      let fieldVersionWithTag =
        handleAplicativo.param === "unique" && fieldVersion !== ""
          ? `<UNIQUE>${fieldVersion}<UNIQUE>`
          : (handleAplicativo.param === "gestor" ||
              handleAplicativo.param === "nuvem") &&
            fieldVersion !== ""
          ? `<GESTOR>${fieldVersion}<GESTOR>`
          : "";

      if (searchValue != "" && typedFilter.includes("&id=")) {
        await getCompanyByID(searchValue, session.token)
          .then((response) => {
            //Estado que armazena os dados das empresas filtradas
            const companyData: CompanyVersionManager = {
              dtcriacao: response?.company?.dtCriacao,
              status: response?.company?.indStatus,
              versao: response?.company?.versaoSistema,
              cnpj: response?.company?.cnpjCpf,
              dedicado: response?.company?.dedicado,
              descricao: "Empresa",
              dtAtualiza: response?.company?.dtAtualiza,
              email: response?.company?.email,
              empresaid: response?.company?.empresaId,
              razao: response?.company?.razao,
              servidor: response?.company?.servidor,
              versaoSistema: response?.company?.versaoSistema,
            };
            setDesiredCompanySelection([companyData]);
            setHasFilter(true);

            if (response?.data?.company.length === 0) {
              messageNotFound();
            }
          })
          .catch((response) => {
            if (response?.response?.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        await getSemaforo(
          `?aplicativo=${
            handleAplicativo.param
          }${typedFilter}&status=${fieldStatus.id.toString()}&versaosistema=${fieldVersionWithTag}&servidor=${servidor}`,
          session.token
        )
          .then((response) => {
            //Estado que armazena os dados das empresas filtradas
            setDesiredCompanySelection(response?.data?.company);
            setHasFilter(true);

            if (response?.data?.company.length === 0) {
              messageNotFound();
            }
          })
          .catch((response) => {
            if (response?.response?.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const cancelFragmentedResearch = () => {
    setSearchValue("");
    setIsSelected(false);
  };

  useEffect(() => {
    //Estado responsavel por armazenar os dados da api de versão
    if (handleAplicativo.param === "unique") {
      getAllVersionsOfCompaniesUnique(session.token).then((response) =>
        setDataVersionsComapnies(response)
      );
    } else {
      getAllVersionsOfCompaniesGestor(session.token).then((response) =>
        setDataVersionsComapnies(response)
      );
    }
    session.perfil === "3" ? setTypeTable("usuario") : setTypeTable("empresa");
  }, [handleAplicativo.param, session.token, session.perfil]);

  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      // enter
      if (keyCode === 13) {
        SearchFilterGerenciador();
      }
    },
    [SearchFilterGerenciador]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <VersionManagerFiltersLayout.Root>
      <VersionManagerFiltersLayout.Filters>
        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          {session.perfil === "3" ? (
            <Text color={"#BABABA"}>Aplicativo</Text>
          ) : (
            <Text color={"#2D3748"}>Aplicativo</Text>
          )}
          <SelectAble
            isDisabled={session.perfil === "3" || typeTable === "usuario"}
            label=""
            width={"100%"}
            onChange={(ev) => {
              handleSetAplicativo(JSON.parse(ev.target.value));
            }}
            defaultValue={""}
          >
            {filters.aplicativos.map((app) => (
              <option
                key={app.id}
                value={JSON.stringify(app)}
                selected={app.id === 2}
              >
                {app.name}
              </option>
            ))}
          </SelectAble>
        </VersionManagerFiltersLayout.FiltersItem>
        <VersionManagerFiltersLayout.FiltersItem colSpan={10} rowSpan={1}>
          <InputGroup>
            <TextInputAble
              label=""
              value={searchValue}
              backgroundColor={"white"}
              placeholder={
                session.perfil === "3" || typeTable === "usuario"
                  ? "CPF / CNPJ ou E-mail"
                  : "CPF / CNPJ / Nome / Razão Social / Id Empresa ou E-mail"
              }
              width={"100%"}
              onChange={(ev) => setSearchValue(ev.target.value)}
            />
            <InputRightElement cursor={"pointer"}>
              {searchValue.length > 0 && (
                <ImCancelCircle
                  size={"20px"}
                  color={"gray"}
                  onClick={cancelFragmentedResearch}
                />
              )}
            </InputRightElement>
          </InputGroup>
        </VersionManagerFiltersLayout.FiltersItem>

        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          {session.perfil === "3" ? (
            <Text color={"#BABABA"}>Status</Text>
          ) : (
            <Text color={"#2D3748"}>Status</Text>
          )}
          <SelectAble
            isDisabled={session.perfil === "3" || typeTable === "usuario"}
            label=""
            w="full"
            onChange={(e) => setFieldStatus(JSON.parse(e.target.value))}
            defaultValue={""}
          >
            {filters.status.map((item) => (
              <option key={item.id} value={JSON.stringify(item)}>
                {item.name}
              </option>
            ))}
          </SelectAble>
        </VersionManagerFiltersLayout.FiltersItem>
        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          {session.perfil === "3" ? (
            <Text color={"#BABABA"}>Servidor</Text>
          ) : (
            <Text color={"#2D3748"}>Servidor</Text>
          )}
          <SelectAble
            isDisabled={session.perfil === "3" || typeTable === "usuario"}
            label=""
            w="full"
            onChange={(ev) => setFieldServer(JSON.parse(ev.target.value))}
            defaultValue={""}
          >
            {filters.servidores.map((serve) => (
              <option
                key={serve.id}
                value={JSON.stringify(serve)}
                selected={serve.name === "Todos"}
              >
                {serve.name}
              </option>
            ))}
          </SelectAble>
        </VersionManagerFiltersLayout.FiltersItem>
        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          {session.perfil === "3" ? (
            <Text color={"#BABABA"}>Tipo</Text>
          ) : (
            <Text color={"#2D3748"}>Tipo</Text>
          )}
          <SelectAble
            isDisabled={session.perfil === "3"}
            label=""
            w="full"
            onChange={(ev) => {
              isTableCompany(JSON.parse(ev.target.value));
            }}
            defaultValue={""}
          >
            {filters.tipos.map((tipo) => (
              <option
                key={tipo.id}
                value={JSON.stringify(tipo)}
                selected={
                  session.perfil === "3" ? tipo.id === 1 : tipo.id === 0
                }
              >
                {tipo.name}
              </option>
            ))}
          </SelectAble>
        </VersionManagerFiltersLayout.FiltersItem>
        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          {session.perfil === "3" ? (
            <Text color={"#BABABA"}>Versão</Text>
          ) : (
            <Text color={"#2D3748"}>Versão</Text>
          )}
          <SelectAble
            isDisabled={session.perfil === "3" || typeTable === "usuario"}
            label=""
            placeholder="Todas"
            w="full"
            onChange={(ev) => setFieldVersion(ev.target.value)}
            defaultValue={""}
          >
            {dataVersionsComapnies.map(
              (version) =>
                // Busca somente as versões setadas como ativa
                version.status === 1 && (
                  <option key={version.versao} value={version.versao}>
                    {version.versao}
                  </option>
                )
            )}
          </SelectAble>
        </VersionManagerFiltersLayout.FiltersItem>
        <VersionManagerFiltersLayout.FiltersItem colSpan={2} rowSpan={1}>
          <Button
            isDisabled={session.perfil === "3"}
            colorScheme="blue"
            w="full"
            onClick={SearchFilterGerenciador}
            leftIcon={<FaSearch />}
            isLoading={isLoading}
            loadingText="Carregando..."
          >
            Pesquisar
          </Button>
        </VersionManagerFiltersLayout.FiltersItem>
      </VersionManagerFiltersLayout.Filters>

      <Flex id="container" display={"flex"} flexDirection={"column"}>
        {isLoading ? (
          <VersionManagerTableLayout.Root>
            <VersionManagerTableLayout.TableBody>
              <VersionManagerTableLayout.Loading />
            </VersionManagerTableLayout.TableBody>
          </VersionManagerTableLayout.Root>
        ) : (
          <VersionManagerTable
            tipo={typeTable}
            propCompanies={desiredCompanySelection} //desiredCompanySelection -> Armazena apenas 1 empresa de acordo com a seleção do usuario
            propSetCompanies={setDesiredCompanySelection}
            //propCompaniesFiltered={filteredCompanies} //filteredCompanies -> Armazena todas empresa após clicar no botão filtrar
            propHasFiltered={hasFilter}
            propUsers={usersCompany}
            propSetUsers={setUsersCompany}
            propHandleAplicativo={handleAplicativo}
            propSearchValue={searchValue}
            propSetSearchValue={setSearchValue}
            dataVersionsComapnies={dataVersionsComapnies}
          />
        )}
      </Flex>
    </VersionManagerFiltersLayout.Root>
  );
};
