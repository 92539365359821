import {
  Td,
  Th,
  Tr,
  Text,
  Radio,
  RadioGroup,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useState } from "react";
import { VersionManagerActions } from "../VersionManagerActions";
import ClipboardJS from "clipboard";
import { CompanyVersionManager, IUserCompany, PropsTable } from "../interfaces";
import { VersionManagerTableLayout } from "src/layouts/gerenciador/versionManagerTable";

export const TableUsers = ({
  propUsers,
  propSearchValue,
  propSetUsers,
}: PropsTable) => {
  const defaultCompanyVersionManager = {
    descricao: "",
    empresaid: 0,
    cnpj: "",
    email: "",
    razao: "",
    dtcriacao: new Date(),
    dtAtualiza: new Date(),
    status: "",
    versao: "",
    servidor: 1,
    dedicado: false,
    versaoSistema: "",
  };
  const [value, setValue] = useState("0");
  const [selectedUser, setSelectedUser] = useState<IUserCompany>();
  const [dataCompany, setDataCompany] = useState<CompanyVersionManager>(
    defaultCompanyVersionManager
  );
  const toast = useToast();

  const nameServer = {
    0: "Principal",
    1: "Secundario",
    2: "Terciário",
  };

  const copy = () => {
    const clipboard = new ClipboardJS(".fullName");
    clipboard.on("success", (e) => {
      toast({
        id: Math.random(),
        title: "Copiado com sucesso!",
        status: "success",
        position: "top",
        duration: 2000,
      });
      e.clearSelection();
    });
    setTimeout(() => {
      clipboard.destroy();
    }, 100);
  };

  return (
    <>
      {propUsers && propUsers?.length > 0 ? (
        <VersionManagerTableLayout.Root>
          <VersionManagerTableLayout.TableHead>
            <></>
          </VersionManagerTableLayout.TableHead>
          <VersionManagerTableLayout.TableBody>
            {/* Carregamento caso ainda não tenha realizado nenhuma pesquisa */}
            {propUsers?.map((user) => (
              <Tr key={user.userId} fontSize={"12px"}>
                <Td>
                  <RadioGroup value={value}>
                    <Radio
                      value={user.userId.toString()}
                      color={"low.light"}
                      borderColor={"low.medium"}
                      checked={String(value) === String(user.userId)}
                      onClick={() => {
                        if (String(value) === String(user.userId)) { // desmarcar qdo clicar novamente
                          setValue("");
                          setSelectedUser(undefined);
                        } else {
                          setValue(String(user.userId));
                          setSelectedUser(user);
                        }
                      }}
                    />
                  </RadioGroup>
                </Td>
                <Td p={"0"}>usuario</Td>
                <Td>{user.userId}</Td>
                <Td>{user.cpf}</Td>
                <Td
                  maxWidth={"15ch"}
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  whiteSpace={"nowrap"}
                  textTransform={"capitalize"}
                >
                  <Tooltip label={user.email}>
                    <Text
                      _hover={{ color: "blue" }}
                      cursor="pointer"
                      className="fullName"
                      onClick={copy}
                      data-clipboard-text={user.email}
                    >
                      {user.email}
                    </Text>
                  </Tooltip>
                </Td>
                <Td
                  maxWidth={"20ch"}
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  whiteSpace={"nowrap"}
                >
                  <Tooltip label={user.name}>
                    <Text
                      _hover={{ color: "blue" }}
                      cursor="pointer"
                      className="fullName"
                      onClick={copy}
                      data-clipboard-text={user.name}
                    >
                      {user.name}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>{user.status === true ? "Ativo" : "Inativo"}</Td>
                <Td>
                  {/* {dataCompany.servidor} */}
                  {nameServer[dataCompany.servidor]}
                  {/* {dataCompany.servidor === 0 ? "Principal" : "Secundário"} */}
                </Td>
                <Td>
                  {/* {dataCompany.versaoSistema} */}
                  {dataCompany.versaoSistema !== "" ||
                  dataCompany.versaoSistema !== null
                    ? dataCompany.versaoSistema.split(/[<>]/)[2]
                    : ""}
                </Td>
                <Td>{dataCompany.dedicado === false ? "Não" : "Sim"}</Td>
                <Td>
                  {dataCompany.versao === null ||
                  dataCompany.versao.split("|")[2] === "01/01/0001"
                    ? ""
                    : dataCompany.versao.split("|")[2]}
                </Td>
                <Td>{new Date(dataCompany.dtAtualiza).toLocaleDateString()}</Td>
                <Td>{new Date(dataCompany.dtcriacao).toLocaleDateString()}</Td>
                <Td>{new Date(dataCompany.dtcriacao).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </VersionManagerTableLayout.TableBody>
        </VersionManagerTableLayout.Root>
      ) : (
        <VersionManagerTableLayout.Root>
          <VersionManagerTableLayout.Empty />
        </VersionManagerTableLayout.Root>
      )}

      <VersionManagerActions
        selectedData={selectedUser}
        propSearchValue={propSearchValue}
        propSetUsers={propSetUsers}
        propCompanies={undefined}
        propSetCompanies={undefined}
        companiesSelectedToUpdate={undefined}
        propHandleAplicativo={undefined}
        propTipo={undefined}
        propSumSelected={undefined}
        propSetSelected={undefined}
        dataVersionsComapnies={undefined}
      />
    </>
  );
};
