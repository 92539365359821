import {
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useGlobal } from "src/contexts/global";
import { IAplicativo, TModalDetailsApp } from "src/Interfaces/App";

import { UpdateApp } from "../UpdateApp";
import { DeleteApp } from "../DeleteApp";
import { updateCompany } from "src/services/api";

export const DetailsApp = ({
  propIsOpenModalDetailApp,
  propSetIsOpenModalDetailApp,
  propAppSelected,
  propApps,
  propSetApps,
  propValidateCertificado,
  propAmountApp,
}: TModalDetailsApp) => {
  // const [isOpenModalDetailApp, setIsOpenModalDetailApp] = useState(false);
  const { session, details, handleSetDetails } = useGlobal();
  const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [appChosen, setAppChosen] = useState<IAplicativo[]>([]);

  const [modelFiscal, setModelFiscal] = useState([]);
  const [isDirty, setIsDirty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsDirty(true);
    setModelFiscal(
      details.company?.modeloNf?.split("|").filter((item) => item !== "")
    );
  }, [details.company.modeloNf]);

  const toast = useToast();

  const appsGestor = propApps.filter(
    (item) =>
      item.descricao.toUpperCase().includes("GESTOR") &&
      item.descricao.toUpperCase().includes("PLANO")
  );

  const appsUnique = propApps.filter(
    (item) =>
      item.descricao.toUpperCase().includes("UNIQUE") &&
      item.descricao.toUpperCase().includes("PLANO")
  );

  const appsSimplaz = propApps.filter((item) =>
    item.descricao.toUpperCase().includes("SIMPLAZ")
  );

  const appsAnalytics = propApps.filter(
    (item) =>
      item.descricao.toUpperCase().includes("ANALYTICS") ||
      item.descricao.toUpperCase().includes("INTELIGÊNCIA")
  );

  const appsMercadoLivre = propApps.filter((item) =>
    item.descricao.toUpperCase().includes("MERCADO")
  );

  const handleOpenModalUpdate = () => {
    if (
      propAppSelected.descricao.toUpperCase().includes("GESTOR") &&
      propAppSelected.descricao.toUpperCase().includes("PLANO")
    ) {
      setAppChosen(appsGestor);
      return setIsOpenModalUpdate(true);
    }
    if (
      propAppSelected.descricao.toUpperCase().includes("UNIQUE") &&
      propAppSelected.descricao.toUpperCase().includes("PLANO")
    ) {
      setAppChosen(appsUnique);
      return setIsOpenModalUpdate(true);
    }
    if (
      propAppSelected.descricao.toUpperCase().includes("ANALYTICS") ||
      propAppSelected.descricao.toUpperCase().includes("INTELIGÊNCIA")
    ) {
      setAppChosen(appsAnalytics);
      return setIsOpenModalUpdate(true);
    }
    if (propAppSelected.descricao.toUpperCase().includes("SIMPLAZ")) {
      setAppChosen(appsSimplaz);
      return setIsOpenModalUpdate(true);
    }
    if (propAppSelected.descricao.toUpperCase().includes("MERCADO")) {
      setAppChosen(appsMercadoLivre);
      return setIsOpenModalUpdate(true);
    }
  };

  const handleModelFiscal = (value) => {
    setModelFiscal(value);
    setIsDirty(false);
  };

  const handleSaveModeloNF = async () => {
    setIsLoading(true);

    const modelFiscalOut =
      modelFiscal.length > 0
        ? "|" + modelFiscal.toString().replaceAll(",", "|") + "|"
        : "";

    await updateCompany(
      {
        cnpjCpf: details.company.cnpjCpf,
        empresaId: details.company.empresaId,
        modeloNf: modelFiscalOut,
      },
      session.token
    )
      .then((response) => {
        handleSetDetails({
          company: response.company,
          users: JSON.stringify(details.users),
        });
        toast({
          title: "Modelo de documento fiscal alterado com sucesso",
          description: "",
          position: "top-right",
          status: "success",
          isClosable: true,
          containerStyle: {
            fontSize: "14px",
          },
        });
        setIsDirty(true);
      })
      .catch(() => {
        toast({
          title: "Erro ao salvar o modelo de Nota Fiscal!",
          description: "",
          position: "top-right",
          status: "error",
          isClosable: true,
          containerStyle: {
            fontSize: "14px",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={propIsOpenModalDetailApp}
        onClose={propSetIsOpenModalDetailApp}
      >
        <ModalOverlay />
        <ModalContent p={"32px"}>
          <ModalHeader p={0}>{propAppSelected.descricao}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <Flex flexDir={"column"}>
              <Flex flexDir={"column"} mt={"12px"}>
                <Text>{` Versão atual`}</Text>
                {(propAppSelected &&
                  propAppSelected.nome &&
                  propAppSelected.nome.toUpperCase().includes("UNIQUE") &&
                  propAppSelected.nome.toUpperCase().includes("PLANO")) ||
                (propAppSelected &&
                  propAppSelected.nome &&
                  propAppSelected.nome.toUpperCase().includes("GESTOR") &&
                  propAppSelected.nome.toUpperCase().includes("PLANO")) ? (
                  <Text as={"b"}>{`${
                    details.company.versaoSistema.split(/[<>]/)[2]
                  }`}</Text>
                ) : (
                  <Text color={"gray.400"}>Sem Informação</Text>
                )}
              </Flex>
              {propAppSelected.descricao &&
                propAppSelected.descricao
                  .toUpperCase()
                  .includes("CERTIFICADO") && (
                  <Flex flexDir={"column"} mt={"12px"}>
                    <Text>Vencimento</Text>
                    <Text as={"b"}>
                      {new Date(propValidateCertificado).toLocaleDateString()}
                    </Text>
                  </Flex>
                )}
              <Flex flexDir={"column"} mt={"12px"}>
                <Text>{` Nº de série`}</Text>
                <Text as={"b"}>
                  {propAppSelected &&
                  propAppSelected.nome &&
                  typeof propAppSelected.nome === "string" &&
                  propAppSelected.nome.toUpperCase().includes("UNIQUE") &&
                  propAppSelected.nome.toUpperCase().includes("PLANO") ? (
                    details.company.nrSerieUnique
                  ) : propAppSelected &&
                    propAppSelected.nome &&
                    typeof propAppSelected.nome === "string" &&
                    propAppSelected.nome.toUpperCase().includes("GESTOR") &&
                    propAppSelected.nome.toUpperCase().includes("PLANO") ? (
                    details.company.nrSerieGestor
                  ) : (
                    <Text color={"gray.400"}>Sem Informação</Text>
                  )}
                </Text>
              </Flex>
              <Flex flexDir={"column"} mt={"12px"}>
                <Text>{` Atualização Programada`}</Text>
                <Text as={"b"}>
                  {(propAppSelected &&
                    propAppSelected.nome &&
                    typeof propAppSelected.nome === "string" &&
                    propAppSelected.nome.toUpperCase().includes("UNIQUE") &&
                    propAppSelected.nome.toUpperCase().includes("PLANO") &&
                    details.company.versao?.split("|")[2] !== undefined) ||
                  (propAppSelected &&
                    propAppSelected.nome &&
                    typeof propAppSelected.nome === "string" &&
                    propAppSelected.nome.toUpperCase().includes("GESTOR") &&
                    propAppSelected.nome.toUpperCase().includes("PLANO") &&
                    details.company.versao?.split("|")[2] !== undefined) ? (
                    `${
                      details.company.versao.split("|")[2] === "01/01/0001"
                        ? ""
                        : details.company.versao?.split("|")[2]
                    } p/ versão ${details.company.versao?.split("|")[1]}`
                  ) : (
                    <Text color={"gray.400"}>Sem Informação</Text>
                  )}
                </Text>
              </Flex>
              <Flex flexDir={"column"} mt={"12px"}>
                <Text>{` Quantidade de usuarios ativos`}</Text>
                <Text as={"b"}>{propAmountApp}</Text>
              </Flex>
              {propAppSelected &&
                propAppSelected.nome &&
                propAppSelected.nome.toUpperCase().includes("GESTOR") && (
                  <Flex flexDir={"column"} mt={"12px"} mb={3}>
                    <Text>Modelos de documentos fiscais</Text>
                    <CheckboxGroup
                      colorScheme="blue"
                      defaultValue={modelFiscal}
                      value={modelFiscal}
                      isDisabled={
                        session.perfil !== "5" && session.perfil !== "1"
                      }
                      onChange={(value) => handleModelFiscal(value)}
                    >
                      <Stack
                        spacing={[1, 3]}
                        mt={2}
                        direction={["row", "column"]}
                      >
                        <Checkbox value="55">55 NF-e</Checkbox>
                        <Checkbox value="65">65 NFc-e</Checkbox>
                        <Checkbox value="sat">SAT</Checkbox>
                      </Stack>
                    </CheckboxGroup>
                    {(session.perfil === "5" || session.perfil === "1") && (
                      <Button
                        w={"max-content"}
                        h={7}
                        px={7}
                        colorScheme={isDirty ? "gray" : "blue"}
                        variant="outline"
                        mt={3}
                        isDisabled={isDirty}
                        isLoading={isLoading}
                        loadingText="Salvando..."
                        onClick={() => handleSaveModeloNF()}
                      >
                        Salvar alterações nos modelos
                      </Button>
                    )}
                  </Flex>
                )}
            </Flex>

            {(session.perfil === "1" ||
              session.perfil === "3" ||
              session.perfil === "5") && (
              <Divider borderColor={"blackAlpha.500"} mt={3} mb={6} />
            )}
          </ModalBody>
          <ModalFooter p={0} justifyContent={"space-between"}>
            {session.perfil === "1" || session.perfil === "5" ? (
              <Button
                w="130px"
                h={"25px"}
                bg={"white"}
                color={"#BB1111"}
                border={"1px solid #BB1111"}
                colorScheme="#BB1111"
                mr={3}
                onClick={() => setIsOpenModalDelete(true)}
                isDisabled={isLoading}
              >
                Excluir serviço
              </Button>
            ) : (
              ""
            )}
            {session.perfil === "3" || session.perfil === "5" ? (
              <Button
                w="130px"
                h={"25px"}
                bg={"white"}
                color={"#166DD7"}
                border={"1px solid #166DD7"}
                colorScheme={"#166DD7"}
                mr={3}
                onClick={handleOpenModalUpdate}
                isDisabled={isLoading}
              >
                Alterar plano
              </Button>
            ) : (
              ""
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenModalUpdate && (
        <UpdateApp
          propIsOpenModalUpdate={isOpenModalUpdate}
          propSetIsOpenModalUpdate={() => setIsOpenModalUpdate(false)}
          propAppSelected={propAppSelected}
          propAppChosen={appChosen}
          propApps={propApps}
          propSetApps={propSetApps}
          propSetIsOpenModalDetailApp={propSetIsOpenModalDetailApp}
        />
      )}
      {isOpenModalDelete && (
        <DeleteApp
          propIsOpenModalDelete={isOpenModalDelete}
          propSetIsOpenModalDelete={() => setIsOpenModalDelete(false)}
          propAppSelected={propAppSelected}
          propApps={propApps}
          propSetApps={propSetApps}
          propSetIsOpenModalDetailApp={propSetIsOpenModalDetailApp}
        />
      )}
    </>
  );
};
