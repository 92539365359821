import { Checkbox, Td, Tr, useToast, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import { VersionManagerActions } from "../VersionManagerActions";
import ClipboardJS from "clipboard";
import { PropsTable } from "../interfaces";
import { VersionManagerTableLayout } from "src/layouts/gerenciador/versionManagerTable";

export const TableCompanies = ({
  propCompanies,
  propSetCompanies,
  propHasFiltered,
  propHandleAplicativo,
  propTipo,
  dataVersionsComapnies,
}: PropsTable) => {
  const [companiesSelectedToUpdate, setCompaniesSelectedToUpdate] = useState<
    number[]
  >([]);

  const nameServer = {
    0: "Principal",
    1: "Secundario",
    2: "Terciário",
  };

  const toast = useToast();
  const copy = () => {
    const clipboard = new ClipboardJS(".fullName");
    clipboard.on("success", (e) => {
      toast({
        id: Math.random(),
        title: "Copiado com sucesso!",
        status: "success",
        position: "top",
        duration: 2000,
      });
      e.clearSelection();
    });
    setTimeout(() => {
      clipboard.destroy();
    }, 100);
  };

  return (
    <>
      {propCompanies?.length > 0 ? (
        <VersionManagerTableLayout.Root>
          <VersionManagerTableLayout.TableHead>
            <Checkbox
              color={"low.light"}
              borderColor={"low.medium"}
              isChecked={
                propCompanies?.length === companiesSelectedToUpdate.length &&
                propCompanies?.length !== 0
              }
              onChange={() =>
                setCompaniesSelectedToUpdate(
                  propCompanies?.length === companiesSelectedToUpdate.length
                    ? []
                    : propCompanies?.map((company) => company.empresaid)
                )
              }
            />
          </VersionManagerTableLayout.TableHead>
          <VersionManagerTableLayout.TableBody>
            {propCompanies?.map((company) => (
              <Tr
                key={company?.empresaid}
                fontSize={"12px"}
                backgroundColor={
                  companiesSelectedToUpdate.includes(company?.empresaid)
                    ? "#EAF0F9"
                    : "#fff"
                }
              >
                <Td>
                  <Checkbox
                    color={"low.light"}
                    borderColor={"low.medium"}
                    onChange={() =>
                      setCompaniesSelectedToUpdate(
                        companiesSelectedToUpdate.includes(company?.empresaid)
                          ? companiesSelectedToUpdate.filter(
                              (items) => items !== company?.empresaid
                            )
                          : [...companiesSelectedToUpdate, company?.empresaid]
                      )
                    }
                    isChecked={companiesSelectedToUpdate.includes(
                      company?.empresaid
                    )}
                  />
                </Td>
                <Td p={"0"}>Empresa</Td>
                <Td>{company?.empresaid}</Td>
                <Td>{company?.cnpj}</Td>
                <Td
                  maxWidth={"15ch"}
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  whiteSpace={"nowrap"}
                  textTransform={"capitalize"}
                >
                  <Tooltip label={company?.email}>
                    <Text
                      _hover={{ color: "blue" }}
                      cursor="pointer"
                      className="fullName"
                      onClick={copy}
                      data-clipboard-text={company?.email}
                    >
                      {company?.email}
                    </Text>
                  </Tooltip>
                </Td>
                <Td
                  maxWidth={"20ch"}
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  whiteSpace={"nowrap"}
                >
                  <Tooltip label={company?.razao}>
                    <Text
                      _hover={{ color: "blue" }}
                      cursor="pointer"
                      className="fullName"
                      onClick={copy}
                      data-clipboard-text={company?.razao}
                    >
                      {company?.razao}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>{company?.status === "1" ? "Ativo" : "Inativo"}</Td>
                <Td>{nameServer[company?.servidor]}</Td>
                <Td
                  maxWidth={"12ch"}
                  textOverflow={"ellipsis"}
                  overflow="hidden"
                  whiteSpace={"nowrap"}
                  textTransform={"capitalize"}
                >
                  {company?.versaosistema !== "" &&
                  company?.versaosistema !== null
                    ? company?.versaosistema?.split(/[<>]/)[2]
                    : ""}
                </Td>
                <Td>{company?.dedicado === false ? "Não" : "Sim"}</Td>
                <Td>
                  {company?.versao === null ||
                  company?.versao?.split("|")[2] === "01/01/0001"
                    ? ""
                    : company?.versao?.split("|")[2]}
                </Td>
                <Td>{new Date(company?.dtAtualiza).toLocaleDateString()}</Td>
                <Td>{new Date(company?.dtcriacao).toLocaleDateString()}</Td>
                <Td>{new Date(company?.dtcriacao).toLocaleDateString()}</Td>
              </Tr>
            ))}
          </VersionManagerTableLayout.TableBody>
        </VersionManagerTableLayout.Root>
      ) : (
        <VersionManagerTableLayout.Root>
          <VersionManagerTableLayout.Empty />
        </VersionManagerTableLayout.Root>
      )}

      <VersionManagerActions
        selectedData={undefined}
        propSearchValue={undefined}
        propSetUsers={undefined}
        propCompanies={propCompanies}
        propSetCompanies={propSetCompanies}
        companiesSelectedToUpdate={companiesSelectedToUpdate}
        propHandleAplicativo={propHandleAplicativo}
        propTipo={propTipo}
        propSumSelected={companiesSelectedToUpdate.length}
        propSetSelected={setCompaniesSelectedToUpdate}
        dataVersionsComapnies={dataVersionsComapnies}
      />
    </>
  );
};
