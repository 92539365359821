import { Route, Routes } from "react-router-dom";
import { Creation } from "src/pages/Creation";
import { Details } from "src/pages/Details";
import { Geral } from "src/pages/Geral";
import { RegisterVersion } from "src/pages/RegisterVersion";
import { GerenciadorDeVersao } from "src/pages/GerenciadorDeVersao";

import { Login } from "../pages/Login";
import { HasAuth, ProtectedRoutes } from "./auth";

export const MainRoutes = () => {
  return (
    <Routes>
      <Route element={<HasAuth />}>
        <Route path="/" element={<Login />} />
      </Route>

      <Route element={<ProtectedRoutes />} >
        <Route path="/geral" element={<Geral />} />
        <Route path="/details" element={<Details />} />
        <Route path="/creation" element={<Creation />} />
        <Route path="/GerenciadorDeVersao" element={<GerenciadorDeVersao />} />
        <Route path="/registerVersion" element={<RegisterVersion />} />
      </Route>
    </Routes>
  );
};