import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlineHistory } from "react-icons/ai";
import moment from "moment";
import { getUsersLog } from "src/services/api";

import { logUserInterface, logUsersDataInterface } from "./interfaces";

import * as S from "./styles";

export default function LayoutPlansAndAppsLogUser({
  open,
  onClose,
}: logUserInterface) {
  const [usersLogs, setUsersLogs] = useState<logUsersDataInterface[]>([]);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (open) {
      setIsloading(true);
      getLogs();
    }
  }, [open]);

  const getLogs = async () => {
    const data = await getUsersLog();
    setUsersLogs(data?.logs);
    setIsloading(false);
  };

  return (
    <>
      <Modal isOpen={open} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent w={"full"}>
          <ModalHeader>
            <Flex alignItems={"center"} gap={3} fontSize={"24px"}>
              <AiOutlineHistory /> Log de ações
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody w={"full"}>
            {!isLoading ? (
              usersLogs.length > 0 ? (
                <S.Table>
                  <Table
                    w={"full"}
                    variant={"striped"}
                  >
                    <Thead>
                      <Tr>
                        <Th>Ação</Th>
                        <Th>Data e hora da ação</Th>
                        <Th>Usuário</Th>
                        <Th>Dado anterior</Th>
                        <Th>Dado atual</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {usersLogs?.map((log, index) => {
                        return (
                          <Tr key={`log- ${index}`}>
                            <Td>{log.tipoAcao}</Td>
                            <Td>
                              {moment(log.dataHora).format("D/MM/YYYY HH:mm")}
                            </Td>
                            <Td>{log.nomeUsuario}</Td>
                            <Td>{log.anterior}</Td>
                            <Td>{log.acao}</Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </S.Table>
              ) : (
                <Box>Nenhum log encontrado</Box>
              )
            ) : (
              <Stack mb={100}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}
          </ModalBody>
          {/* <ModalFooter>
           <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
            </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}
