import {
  Button,
  Flex,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AiOutlineInsertRowAbove } from "react-icons/ai";

import { logout } from "src/utils/logout";
import bgGeral from "src/assets/bg_home.png";
import { useGlobal } from "src/contexts/global";
import { Feedback } from "src/components/Feedback/feedback";
import { SearchCompany } from "src/components/HeaderPages/SearchCompany";
import { ReactComponent as LogoWhite } from "src/assets/svgs/logo-nucleo-white.svg";

export const Geral = () => {
  const { session } = useGlobal();
  const navigate = useNavigate();

  return (
    <>
      <Feedback />
      <Flex
        h={"100%"}
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Flex
          w={"100%"}
          backgroundImage={`url(${bgGeral})`}
          backgroundSize="cover"
          backgroundRepeat={"no-repeat"}
          flex={1}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Tooltip label="Deslogar" placement="top">
            <Button onClick={() => logout()} variant="link">
              <LogoWhite />
            </Button>
          </Tooltip>
          <Flex
            flexDirection={"column"}
            gap={2}
            textAlign={"center"}
            mt={"40px"}
            mb={"70px"}
          >
            <Text fontSize={"xl"} color={"high.pure"}>
              Olá, <strong style={{ color: "#166DD7" }}>{session.role}</strong>
              .🤙
            </Text>
            <Text fontSize={"sm"} color={"high.pure"} fontWeight={"bold"}>
              Aqui no Núcleo Londrisoft você poderá
            </Text>
          </Flex>
        </Flex>
        <Flex flex={1} position="relative">
          <Flex
            flexDirection={"column"}
            minHeight={session.permissions.register ? "220px" : "190px"}
            bgColor={"#fff"}
            boxShadow={"md"}
            borderRadius={"4px"}
            position={"absolute"}
            top={"-60px"}
            left={"50%"}
            translateX={"-50%"}
            transform={"auto"}
            px={6}
            py={6}
          >
            <Text
              fontSize={"sm"}
              fontWeight={"bold"}
              mb="10px"
              textAlign={"center"}
            >
              encontrar informação sobre o usuário:
            </Text>
            <SearchCompany />

            {session.permissions.register && (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                alignItems={"center"}
                mt={"auto"}
              >
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  mb="10px"
                  textAlign={"center"}
                >
                  criar cadastro de empresa:
                </Text>
                <Button
                  width={"220px"}
                  fontSize="sm"
                  colorScheme={"blue"}
                  leftIcon={<FaUserPlus size={18} />}
                  onClick={() => navigate("/creation")}
                >
                  Criar Cadastro
                </Button>
              </Flex>
            )}

            {(session.perfil === "3" || session.perfil === "5") && (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                alignItems={"center"}
                mt={"auto"}
              >
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  mb="10px"
                  textAlign={"center"}
                >
                  ou
                </Text>
                <Button
                  width={"220px"}
                  fontSize="sm"
                  colorScheme={"blue"}
                  leftIcon={<AiOutlineInsertRowAbove size={18} />}
                  onClick={() => navigate("/GerenciadorDeVersao")}
                >
                  Acessar Gerenciador
                </Button>
              </Flex>
            )}
          </Flex>
          <Flex mt={"220px"}>
            <button style={{ color: "#166DD7" }} onClick={() => logout()}>
              Sair da conta
            </button>
          </Flex>
        </Flex>
        <Flex></Flex>
      </Flex>
    </>
  );
};
// export const SearchInput = () => {
//   const toast = useToast();
//   const navigate = useNavigate();
//   const { session, handleSetDetails, details } = useGlobal();
//   const [searchValue, setSearchValue] = useState("");
//   const [isSelected, setIsSelected] = useState(false);
//   const [filtredCompanies, setFiltredCompanies] = useState<
//     AllCompaniesAndUsers[]
//   >([]);
//   const [desiredCompanySelection, setDesiredCompanySelection] = useState<
//     CompanyVersionManager[]
//   >([]);

//   const getWorkspace = async (cnpjCpf: any) => {
//     try {
//       const { data } = await apiInstance(9000).get(
//         `/workspace/Empresa/${cnpjCpf}`,
//         {
//           headers: {
//             gatewayls: "2e44bb6339e6aacd8faeca8fd4e8694e",
//           },
//         }
//       );
//     } catch (error) {
//       toast({
//         title: "Falha ao buscar dados",
//         description: "Verifique os filtros e os valores informados!",
//         position: "top-right",
//         status: "error",
//         isClosable: true,
//         containerStyle: {
//           fontSize: "14px",
//         },
//       });
//     }
//   };

//   const handleSearch = async (e: FormEvent) => {
//     let url = "/api/Empresa/nucleo?";

//     if (
//       !isNaN(Number(searchValue)) ||
//       (searchValue.includes(".") && searchValue.includes("-"))
//     ) {
//       const resultCNPJ = searchValue
//         .replaceAll(".", "")
//         .replace("/", "")
//         .replace("-", "");
//       url = url.concat(`&cnpj=${resultCNPJ}`);
//     } else if (isNaN(Number(searchValue)) && !searchValue.includes("@")) {
//       url = url.concat(`razao=${searchValue}`);
//     } else if (searchValue.includes("@")) {
//       url = url.concat(`email=${searchValue}`);
//     }

//     try {
//       e.preventDefault();
//       const { data } = await apiInstance(9008).get(`${url}`, {
//         headers: {
//           authorization: `Bearer ${session.token}`,
//         },
//       });

//       if (!data.dados[0]) {
//         throw new Error("");
//       }

//       const cnpjCpfSelected = data.dados[0].company.cnpjCpf;

//       getWorkspace(cnpjCpfSelected);

//       let newFiltredCompanies = data.dados;
//       setFiltredCompanies(newFiltredCompanies);

//       // handleSetDetails(data);
//       if (newFiltredCompanies.length > 1) {
//         showList();
//       } else {
//         handleSetDetails(newFiltredCompanies[0]);
//         navigate("/details");
//       }
//       // console.log(data);
//     } catch (error) {
//       console.log(error);
//       if (axios.isAxiosError(error)) {
//         const axiosError = error as AxiosError<ErrorResponse>;
//         if (axiosError.response) {
//           const { status, message, company } = axiosError.response.data;

//           console.log(axiosError.response);
//           console.log(axiosError.response.status);
//           if (axiosError.response.status === 401) {
//             toast({
//               title: "Sessão Expirada",
//               description: "Você será redirecionado(a) para tela de login",
//               position: "top-right",
//               status: "error",
//               isClosable: true,
//               containerStyle: {
//                 fontSize: "14px",
//               },
//             });
//             // alert("sessão expirada");
//             setTimeout(() => {
//               logout();
//             }, 3000);
//           }
//         } else {
//           toast({
//             title: "Falha ao salvar",
//             description: "Erro desconhecido",
//             position: "top-right",
//             status: "error",
//             isClosable: true,
//             containerStyle: {
//               fontSize: "14px",
//             },
//           });
//         }
//       }
//     }
//   };
//   // Mostra lista com empresas filtradas pela razão social
//   const showList = () => {
//     setIsSelected(true);
//   };
//   // Confirma empresa a ser apresentada na tabela principal e fecha a tabela auxiliar de busca
//   const SelectedItem = (item: CompanyVersionManager) => {
//     const newResult: any = filtredCompanies.filter((res) => {
//       return res.company.empresaId === item.empresaId;
//     });

//     handleSetDetails(newResult[0]);
//     setDesiredCompanySelection(newResult);
//     setIsSelected(false);
//     navigate("/details");
//   };

//   return (
//     <Fragment>
//       <FormControl
//         as="form"
//         onSubmit={handleSearch}
//         display={"flex"}
//         // justifyContent="center"
//         gap={"12px"}
//       >
//         <Box>
//           <Input
//             size={"sm"}
//             w="535px"
//             placeholder="CPF / CNPJ, Nome / Razão Social / e-mail"
//             // borderInlineStartRadius={"4px"}
//             // borderLeftRadius={0}
//             borderColor="#D8D8D8"
//             value={searchValue}
//             onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
//               setSearchValue(e.target.value)
//             }
//           />
//           <Flex style={{ position: "fixed", zIndex: "10" }}>
//             <Table w={"630px"} background={"white"}>
//               <Tbody>
//                 {isSelected === true &&
//                   filtredCompanies.map((item) => (
//                     <Tr key={item.company.empresaId}>
//                       <Td
//                         _hover={{ color: "#1155BB" }}
//                         textAlign={"left"}
//                         cursor={"pointer"}
//                         onClick={() => SelectedItem(item.company)}
//                       >
//                         <Text text-align={"left"}>{item.company.razao}</Text>
//                         <Text text-align={"right"}>{item.company.cnpjCpf}</Text>
//                       </Td>
//                     </Tr>
//                   ))}
//               </Tbody>
//             </Table>
//           </Flex>
//         </Box>
//         <Flex
//           position={"absolute"}
//           top={"0px"}
//           right={"0px"}
//           z-index={"20px"}
//           border={"none"}
//           outline={"none"}
//         >
//           {searchValue.length > 0 && (
//             <Button
//               // border={"1px solid red"}
//               w={"15px"}
//               h={"28px"}
//               mr={"7px"}
//               mt={"2px"}
//               background={"transparent"}
//               rightIcon={<ImCancelCircle size={"15px"} color={"gray"} />}
//               _hover={{ bg: "transparent" }}
//               _active={{ bg: "white" }}
//               onClick={() => {
//                 setIsSelected(false);
//                 setSearchValue("");
//               }}
//             ></Button>
//           )}

//           <Button
//             size={"sm"}
//             borderLeftRadius={0}
//             colorScheme={"blue"}
//             type="submit"
//             isDisabled={!searchValue.length}
//           >
//             Pesquisar
//           </Button>
//         </Flex>
//       </FormControl>
//     </Fragment>
//   );
// };
