import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  postEconomyGroup,
  getCompanyByCnpj,
  getEconomyGroup,
  deleteEconomyGroupParticipant,
} from "src/services/api";
import { unMasked } from "src/utils/masked";
import { useGlobal } from "src/contexts/global";

import LayoutPlansAndAppsInput from "../input/root";

import {
  economyGroupInterface,
  economyGroupListInterface,
  IEconomyGroup,
} from "./interfaces";

import * as S from "./styles";
import { IRegister } from "src/Interfaces/Register";
import React from "react";

export default function LayoutPlansAndAppsEconomyGroup({
  open,
  onClose,
}: economyGroupInterface) {
  const { session, details } = useGlobal();
  const toast = useToast();

  const {
    isOpen,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [groupList, setGroupList] = useState<economyGroupListInterface[]>([]);

  const [search, setSearch] = useState<string | number>("");
  const [addCompany, setAddCompany] = useState("");
  const [allEconomicGroups, setAllEconomicGroups] = useState<
    economyGroupListInterface[]
  >([]);
  const [isLoading, setIsloading] = useState(false);

  const [companySelected, setCompanySelected] =
    useState<economyGroupListInterface>();

  useEffect(() => {
    if (open) {
      setSearch("");
      setAddCompany("");
      getEconomyGroupList();
    }
  }, [open]);

  const getEconomyGroupList = async () => {
    setIsloading(true);
    const data = await getEconomyGroup(details.company.cnpjCpf);
    const list: economyGroupListInterface[] = await data?.grupo?.map(
      (emp: economyGroupListInterface) => {
        const item: economyGroupListInterface = {
          cnpjCpf: emp.cnpjCpf,
          razao: emp.razao,
          perfil:
            emp.cnpjCpf === details.company.cnpjCpf &&
            emp.razao === details.company.razao
              ? "Pai"
              : "Filho",
          origem: "Sincronizados",
          aplicacao: "Gestor",
        };
        return item;
      }
    );
    setGroupList(list);
    setAllEconomicGroups(list);
    setIsloading(false);
  };

  const filterCnpj = (term: string) => {
    const regexp = /[a-zA-Z]/gi;
    const matches_array = term.match(regexp);
    setSearch(term);
    if (term && matches_array) {
      setGroupList(
        allEconomicGroups.filter((item) =>
          item.razao?.toLowerCase()?.includes(term.toLowerCase())
        )
      );
    } else if (term && !matches_array) {
      setGroupList(
        allEconomicGroups.filter((item) => item.cnpjCpf?.includes(term))
      );
    } else {
      setGroupList(allEconomicGroups);
    }
  };

  const msgErro = (message?: string) => {
    return toast({
      title: "Grupo econômico não encontrado",
      description: message ? message : "",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const msgSucesso = (message?: string) => {
    return toast({
      title: message === "OK" ? "Cadastro feito com sucesso!" : message,
      status: message !== "OK" ? "warning" : "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
  };

  const adicionarCnpj = () => {
    getCompanyByCnpj(unMasked(addCompany), session.token)
      .then((response) => {
        if (response?.dados?.length === 0) {
          msgErro("Confira o CPNJ e tente novamente");
          return;
        }
        const company: IRegister = response?.dados[0]?.company;
        const payload: IEconomyGroup = {
          cnpjCpf: unMasked(addCompany),
          indSatus: company.indStatus ? company.indStatus : "",
          razao: company.razao,
          grupoEconomico: 1,
          participanteEndereco: [
            {
              endereco: company.endereco,
              bairro: company.bairro,
              cep: company.cep,
              cidade: company.cidade,
              estado: company.estado,
              celular: company.fone,
              email: company.email,
              fone: company.fone,
              numero: company.numero,
            },
          ],
        };
        postEconomyGroup(unMasked(details.company.cnpjCpf), payload)
          .then((response) => {
            getEconomyGroupList();
            msgSucesso(response?.message);
            setAddCompany("");
          })
          .catch((error) => msgErro(String(error?.message)));
      })
      .catch((error) => msgErro(String(error?.message)));
  };

  const deleteCompany = async (company: economyGroupListInterface) => {
    if (company) {
      deleteEconomyGroupParticipant(company.cnpjCpf);
      onCloseAlert();
    }
  };

  const confirmDeleteCompanyAlert = (company: economyGroupListInterface) => {
    onOpenAlert();
    setCompanySelected(company);
  };

  const AlertContent = () => {
    return (
      <AlertDialog
        isOpen={isOpen}
        onClose={onCloseAlert}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletando empresa do grupo econômico
            </AlertDialogHeader>

            <AlertDialogBody>
              {`Tem certeza que deseja excuir a empresa ${companySelected?.razao} desse grupo econômico?`}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseAlert}>
                Cancelar
              </Button>
              <Button
                colorScheme="red"
                onClick={() =>
                  companySelected ? deleteCompany(companySelected) : null
                }
                ml={3}
              >
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };

  return (
    <>
      {AlertContent()}
      <Modal isOpen={open} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent w={"full"}>
          <ModalHeader>
            <Flex
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              flexWrap={"wrap"}
              w={"full"}
            >
              <Box
                fontSize={"lg"}
                color={"#1155BB"}
                mb={4}
                borderBottom={"1px"}
                borderBottomWidth={"1px"}
              >
                Grupos econômicos
              </Box>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={3}
                w={"full"}
                flexWrap={"wrap"}
                sx={{
                  "@media (max-width: 800px)": {
                    gridTemplateColumns: "repeat(1, minmax(150px, 1fr))",
                  },
                }}
              >
                {allEconomicGroups.length > 0 && (
                  <GridItem rowSpan={6}>
                    <Box w={"full"}>
                      <LayoutPlansAndAppsInput
                        text="Pesquisar grupo econômico"
                        size="xs"
                        textAlign="left"
                        value={search}
                        search={true}
                        onChange={(event) => filterCnpj(event?.target?.value)}
                        handleClick={() => {
                          setSearch("");
                          filterCnpj("");
                        }}
                        readonly={false}
                        placeholder="Pesquisar por nome ou  CNPJ"
                      />
                    </Box>
                  </GridItem>
                )}
                {(session.perfil === "3" || session.perfil === "5") && (
                  <GridItem rowSpan={allEconomicGroups.length > 0 ? 6 : 12}>
                    <Box w={"full"}>
                      <LayoutPlansAndAppsInput
                        text="Adicionar grupo econômico"
                        size="xs"
                        textAlign="left"
                        value={addCompany}
                        onChange={(event) =>
                          setAddCompany(event?.target?.value)
                        }
                        handleClick={adicionarCnpj}
                        readonly={false}
                        placeholder="Digite o CNPJ"
                        type="text"
                      />
                    </Box>
                  </GridItem>
                )}
              </Grid>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody w={"full"}>
            {!isLoading ? (
              groupList.length > 0 ? (
                <S.Table>
                  <Table w={"full"} variant={"simple"}>
                    <Thead>
                      <Tr>
                        <Th>Razão</Th>
                        <Th>CNPJ</Th>
                        <Th>Perfil</Th>
                        <Th>Origem</Th>
                        <Th>Aplicação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {groupList?.map((log, index) => {
                        return (
                          <Tr key={`grupo-economy- ${index}`}>
                            <Td>{log.razao}</Td>
                            <Td>{log.cnpjCpf}</Td>
                            <Td>{log.perfil}</Td>
                            <Td>{log.origem}</Td>
                            <Td>{log.aplicacao}</Td>
                            {/*<Td>
                              {(session.perfil === "3" ||
                                session.perfil === "5") &&
                                log.perfil === "Filho" && (
                                  <>
                                    <Tooltip label="Excluir empresa do grupo">
                                      <IconButton
                                        isRound={true}
                                        variant="ghost"
                                        colorScheme="red"
                                        aria-label="Done"
                                        fontSize="20px"
                                        icon={<MdDeleteOutline />}
                                        onClick={() =>
                                          confirmDeleteCompanyAlert(log)
                                        }
                                      />
                                    </Tooltip>
                                  </>
                                )}
                                </Td>*/}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </S.Table>
              ) : allEconomicGroups.length > 0 ? (
                <Box>Nenhum resultado para o termo informado</Box>
              ) : (
                <Box>Esta empresa não possui grupo econômico</Box>
              )
            ) : (
              <Stack mb={100}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex
              w={"full"}
              flexWrap={"wrap"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box fontSize={"xs"}>
                {allEconomicGroups.length > 0 && (
                  <>Total de grupos econômicos: {allEconomicGroups.length}</>
                )}
              </Box>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
