import { Tbody } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerTableTd({ children }: IRootProps) {
  return (
    <Tbody
    
    >
      {children}
    </Tbody>
  );
}
