import { Flex, Grid } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerActions({ children }: IRootProps) {
  return (
    <Flex
      id="actions"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Flex
        alignItems="end"
        gap="24px"
        sx={{
          "@media (max-width: 600px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          //  gridAutoFlow={'column dense'}
          sx={{
            "@media (max-width: 1200px)": {
              gridTemplateColumns: "repeat(6, 6fr)",
             /* display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'*/
            },
            "@media (max-width: 800px)": {
              gridTemplateColumns: "repeat(4, 6fr)",
            },
          }}
          gap={6}
          width={"full"}
          alignItems={"end"}
        >
          {children}
        </Grid>
      </Flex>
    </Flex>
  );
}
