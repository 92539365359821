import { ChakraProvider } from "@chakra-ui/react";
import { GlobalProvider } from "./contexts/global";
import { MainRoutes } from "./routes";
import { theme } from "./styles/theme";

export const App = () => (
  <ChakraProvider theme={theme}>
    <GlobalProvider>
      <MainRoutes />
    </GlobalProvider>
  </ChakraProvider>
);
