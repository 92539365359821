import { Flex } from "@chakra-ui/react";
import { IRootProps } from "src/layouts/interfaces";

export default function LayoutVersionManagerFiltersRoot({
  children,
}: IRootProps) {
  return (
    <Flex flexDir="column" w={"full"} alignItems={"center"}>
      <Flex
        flexDir="column"
        w={"100vw"}
        gap="15px"
        maxWidth={"100%"}
        padding={"0 30px"}
      >
        {children}
      </Flex>
    </Flex>
  );
}
