import styled from "@emotion/styled";

export const Table = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 80vh;
  
  th,
  td {
    border: 1px solid #f5f5f5;
    padding: 5px 10px;
    min-width: 150px;
    background-color: #fff;
    font-size: 12.5px;
  }

  th {
    color: #1155bb;
  }

  tr {
    color: #1155bb;
  }

  td {
    color: #666666;
    overflow: auto;
  }
`;
