import { Button, Text } from "@chakra-ui/react";
import { VscNewFile } from "react-icons/vsc";

export const TitleRegisterCompany = () => {
  return (
    <Text size={"300px"} color={"#1155BB"}>
      Cadastro da Empresa
      <Button
        _hover={{ bgColor: "white" }}
        bg={"white"}
        cursor={"auto"}
        leftIcon={<VscNewFile size={22} />}
      ></Button>
    </Text>
  );
};
